<template>
    <div>
        <!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
        <h3>
            Danh sách tài khoản
        </h3>
        <br/>
        <v-row justify="left">
			<v-dialog @click:outside="state.dialog = false" v-model="state.dialog" persistent max-width="800px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn @click="addUser" class="text-transform" color="primary" dark v-bind="attrs" v-on="on">
						<v-icon left>mdi-plus</v-icon>Thêm mới
					</v-btn>
				</template>
				<v-card>
					<v-card-title>
						<span class="text-h5">Thông tin tài khoản</span>
					</v-card-title>
					<form @submit.prevent="onSubmitUser" ref="anyName">
						<v-card-text>
							<v-container>
								<v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            autocomplete="off"
                                            v-model="state.new_user.name"
                                            label="Tên đầy đủ *"
                                            outlined
                                            :error-messages="state.validates.name"
                                            name="name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            autocomplete="new-email"
                                            v-model="state.new_user.email"
                                            label="Email đăng nhập *"
                                            outlined
                                            :error-messages="state.validates.email"
                                            name="name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            v-model="state.new_user.password"
                                            label="Mật khẩu đăng nhập *"
                                            type="password"
                                            outlined
                                            :error-messages="state.validates.password"
                                            name="password"
                                            autocomplete="new-password"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-select
                                        v-model="state.new_user.roles"
                                        outlined
                                        multiple    
                                        :error-messages="state.validates.roles"
                                        :items="state.roles"
                                        item-text="name"
                                        item-value="id"
                                        label="Quyền"
                                        ></v-select>
                                    </v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="state.dialog = false"
							>
								Đóng
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								type="submit"
							>
								Lưu
							</v-btn>
						</v-card-actions>
					</form>
				</v-card>
			</v-dialog>
		</v-row>
        <br>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="2"
				sm="3"
				md="3"
			>
                <v-text-field
                dense
                v-model="state.search"
                label="Từ khóa tìm kiếm..."
                outlined
                ></v-text-field>
            </v-col>
            <v-col cols="3"
				sm="3"
				md="3">
                <v-btn
                    style="margin-right: 10px"
                    class="text-transform"
                    color="primary"
                    @click="searchUser"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="error"
                    @click="resetForm"
                >
                    <v-icon left>mdi-reload</v-icon> Reset
                </v-btn>
            </v-col>
        </v-row>
        <hr>
        <v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>
                                    STT
                                </th>
                                <th>Tên</th>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Quyền
                                </th>
                                <th>
                                    Hành động
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="state.loading">
                                <td colspan="5" align="center">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-if="state.message != ''">
                                <td colspan="5" align="center">
                                    {{state.message}}
                                </td>
                            </tr>
                            <template v-if="state.users.length > 0">
                                <tr v-for="(userItem, index) in state.users"  :key="index">
                                    <td>
                                        {{index + 1}}
                                    </td>
                                    <td>
                                        {{userItem.name}}
                                    </td>
                                    <td>
                                        {{userItem.email}}
                                    </td>
                                    <td>
                                        <span v-for="(roleItem, index_role) in userItem.roles" :key="index_role">
                                            {{roleItem}}
                                        </span>
                                    </td>
                                    <td>
                                        <v-btn
                                            small
                                            depressed
                                            color="warning"
                                            style="margin-right: 7px"
                                            @click="state = {
                                                ...state,
                                                dialog: true,
                                                validates: {
                                                    name: '',
                                                    email: '',
                                                    password: '',
                                                    roles: []
                                                },
                                                new_user: {
                                                    name: userItem.name,
                                                    email: userItem.email,
                                                    password: '',
                                                    roles: userItem.role_ids,
                                                    id: userItem.id
                                                },
                                                edit: {
                                                    status: true,
                                                    index_edit: index
                                                }
                                            }"
                                        >
                                        <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                        
                                        <v-btn
                                            small
                                            depressed
                                            color="error"
                                            @click="deleteUser(userItem.id, index)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Breadcrumb from "./../../Shares/Breadcrumb.vue";
import { api } from "./../../../../helpers/const.js";

export default {
    name: "ListUser",
    // components: { Breadcrumb },
    data() {
        return {
            state: {
                search: '',
                edit:{
                    status: false,
                    index_edit: ''
                },
                dialog: false,
                users: [],
                loading: true,
                error: '',
                roles: [],
                message: '',
                validates: {
                    name: '',
                    email: '',
                    password: '',
                    roles: []
                },
                new_user: {
                    name: '',
                    email: '',
                    password: '',
                    roles: [],
                    id: ''
                },
            }
        }
    },
    methods: {
        addUser() {
            if (this.state.edit.status) {
                this.state = {
                    ...this.state,
                    new_user: {
                        name: '',
                        email: '',
                        password: '',
                        roles: [],
                        id: ''
                    },
                    edit: {
                        status: false,
                        index_edit: ''
                    }
                }
            }
        },
        deleteUser(id, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({id: id})
                    };
                    fetch(api.delete_user, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                icon: 'success',
                                title: result.message,
                                timer: 1500
                            }); 
                            const users = [...this.state.users];
                            users.splice(index, 1);
                            this.state = {
                                ...this.state,
                                users: users
                            }
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: result.message,
                                timer: 1500
                            }); 
                        }
                    })
                }
            })
            
        },
        onSubmitUser() {
            const url = this.state.edit.status ? api.update_user : api.create_user;
            console.log(url, 'chinh123');
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.state.new_user)
            };
            fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    if (this.state.edit.status) {
                        const state_cp = {...this.state};
                        state_cp.users[this.state.edit.index_edit] = result.new_user;
                        state_cp.edit = {
                            status: false,
                            index_edit: ''
                        }
                        state_cp.dialog = false;
                        this.state = state_cp;
                    } else {
                        this.state = {
                            ...this.state,
                            users: [result.new_user, ...this.state.users],
                            dialog: false
                        }
                    }

                    this.$swal({
                        icon: 'success',
                        title: result.message,
                        timer: 1500
                    });
                } else {
                    if (result.type == 'validate') {
                        this.state = {
                            ...this.state,
                            validates: result.errors,
                        }
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: result.message,
                            timer: 1500
                        }); 
                    }

                }
            });
        },
        listUser() {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    search: this.state.search
                })
            };
            fetch(api.list_user, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.state = {
                        ...this.state,
                        users: result.datas,
                        roles: result.role_datas,
                        loading: false
                    }
                } else {
                    this.state = {
                        ...this.state,
                        loading: false,
                        error: result.message
                    }
                }
            });
        },
        resetForm() {
            this.state = {
                ...this.state,
                search: ''
            }
            this.listUser();
        },
        searchUser() {
            this.state = {
                ...this.state,
                loading: true,
                users: []
            }
            this.listUser();
        }
    },
    mounted() {
        this.listUser();
    }
}
</script>

<style scoped>

</style>