<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
		<h3 class="m-b-10">
           Sửa chứng chỉ
		</h3>
        <p class="m-b-0" style="margin-top: 10px">
        <v-btn
            small
            depressed
            color="primary"
            style="margin-right: 7px"
            @click="$router.go(-1)"
            >
            <v-icon left>mdi-arrow-left</v-icon>
            Quay lại
        </v-btn>
        </p>
		<br>
        <v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <form @submit.prevent="onUpdate">
                    <v-row>
                        <v-col md="12">
                            <h3>Thông tin chứng chỉ cần sửa</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="3">
                            <v-text-field
                                label="Tên *"
                                v-model="state.data_edit.full_name"
                                :error-messages="errors.full_name"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col md="3">
                            <v-text-field
                                label="Email *"
                                v-model="state.data_edit.email"
                                :error-messages="errors.email"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col md="3">
                            <v-text-field
                                label="Số điện thoại *"
                                v-model="state.data_edit.phone"
                                :error-messages="errors.phone"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col md="3">
                            <v-text-field
                                label="Địa chỉ *"
                                v-model="state.data_edit.address"
                                :error-messages="errors.address"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="3">
                            <v-autocomplete
                                v-model="state.data_edit.decree"
                                :items="state.type_exam_list"
                                :error-messages="errors.decree"
                                outlined
                                dense
                                multiple
                                label="Chọn lĩnh vực theo nghị định*"
                                chips
                                small-chips
                            ></v-autocomplete>
                        </v-col>
                        <v-col md="3">
                            <v-text-field
                                v-model="state.data_edit.type_exam"
                                label="Lĩnh vực *"
                                hint="Cách nhau bằng dấu ; không để dấu ; ở cuối"
                                :error-messages="errors.type_exam"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col md="3">
                            <v-text-field
                                v-model="state.data_edit.rank_exam"
                                :error-messages="errors.rank_exam"
                                label="Hạng *"
                                hint="Cách nhau bằng dấu ; không để dấu ; ở cuối"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col md="3">
                            <v-text-field
                                label="Giá *"
                                v-model="state.data_edit.price"
                                :error-messages="errors.price"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="3">
                            <v-autocomplete
                                v-model="state.data_edit.product_id"
                                :items="state.products"
                                :error-messages="errors.product_id"
                                outlined
                                dense
                                item-text="name"
                                item-value="id"
                                label="Chọn sản phẩm *"
                                chips
                                small-chips
                            ></v-autocomplete>
                        </v-col>
                        <v-col md="3">
                            <v-text-field
                                label="Ghi chú"
                                v-model="state.data_edit.note"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="3">
                            <v-btn
                                type="submit"
                                :loading="loading"
                                depressed
                                color="primary"
                                @click="loading = !loading"
                                >
                                Cập nhật
                            </v-btn>
                        </v-col>
                    </v-row>
                </form>

			</v-col>
		</v-row>
	</div>
</template>

<script>
import { api } from "./../../../../../helpers/const.js";
import Breadcrumb from "./../../../Shares/Breadcrumb.vue";
export default {
	name: "EditCertificate",
	// components: { Breadcrumb },
	data() {
		return {
            loading: false,
            state: {
                data_edit: {},
                products: [],
                type_exam_list: []
            },
            errors: {}
		};
	},
	methods: {
        onUpdate() {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.state.data_edit)
            };
            fetch(api.update_certificate_import, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.loading = false;
                    this.$swal({
                        title: result.message,
                        icon: 'success',
                        timer: 2000
                    })
                } else {
                    this.loading = false;
                    if (result.type == 'validate') {
                        this.errors = result.errors;
                    } else {
                        this.$swal({
                            title: result.message,
                            icon: 'error',
                            timer: 2000
                        })
                    }
                }
            });
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    id: this.$route.params.id
                })
            };
            fetch(api.get_edit_certificate_import, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.state = {
                        ...this.state,
                        data_edit: result.data,
                        products: result.products,
                        type_exam_list: result.type_exam_list
                    }
                }
            });
        }
	},
	mounted() {
        this.callApi();
	}
};
</script>
