<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
		<h3>
			Danh sách khách hàng nợ khi mua hàng
		</h3>
		<br/>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
                style="padding-top: 0px; padding-bottom: 0px"
			>
                <h3>Lọc dữ liệu</h3>
            </v-col>
            <v-col
				cols="2"
				sm="2"
				md="2"
			>
                <v-text-field
                dense
                hide-details
                v-model="states.filters.key_search"
                label="Từ khóa tìm kiếm..."
                outlined
                ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field
                    label="Từ"
                    type="date"
                    dense
                    outlined
                    v-model="states.filters.date_start"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field
                    label="Đến"
                    type="date"
                    dense
                    outlined
                    v-model="states.filters.date_end"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="2"
				sm="2"
				md="2">
                <v-select
                hide-details
                dense
                v-model="states.filters.status_owe"
                :items="states.status_owe"
                item-value="id"
                item-text="name"
                label="Trạng thái"
                outlined
                ></v-select>
            </v-col>
            <v-col cols="2"
				sm="3"
				md="3">
                <v-select
                    v-model="states.filters.employees"
                    :items="states.employees"
                    label="Nhân viên phụ trách"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    >
                </v-select>
                <!-- <v-autocomplete
                    multiple
                    chips
                    small-chips
                    :items="states.employees"
                    item-value="id"
                    item-text="name"
                    outlined
                    v-model="states.filters.employees"
                    dense
                    label="Nhân viên"
                ></v-autocomplete> -->
            </v-col>
            <v-col cols="2"
				sm="4"
				md="4">
                <v-btn
                    class="text-transform"
                    color="primary"
                    @click="search"
                    style="margin-right: 10px"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="error"
                    style="margin-right: 10px"
                >
                    <v-icon left>mdi-magnify</v-icon> Tải lại
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="success"
                    @click="exportOwe"
                    :loading="states.loading_export"
                >
                    <v-icon left>mdi-download</v-icon> Export
                </v-btn>
            </v-col>
        </v-row>
        <hr>
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table class="list-order">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    STT
                                </th>
                                <th>Đơn hàng</th>
                                <th class="text-left">
                                    Khách hàng
                                </th>
                                <th class="text-left">
                                    Đơn giá
                                </th>
                                <th class="text-left">
                                    Giảm giá
                                </th>
                                <th class="text-left">
                                    Nợ
                                </th>
                                <th class="text-left">
                                    Nhận
                                </th>
                                <th>
                                    Hoa hồng
                                </th>
                                <th class="text-left">
                                    Trạng thái
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="states.loading">
                                <td class="text-center" colspan="12">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-if="states.message != null">
                                <td class="text-center" colspan="12">
                                    <p>
                                        {{states.message}}
                                    </p>
                                    <v-btn
                                        :to="{name: 'list-order'}"
                                        large
                                        depressed
                                        color="error"
                                        style="margin-right: 7px"
                                        >
                                        <v-icon small left>mdi-reload</v-icon> Tải lại
                                    </v-btn>
                                </td>
                            </tr>
                            <tr v-if="states.orders.length > 0" style="background: rgb(251, 140, 0);color: #fff;">
                                <td colspan="3"></td>
                                <td>
                                    <b>{{Number(total(states.orders).total_price).toLocaleString()}}</b>
                                </td>
                                <td>
                                    <b>{{Number(total(states.orders).total_discount).toLocaleString()}}</b>
                                </td>
                                <td>
                                    <b>{{Number(total(states.orders).total_owe).toLocaleString()}}</b>
                                </td>
                                <td>
                                    <b>{{Number(total(states.orders).total_receive).toLocaleString()}}</b>
                                </td>
                                <td>
                                    <b>{{Number(total(states.orders).total_commission).toLocaleString()}}</b>
                                </td>
                                <!-- <td>
                                    <b>{{Number(total(states.orders).total_payment).toLocaleString()}}</b>
                                </td> -->
                                <td></td>
                            </tr>
                            <tr v-for="(item, index) in states.orders"
                                :key="index"
                                >
                                <td>{{ index +  1 }}</td>
                                <td>
                                    <p>
                                        Sản phẩm: <b>{{item.product.name}}</b>
                                    </p>
                                    <p>
                                        Tạo đơn: <b>{{item.created_at}}</b>
                                    </p>
                                    <p>
                                        Duyệt đơn: <b>{{item.date_accept}}</b>
                                    </p>
                                    <p>
                                        Người tạo: <b>{{item.saler}}</b>
                                    </p>
                                    <p>
                                        Người duyệt: <b>{{item.user_accept}}</b>
                                    </p>
                                    <p>
                                        Mã đơn: <b>{{ item.code }}</b>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        <v-icon small left>mdi-email</v-icon> {{item.email}}
                                    </p>
                                    <p>
                                        <v-icon small left>mdi-account</v-icon>{{item.full_name}}
                                    </p>
                                    <p>
                                        <v-icon small left>mdi-phone</v-icon> {{item.phone}}
                                    </p>
                                </td>
                                <td>
                                    {{item.amount}}  x <b>{{Number(item.price).toLocaleString()}}</b>
                                </td>
                                <td>
                                    {{Number(item.discount).toLocaleString()}}
                                </td>
                                <td>
                                    {{Number(item.owe).toLocaleString()}}
                                </td>
                                <td>
                                    <b>
                                        {{Number(item.price * item.amount - item.discount - item.owe).toLocaleString()}}
                                    </b>
                                </td>
                                <td>
                                    <b>
                                        {{Number(commission(item)).toLocaleString()}}
                                    </b>
                                </td>
                                <!-- <td>
                                    <b>
                                        {{Number(item.payment).toLocaleString()}}
                                    </b>
                                </td> -->
                                <td>
                                    <v-chip
                                        @click="changeStatusOwe(item, index, 1)"
                                        class="cursor"
                                        style="margin-right: 10px" 
                                        :color="item.status_owe ? 'primary' : 'default'">
                                        <!-- {{item.status_vacc_vace.name}} -->
                                        Chưa trả
                                    </v-chip>
                                    <v-chip
                                        @click="changeStatusOwe(item, index, 0)"
                                        class="cursor"
                                        :color="!item.status_owe ? 'primary' : 'default'">
                                        Đã trả
                                    </v-chip>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                            
			</v-col>
		</v-row>
        <div class="text-center" v-if="states.filters.total_page > 1">
            <v-container>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            @input="nextPage"
                            v-model="states.filters.page"
                            class="my-4"
                            :length="states.filters.total_page"
                            :total-visible="7"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
import {convertNumber} from "./../../../../helpers/function.js";
import {debounce} from './../../../../helpers/function.js'
import Breadcrumb from "./../../Shares/Breadcrumb.vue";
export default {
	name: "OweMoney",
	// components: { Breadcrumb },
	data() {
		return {
            tab: null,
            menu: false,
            menu2: false,
			breadcrumbs: [
				{
					text: "Dashboard",
					disabled: false,
					href: "/admin"
				},
				{
					text: "Tạo đơn",
					disabled: false,
					href: "/admin/order/create"
				}
			],
            states: {
                employees: [],
                loading_export: false,
                loading: true,
                status_owe: [{name: 'Tất cả', id: -1}, {name: 'Chưa trả', id: 1}, {name: 'Đã trả', id: 0}],
                filters: {
                    key_search: '',
                    date_start: '',
                    date_end: '',
                    status_owe: -1,
                    page: 1,
                    total_page: 0,
                    type_search: 'search',
                    employees: -1
                },
                orders: [],
                data_products: []
            }
		};
	},
	methods: {
        commission(item) {
            return item.commission/100 * (item.price * item.amount - item.discount - item.payment * item.amount);
        },
        changeStatusOwe(order, index, status) {
            const old_order = [...this.states.orders];
            old_order[index].status_owe = status;
            this.states = {
                ...this.states,
                orders: old_order
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    order_id: order.id,
                    status: status
                })
            };
            fetch(api.change_status_owe, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        icon: 'error',
                        title: result.message,
                        timer: 1000
                    });
                    old_order[index].owe = !status;
                    this.states = {
                        ...this.states,
                        orders: old_order
                    }
                }
            });
        },
        total(data) {
			return {
                total_price: data.map(item => item.price * item.amount).reduce((prev, next) => prev + next),
                total_receive: data.map(item => item.price * item.amount - item.discount - item.owe).reduce((prev, next) => prev + next),
                total_owe: data.map(item => item.owe).reduce((prev, next) => prev + next),
                total_payment: data.map(item => item.payment * item.amount).reduce((prev, next) => prev + next),
                total_discount: data.map(item => item.discount).reduce((prev, next) => prev + next),
                total_commission: data.map(item => item.commission/100 * item.price * item.amount - item.discount).reduce((prev, next) => prev + next),
            };
		},
        changeStatusVaccVace(order, index, status) {
            const old_order = [...this.states.orders];
            old_order[index].status_vacc_vace = status;
            this.states = {
                ...this.states,
                orders: old_order
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    order_id: order.id,
                    status: status
                })
            };
            fetch(api.change_status_vacc_vace, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        icon: 'error',
                        title: result.message,
                        timer: 1000
                    });
                    old_order[index].status_vacc_vace = !status;
                    this.states = {
                        ...this.states,
                        orders: old_order
                    }
                }
            });
        },
        search() {
            // console.log(this.states.filters);
            const filters = {...this.states.filters};
            filters.page = 1;
            filters.total_page = 0;
            this.states = {
                ...this.states,
                filters: filters,
                orders: [],
                loading: true
            }
            this.callApi();
        },
        exportOwe() {
            const filters = {...this.states.filters};
            filters.type_search = 'export';
            this.states = {
                ...this.states,
                filters: filters,
                loading_export: true
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.statistical_owe, requestOptions)
            .then(res => res.blob())
            .then(
                (result) => {
                    filters.type_search = 'search';
                    this.states = {
                        ...this.states,
                        loading_export: false,
                        filters: filters
                    }
                    const date = new Date();
                    const date_export = date.getDate() + '/' + parseInt(date.getMonth() + 1) + '/' + date.getFullYear();
                    const url = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `export_owe_${date_export}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            )
        },
        toggle () {
            this.$nextTick(() => {
                if (this.likesAllEmployee) {
                    this.states.filters.employees = []
                } else {
                    const data = [];
                    this.states.employees.map((value) => {
                        data.push(value.id);
                    })
                    this.states.filters.employees = data; //this.states.employees.slice()
                }
            })
        },
        nextPage(page) {
            this.states = {
                ...this.states,
                page: page,
                orders: [],
                loading: true
            }
            this.callApi();
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.statistical_owe, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        ...this.states,
                        employees: result.employees,
                        filters: {
                            ...this.states.filters,
                            total_page: result.total_page
                        },
                        orders: result.orders,
                        loading: false,
                        data_products: result.data_products
                    }
                } else {
                    this.states = {
                        ...this.states,
                        message: result.message,
                        loading: false
                    }
                }
            });
        }
	},
    computed: {
      likesAllEmployee () {
        return this.states.filters.employees.length === this.states.employees.length;
      },
      likesSomeEmployee () {
        return this.states.filters.employees.length > 0 && !this.likesAllEmployee;
      },
      icon () {
        if (this.likesAllEmployee) return 'mdi-close-box'
        if (this.likesSomeEmployee) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },
	watch: {
		
	},
	mounted() {
		this.callApi()
	}
};
</script>

<style scoped>
table tr td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    white-space: nowrap;
}
</style>
