var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"left"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},on:{"click:outside":function($event){_vm.dialog = false}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-transform",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Quản lý file ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Quản lý File, Image")])]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.uploadFile.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Tìm kiếm tên"},model:{value:(_vm.state.name_file_search),callback:function ($$v) {_vm.$set(_vm.state, "name_file_search", $$v)},expression:"state.name_file_search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-btn',{attrs:{"depressed":"","color":"success"},on:{"click":_vm.search}},[_vm._v(" Tìm kiếm ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-file-input',{attrs:{"label":"File input","outlined":"","dense":""},model:{value:(_vm.state.file),callback:function ($$v) {_vm.$set(_vm.state, "file", $$v)},expression:"state.file"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","type":"submit"}},[_vm._v(" Thêm mới ")])],1)],1)],1)],1),_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"text-h5 m-b-0"},[_vm._v("Danh sách")])]),_vm._l((_vm.state.list_files),function(file,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"4","md":"4"}},[_c('p',{staticClass:"text-center"},[_c('img',{staticStyle:{"width":"100%","height":"100px","object-fit":"contain"},attrs:{"src":file.name,"alt":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-transform",staticStyle:{"margin-right":"10px"},attrs:{"depressed":"","small":"","color":"default"},on:{"click":function($event){return _vm.copy(file.name)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link-plus")]),_vm._v(" Copy ")],1)]}}],null,true)},[_c('span',[_vm._v("Click để copy")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-transform",attrs:{"depressed":"","small":"","color":"error"},on:{"click":function($event){return _vm.removeFile(file.id, index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Xóa ")],1)]}}],null,true)},[_c('span',[_vm._v("Click để xóa")])])],1)])})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }