<template>
	<div>
        <br>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
		<h3>
            Danh sách chứng chỉ đã duyệt
		</h3>
        <br>
        <!-- <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
                style="padding-top: 0px; padding-bottom: 0px"
			>
                <h3 >Lọc dữ liệu</h3>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field
                    label="Ngày upload, từ"
                    type="date"
                    dense
                    v-model="date_upload_start"
                    outlined
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field
                    label="Ngày upload, đến"
                    type="date"
                    dense
                    v-model="date_upload_end"
                    outlined
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="2"
				sm="3"
				md="3">
                <v-btn
                    class="text-transform"
                    color="primary"
                    @click="search"
                    style="margin-right: 10px"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
            </v-col>
        </v-row>
        <hr> -->
        <v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-btn
                v-if="selected.length > 1"
                @click="mergeCertificate"
                depressed
                color="primary"
                >
                Gộp danh sách
                </v-btn>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Chọn</th>
                                <th class="text-left">
                                    STT
                                </th>
                                <th class="text-left">
                                    Tên đợt Upload
                                </th>
                                <th class="text-left">
                                    Ngày upload
                                </th>
                                <th>
                                    Số lượng
                                </th>
                                <th>
                                    Ngày thi
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="states.loading">
                                <td class="text-center" colspan="7">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-if="states.message != null">
                                <td class="text-center" colspan="7">
                                    {{states.message}} <br>
                                    <v-btn
                                        class="text-transform"
                                        depressed
                                        @click="reloadPage"
                                        color="primary"
                                    >
                                        Tải lại
                                    </v-btn>
                                </td>
                            </tr>
                            <tr v-for="(certificateItem, index) in states.certificates.slice(0, limit)" :key="index">
                                <td>
                                    <v-checkbox
                                    v-model="selected"
                                    :value="certificateItem.name_upload"
                                    ></v-checkbox>
                                </td>
                                <td>
                                    {{index + 1}}
                                </td>
                                <td>
                                    <router-link class="a-none" :to="{ name: 'certificate-detail-approved', params: { name_upload: certificateItem.name_upload }}">
                                        {{certificateItem.name_upload}}
                                    </router-link>
                                </td>
                                <td>
                                    {{certificateItem.date_upload}}
                                </td>
                                <td>
                                    {{certificateItem.amount}}
                                </td>
                                <td>
                                    <input @change="changeDateExam($event, certificateItem.name_upload)" type="date" v-model="certificateItem.date_exam">
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <center>
                    <v-btn
                    @click="loadMoreCertificate"
                    depressed
                    color="primary"
                    >
                    Xem thêm
                    </v-btn>
                </center>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { convertNumber } from '../../../../helpers/function.js';
import { api } from "./../../../../helpers/const.js";

export default {
	name: "ImportCertificate",
	// components: { Breadcrumb },
	data() {
		return {
            selected: [],
            status: -1,
			loading: true,
            limit: 20,
            url_file_sample: api.url_file_sample,
            date_upload_start: new Date().getFullYear() + '-' + convertNumber(parseInt(1 + new Date().getMonth())) + '-01',
            date_upload_end: new Date().getFullYear() + '-' + convertNumber(parseInt(1 + new Date().getMonth())) + '-' + convertNumber(new Date().getDate()),
            states: {
                dialog: false,
                loading: true,
                message: null,
                certificates: [],
                provinces: []
            },
		};
	},
	methods: {
        loadMoreCertificate() {
            const new_limit = parseInt(this.limit + 20);
            this.limit = new_limit;
        },
        changeDateExam(event, name_upload) {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    name_upload: name_upload,
                    date_exam: event.target.value
                })
            };
            fetch(api.update_date_exam_certificate_approved, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        title: result.message,
                        icon: 'error',
                        timer: 2000
                    })
                } else {
                    this.$swal({
                        title: result.message,
                        icon: 'success',
                        timer: 2000
                    })
                }
            });
        },
        mergeCertificate() {
            this.$swal({
                title: 'Bạn có chắc chắn muốn gộp?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            name_uploads: this.selected,
                            type: 'approved'
                        })
                    };
                    fetch(api.merge_certificate, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                            location.reload();
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 1500
                            })
                        }
                    })
                }
            })
        },
        search() {
            this.states = {
                ...this.states,
                loading: true,
                certificates: []
            }
            this.callApi();
        },
        callApi() {
            const requestOptions = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                }
            };
            fetch(api.list_certificate_approved + '?date_upload_start=' + this.date_upload_start + '&date_upload_end=' + this.date_upload_end, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        ...this.states,
                        loading: false,
                        certificates: result.datas,
                    }
                } else {
                    this.states = {
                        ...this.states,
                        message: result.message,
                        loading: false
                    }
                }
            });
        }
	},
	mounted() {
        this.callApi();
	}
};
</script>

<style scoped>

</style>
