<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
		<h3>
            Danh sách key
		</h3>
		<br/>
		<!-- <v-row justify="left">
            <v-btn :to="{name: 'create-order'}" class="text-transform" color="primary" dark v-bind="attrs" v-on="on">
                <v-icon left>mdi-plus</v-icon> Thêm mới
            </v-btn>
		</v-row> -->
        <v-row justify="left">
            <v-dialog 
                @click:outside="states.dialog_edit=false"
                v-model="states.dialog_edit" persistent max-width="600px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Sửa key</span>
                    </v-card-title>
                    <form @submit.prevent="onUpdateKey">
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            v-model="data_key_edit.number_of_device_use"
                                            label="Số lượng máy sử dụng"
                                            type="number"
                                            :error-messages="errors.number_of_device_use"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            v-model="data_key_edit.number_key_per_device"
                                            label="Số bản được cài trên 1 máy"
                                            type="number"
                                            :error-messages="errors.number_key_per_device"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="data_key_edit.expire_date"
                                            :items="states.day_numbers"
                                            item-text="name"
                                            item-value="value"
                                            label="Thời gian sử dụng"
                                            name="product_status"
                                            :error-messages="errors.expire_date"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            :items="states.type_keys"
                                            v-model="data_key_edit.type_key"
                                            label="Loại key"
                                            item-text="name"
                                            item-value="value"
                                            :error-messages="errors.type_key"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete
                                            v-model="data_key_edit.product_id"
                                            :items="states.products"
                                            item-text="name"
                                            item-value="id"
                                            label="Sản phẩm"
                                            :error-messages="errors.product_id"
                                            chips
                                            small-chips
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="states.dialog_edit=false"
                            >
                                Đóng
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                type="submit"
                            >
                                Lưu
                            </v-btn>
                        </v-card-actions>
                    </form>
                </v-card>
            </v-dialog>
            <v-dialog @click:outside="
                                        states.dialog=false,
                                        errors = {
                                            amount: '',
                                            number_of_device_use: '',
                                            number_key_per_device: '',
                                            type_key: '',
                                            product_id: '',
                                            expire_date: ''
                                        }"
            v-model="states.dialog" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on">
                        Thêm mới
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Thông tin key</span>
                    </v-card-title>
                    <form @submit.prevent="onSubmitKey">
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            v-model="form_search.amount"
                                            label="Số key cần tạo"
                                            type="number"
                                            :error-messages="errors.amount"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            v-model="form_search.number_of_device_use"
                                            label="Số lượng máy sử dụng"
                                            type="number"
                                            :error-messages="errors.number_of_device_use"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            v-model="form_search.number_key_per_device"
                                            label="Số bản được cài trên 1 máy"
                                            type="number"
                                            :error-messages="errors.number_key_per_device"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="form_search.expire_date"
                                            :items="states.day_numbers"
                                            item-text="name"
                                            item-value="value"
                                            label="Thời gian sử dụng"
                                            name="product_status"
                                            :error-messages="errors.expire_date"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            :items="states.type_keys"
                                            v-model="form_search.type_key"
                                            label="Loại key"
                                            item-text="name"
                                            item-value="value"
                                            :error-messages="errors.type_key"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete
                                            v-model="form_search.product_id"
                                            :items="states.products"
                                            item-text="name"
                                            item-value="id"
                                            label="Sản phẩm"
                                            :error-messages="errors.product_id"
                                            chips
                                            small-chips
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="states.dialog=false,errors = {
                                            amount: '',
                                            number_of_device_use: '',
                                            number_key_per_device: '',
                                            type_key: '',
                                            product_id: '',
                                            expire_date: ''
                                        }"
                            >
                                Đóng
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                type="submit"
                            >
                                Lưu
                            </v-btn>
                        </v-card-actions>
                    </form>
                </v-card>
            </v-dialog>
        </v-row>
		<br>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
                style="padding-top: 0px; padding-bottom: 0px"
			>
            <h3 >Tìm kiếm key</h3>
            </v-col>
            <v-col
				cols="6"
				sm="3"
				md="3"
			>
                <v-text-field
                dense
                v-model="filter.key_search"
                label="Từ khóa tìm kiếm..."
                outlined
                ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2" md="2">
                <v-select
                    outlined
                    dense
                    v-model="filter.type_key"
                    :items="states.type_key_search"
                    item-text="name"
                    item-value="value"
                    label="Loại key"
                ></v-select>
            </v-col>
            <v-col cols="6" sm="2" md="2">
                <v-select
                    outlined
                    dense
                    v-model="filter.status_key"
                    :items="states.status_key"
                    item-text="name"
                    item-value="value"
                    label="Trạng thái"
                ></v-select>
            </v-col>
            <v-col cols="6"
				sm="2"
				md="2">
                <v-autocomplete
                    v-model="filter.product"
                    :items="states.products"
                    outlined
                    dense
                    multiple
                    item-text="name"
                    item-value="id"
                    label="Sản phẩm"
                    chips
                    small-chips
                ></v-autocomplete>
            </v-col>
            <v-col cols="12"
				sm="3"
				md="3">
                <v-btn
                    style="margin-right: 10px"
                    class="text-transform"
                    color="primary"
                    @click="submitForm"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="error"
                    @click="resetForm"
                >
                    <v-icon left>mdi-reload</v-icon> Reset
                </v-btn>
            </v-col>
            <v-col cols="1"
				sm="1"
				md="1">
            </v-col>
         </v-row>
         <hr>
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <p>
                    Tổng cộng: <b>{{Number(states.total_key).toLocaleString()}}</b>
                </p>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    STT
                                </th>
                                <th class="text-left">
                                    License
                                </th>
                                <th class="text-left">
                                    Khách hàng
                                </th>
                                <!-- <th>
                                    Phần mềm
                                </th>
                                <th class="text-left">
                                    Loại key
                                </th> -->
                                <th class="text-left">
                                    Đổi máy
                                </th>
                                <th>Trạng thái</th>
                                <th>Tình trạng</th>
                                <th>Gửi key</th>
                                <th class="text-left">
                                    Hành động
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="states.loading">
                                <td class="text-center" colspan="12">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-for="(license, index) in states.licenses" :key="index">
                                <td>
                                    {{index + 1}}
                                </td>
                                <td>
                                    <p>
                                        Key: <b>{{license.license}}</b>
                                    </p>
                                    <p>
                                        Hardware Id: <b>{{license.hardware_id}}</b>
                                    </p>
                                    <p>
                                        Phần mềm: <b>{{license.product.name}}</b>
                                    </p>
                                    <p>
                                        {{license.type_key.name}} - <b>{{license.day_use}} ngày</b>
                                    </p>
                                </td>
                                <td style="white-space: nowrap">
                                    <p v-if="license.customer.email != null">
                                        <b>Kích hoạt:</b>
                                    </p>
                                    <p v-if="license.customer.email != null">
                                        <v-icon small left>mdi-email</v-icon> {{license.customer.email}}
                                    </p>
                                    <p v-if="license.customer.name != null">
                                        <v-icon small left>mdi-account</v-icon>{{license.customer.name}}
                                    </p>
                                    <p v-if="license.customer.phone != null">
                                        <v-icon small left>mdi-phone</v-icon> {{license.customer.phone}}
                                    </p>
                                    <p v-if="license.order.email != undefined">
                                        <b>Đặt mua:</b>
                                    </p>
                                    <p v-if="license.order.email != undefined">
                                        <v-icon small left>mdi-email</v-icon> {{license.order.email}}
                                    </p>
                                    <p v-if="license.order.full_name != undefined">
                                        <v-icon small left>mdi-account</v-icon>{{license.order.full_name}}
                                    </p>
                                    <p v-if="license.order.phone != undefined">
                                        <v-icon small left>mdi-phone</v-icon> {{license.order.phone}}
                                    </p>
                                </td>
                                <!-- <td>
                                    {{license.product.name}}
                                </td>
                                <td>
                                    {{license.type_key.name}} - <b>{{license.day_use}} ngày</b>
                                </td> -->
                                <!-- <td>
                                    {{license.number_of_device_use}}
                                </td>
                                <td>
                                    {{license.number_key_per_device}}
                                </td> -->
                                <td style="white-space: nowrap;">
                                    <p>Số lần: <b>{{license.number_change_key}}</b></p>
                                    <p>Đã dùng: <b>{{license.number_can_change_key}}</b></p>
                                </td>
                                <td style="white-space: nowrap">
                                    <v-chip
                                        class="cursor"
                                        style="margin-right: 10px" 
                                        :color="(license.status.value === 1 || license.status === 2) ? 'primary' : 'error'"

                                    >
                                        {{license.status.name}}
                                    </v-chip>
                                    <!-- <span :class="license.status.class">
                                        {{license.status.name}}
                                    </span> -->
                                </td>
                                <td style="white-space: nowrap">
                                    <v-chip
                                        class="cursor"
                                        style="margin-right: 10px" 
                                        :color="license.status_active.value === 2 ? 'primary' : 'error'"
                                    >
                                        {{license.status_active.name}}
                                    </v-chip>
                                    <!-- <span :class="license.status_active.class">
                                        {{license.status_active.name}}
                                    </span> -->
                                </td>
                                <td style="width: 250px">
                                    <v-text-field
                                    dense
                                    v-model="license.email_resend"
                                    label="Email"
                                    outlined
                                    ></v-text-field>
                                    <v-btn
                                        style="margin-right: 10px"
                                        class="text-transform"
                                        color="primary"
                                        @click="sendKeyToMail(license, index)"
                                        :loading="license.loading"
                                    >
                                        Gửi
                                    </v-btn>
                                </td>
                                <td style="white-space: nowrap">
                                    <v-btn
                                        small
                                        depressed
                                        color="warning"
                                        v-if="license.status.value < 2"
                                        style="margin-right: 7px"
                                        @click="editKey(license, index)"
                                        >
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn
                                        small
                                        depressed
                                        color="warning"
                                        v-if="license.status.value == 2"
                                        style="margin-right: 7px"
                                        :to="{name: 'edit-key', params: {id: license.id}}"
                                        >
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn
                                        small
                                        depressed
                                        color="error"
                                        @click="deleteKey(license, index)"
                                        >
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
			</v-col>
		</v-row>
        <div class="text-center" v-if="states.total_page > 1">
            <v-container>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            @input="nextPage"
                            v-model="page"
                            class="my-4"
                            :length="states.total_page"
                            :total-visible="7"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
import Breadcrumb from "./../../Shares/Breadcrumb.vue";
export default {
	name: "CreateOrder",
	// components: { Breadcrumb },
	data() {
		return {
            page: 1,
            loading_accept: false,
			loading: true,
			breadcrumbs: [
				{
					text: "Dashboard",
					disabled: false,
					href: "/admin"
				},
				{
					text: "Tạo đơn",
					disabled: false,
					href: "/admin/order/create"
				}
			],
            states: {
                dialog_edit: false,
                dialog: false,
                products: [],
                type_keys: [],
                type_key_search: [],
                day_numbers: [],
                loading: true,
                message: null,
                licenses: [],
                total_key: '',
                total_page: 0,
                status_key: [
                    {
                        name: 'Tất cả',
                        value: -1
                    },
                    {
                        name: 'Chưa gửi',
                        value: 0
                    },
                    {
                        name: 'Đã gửi chưa kích hoạt',
                        value: 1
                    },
                    {
                        name: 'Đã kích hoạt',
                        value: 2
                    },
                    // {
                    //     name: 'Chưa kích hoạt',
                    //     value: 3
                    // }
                ]
            },
            form_search: {
                amount: '',
                number_of_device_use: '',
                number_key_per_device: '',
                type_key: '',
                product_id: '',
                expire_date: '',
            },
            filter: {
                key_search: '',
                type_key: '-1',
                product: [],
                page: '',
                status_key: -1
            },
            errors: {
                amount: '',
                number_of_device_use: '',
                number_key_per_device: '',
                type_key: '',
                product_id: '',
                expire_date: ''
            },
            data_key_edit: {
                index: '',
                id: '',
                number_of_device_use: '',
                number_key_per_device: '',
                type_key: '',
                product_id: '',
                expire_date: ''
            }
		};
	},
	methods: {
        editKey(license, index) {
            this.states = {
                ...this.states,
                dialog_edit: true,
            }
            this.data_key_edit = {
                index: index,
                id: license.id,
                number_of_device_use: license.number_of_device_use,
                number_key_per_device: license.number_key_per_device,
                type_key: license.type_key.value,
                product_id: license.product.id,
                expire_date: license.expire_date
            }
        },
        deleteKey(license, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: license.id
                        })
                    };
                    fetch(api.delete_key, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            const licenses = [...this.states.licenses];
                            licenses.splice(index, 1);
                            this.states = {
                                ...this.states,
                                licenses: licenses
                            }
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 2000
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    })
                }
            })
        },
        nextPage(page) {
            this.filter['page'] = page;
            this.states = {
                ...this.states,
                licenses: [],
                loading: true
            }
            this.callApi();
        },
        resetForm() {
            this.filter = {
                ...this.filter,
                key_search: '',
                type_key: '-1',
                product: [],
                status_key: -1
            }
            this.states = {
                ...this.states,
                licenses: [],
                loading: true
            }
            this.callApi();
        },
        submitForm() {
            this.states = {
                ...this.states,
                loading: true,
                licenses: []
            }
            this.filter = {
                ...this.filter,
                page: 1
            }
            this.callApi();
            // const requestOptions = {
            //     method: "POST",
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': 'Bearer ' + this.$cookie.get("is_login")
            //     },
            //     body: JSON.stringify(this.filter)
            // };
            // fetch(api.search_key, requestOptions)
            // .then((response) => response.json())
            // .then((result) => {
            //     if (result.success) {
            //         this.states = {
            //             ...this.states,
            //             licenses: result.licenses,
            //             loading: false,
            //             total_key: result.total_key,
            //             total_page: result.total_page
            //         }
            //     } else {
            //         this.states = {
            //             ...this.states,
            //             loading: false
            //         }
            //     }
            // });
        },
        sendKeyToMail(key, index) {
            const license_copy = [...this.states.licenses];
            license_copy[index].loading = true;
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    email: key.email_resend,
                    product_id: key.product.id,
                    key: key.license
                })
            };
            fetch(api.send_key_to_email, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.$swal({
                        title: result.message,
                        icon: 'success',
                        timer: 2000
                    })
                    this.states = {
                        ...this.states,
                    }
                    // this.status_queue = !this.status_queue
                    setTimeout(() => {
                        fetch(api.run_queue);
                    }, 1500);
                } else {
                    this.$swal({
                        title: result.message,
                        icon: 'error',
                        timer: 2000
                    })
                }
                license_copy[index].loading = false;
                this.states = {
                    ...this.states,
                    licenses: license_copy,
                }
            })
        },
        onUpdateKey() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.data_key_edit)
            };
            fetch(api.update_key, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.$swal({
                        title: result.message,
                        icon: 'success',
                        timer: 2000
                    })
                    const licenses = [...this.states.licenses];
                    licenses[this.data_key_edit.index] = result.data;
                    this.states = {
                        ...this.states,
                        licenses: licenses,
                        dialog_edit: false
                    }
                } else {
                    this.$swal({
                        title: result.message,
                        icon: 'error',
                        timer: 2000
                    })
                }
            })
        },
		onSubmitKey() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.form_search)
            };
            fetch(api.create_key, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        ...this.states,
                        dialog: false,
                        total_key: parseInt(this.states.total_key) + parseInt(this.form_search.amount)
                    }
                    this.$swal({
                        title: result.message,
                        icon: 'success',
                        timer: 2000
                    })
                } else {
                    if (result.type == 'validate') {
                        this.errors = result.errors;
                    } else {
                        this.$swal({
                            title: result.message,
                            icon: 'error',
                            timer: 2000
                        })
                    }
                }
            });
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.filter)
            };
            fetch(api.get_key, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    const obj = {name: 'Tất cả', value: -1};
                    const type_key_search = [obj, ...result.type_keys];
                    this.form_search = result.key_default;
                    this.states = {
                        ...this.states,
                        products: result.products,
                        loading: false,
                        type_keys: result.type_keys,
                        day_numbers: result.day_numbers,
                        licenses: result.licenses,
                        type_key_search: type_key_search,
                        total_key: result.total_key,
                        total_page: result.total_page
                    }
                } else {
                    this.states = {
                        ...this.states,
                        message: result.message,
                        loading: false,
                    }
                }
            });
        }
	},
	mounted() {
        this.callApi();
	},
    // watch: {
    //     status_queue() {
    //         setTimeout(() => {
    //             fetch(api.run_queue);
    //         }, 2000);
    //     }
    // }
};
</script>

<style scoped>
table tr td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}
</style>
