<template>
    <div>
		<br>
        <h3>
			Thống kê theo khu vực
		</h3>
		<br/>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
                style="padding-top: 0px; padding-bottom: 0px"
			>
                <h3 >Lọc dữ liệu</h3>
            </v-col>
        </v-row>
        <v-row class="bg-white" style="padding: 0px 10px 0px 10px;">
            <v-col cols="2"
				sm="3"
				md="3">
                <v-select
                    outlined
                    dense
                    :items="states.provinces"
                    label="Chọn khu vực"
                    v-model="states.province_id"
                    item-text="name"
                    item-value="id"
                ></v-select>
            </v-col>
            <v-col cols="2"
				sm="3"
				md="3">
                <v-select
                    outlined
                    dense
                    :items="states.product_searchs"
                    label="Chọn sản phẩm"
                    v-model="states.product_id"
                    item-text="name"
                    item-value="id"
                ></v-select>
            </v-col>
            <v-col cols="2"
				sm="3"
				md="3">
                <v-btn
                    class="text-transform"
                    color="primary"
                    @click="search"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
            </v-col>
        </v-row>
        <hr>
		<v-row class="bg-white">
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
                <v-simple-table class="list-order">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>
                                    STT
                                </th>
                                <th>Phần mềm</th>
                                <th>
                                    Số lượng
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="states.loading">
                                <td class="text-center" colspan="3">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-if="states.message != null">
                                <td class="text-center" colspan="3">
                                    <p>
                                        {{states.message}}
                                    </p>
                                    <v-btn
                                        :to="{name: 'list-order'}"
                                        large
                                        depressed
                                        color="error"
                                        style="margin-right: 7px"
                                        >
                                        <v-icon small left>mdi-reload</v-icon> Tải lại
                                    </v-btn>
                                </td>
                            </tr>
                            <tr v-for="(productItem, index) in states.products"
                                :key="index">
                                <td>
                                    {{index + 1}}
                                </td>
                                <td>
                                    {{productItem.name}}
                                </td>
                                <td>
                                    {{Number(productItem.total_order).toLocaleString()}}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
	</div>
</template>
<script>
import { api } from "./../../../../helpers/const.js";
export default {
	name: "Region",
	data() {
		return {
            states: {
                loading: true,
                message: null,
                products: [],
                product_searchs: [],
                province_id: -1,
                product_id: -1,
                provinces: []
            }
        }
    },
    methods: {
        search() {
            this.states = {
                ...this.states,
                loading: true,
                products: []
            }
            this.callApi();
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    product_id: this.states.product_id,
                    province_id: this.states.province_id
                })
            };
            fetch(api.customer_region, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    const product_searchs = [
                        {
                            id: -1,
                            name: 'Tất cả'
                        },
                        ...result.products
                    ];
                    this.states = {
                        ...this.states,
                        loading: false,
                        products: result.products,
                        provinces: result.provinces,
                        product_searchs: product_searchs
                    }
                } else {
                    this.states = {
                        ...this.states,
                        loading: false,
                        message: result.message
                    }
                }
            })
        }
    },
    mounted() {
		this.callApi()
	}
}
</script>
