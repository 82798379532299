<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
		<br>
		<h3>
			Tạo đơn hàng
		</h3>
				<br/>
		<v-row justify="left">
			<v-btn :to="{name: 'list-order'}" class="text-transform" color="primary" dark v-bind="attrs" v-on="on">
				<v-icon left>mdi-playlist-edit</v-icon> Danh sách đơn hàng
			</v-btn>
		</v-row>
		<br>
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
			<form @submit.prevent="onSubmitOrder">
				<v-row>
					<v-col cols="12" md="12">
						<h3>Thông tin khách hàng</h3>
					</v-col>
					<v-col cols="12" md="12" style="padding: 0px 12px" v-if="list_emails.length > 0">
						<v-chip
						class="ma-2 cursor"
						v-for="(emailItem, index) in list_emails" :key="index"
						>
							<span @click="pickEmail(emailItem)">
								{{emailItem.email}}
							</span>
						</v-chip>
					</v-col>
					<v-col md="4" cols="12">
						<v-text-field
							label="Email *"
							autocomplete="off"
							outlined
							v-model="customer_info.email"
							@keyup.delete="searchEmail"
							@keypress="searchEmail"
							@paste="onPasteEmail"
							required
							dense
						></v-text-field>
						<!-- <v-autocomplete
							autocomplete="new-email"
							required
							v-model="emails"
							:items="list_emails"
							:search-input.sync="search"
							item-text="email"
							item-value="email"
							outlined
							dense
							return-object
							@change="changeEmail"
							label="Địa chỉ Email *"
						></v-autocomplete> -->
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field
							label="Họ tên *"
							outlined
							required
							v-model="customer_info.full_name"
							dense
						></v-text-field>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field
							label="Số điện thoại *"
							required
							outlined
							v-model="customer_info.phone"
							dense
						></v-text-field>
					</v-col>
					<v-col cols="12" md="4">
						<v-autocomplete
							v-model="customer_info.province_id"
							:items="provinces"
							item-value="id"
							item-text="name"
							required
							outlined
							dense
							label="Tỉnh/TP *"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field
							v-model="customer_info.address"
							label="Địa chỉ *"
							placeholder="Địa chỉ (nhớ nhập đủ)"
							required
							outlined
							dense
						></v-text-field>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field
							label="Ghi chú"
							outlined
							v-model="customer_info.note"
							dense
						></v-text-field>
					</v-col>
					<v-col cols="12" md="4">
						<v-autocomplete
							v-model="values"
							:items="products"
							outlined
							dense
							multiple
							item-text="name"
							item-value="product_id"
							return-object
							label="Chọn sản phẩm *"
							chips
            				small-chips
							@change="changeProduct"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field
							label="Công ty"
							outlined
							v-model="customer_info.company"
							dense
						></v-text-field>
					</v-col>
					<v-col cols="12" md="4">
						<v-text-field
							label="MST"
							v-model="customer_info.tax_code"
							outlined
							dense
						></v-text-field>
					</v-col>
					<v-col cols="12" md="12">
						<v-simple-table v-if="product_selected.length > 0">
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">
											Phần mềm
										</th>
										<th class="text-left">
											Loại
										</th>
										<th class="text-left">
											Giá
										</th>
										<th class="text-left">
											Lựa chọn
										</th>
										<th class="text-left">Số lượng</th>
										<th class="text-left">Giảm</th>
										<th class="text-left">Nợ</th>
										<th class="text-left">Thành tiền</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="text-right" style="font-size: 20px; font-weight: 500; background: #fb8c00;color: #fff;" colspan="8">
											{{Number(total(product_selected)).toLocaleString()}}
										</td>
									</tr>
									<tr
										v-for="(item,index) in product_selected"
										:key="index"
										>
										<td>{{ item.name }}</td>
										<td>{{ item.type }}</td>
										<td>{{ Number(item.price_root).toLocaleString() }}</td>
										<td>
											<v-select
												:items="day_numbers"
												item-text="name"
												item-value="value"
												label="Số ngày"
												@change="onChangeUseDayNumber(index, item.use_day_number)"
												name="day_number"
												v-model="item.use_day_number"
												v-if="item.type == product_types.software"
												required
											>
											</v-select>
											<v-select
												:items="product_keys"
												item-text="name"
												item-value="value"
												label="Loại key"
												@change="onChangeTypeKey(index, item.key_type)"
												v-model="item.key_type"
												name="product_key"
												v-if="item.type == product_types.software"
												required
											>
											</v-select>
											<v-select
												:items="method_learns"
												item-text="name"
												item-value="value"
												v-model="item.method_learn"
												label="Phương thức học"
												name="method_learn"
												v-if="item.type == product_types.course"
												required
											>
											</v-select>
											<v-select
												:items="product_bonus"
												item-text="name"
												item-value="product_id"
												label="Tặng key"
												v-model="item.bonus_product_id"
												v-if="item.type == product_types.course"
												required
											>
											</v-select>
										</td>
										<td>
											<v-text-field
												type="number"
												v-model="item.amount"
											></v-text-field>
										</td>
										<td>
											<v-text-field
												type="number"
												value="0"
												v-model="item.discount"
											></v-text-field>
										</td>
										<td>
											<v-text-field
												type="number"
												v-model="item.owe"
												value="0"
											></v-text-field>
										</td>
										<td>
											{{Number(item.price * item.amount - item.owe - item.discount).toLocaleString()}}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
					<v-col md="12">
						<v-btn
						depressed
						x-large
						color="success"
						dark
						type="submit"
						style="width: 100%"
						:loading="loading"
						>
							<v-icon left>mdi-send</v-icon> Tạo yêu cầu đơn hàng
    					</v-btn>
					</v-col>
				</v-row>
			</form>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { api } from "./../../../../../helpers/const.js";
import {debounce} from './../../../../../helpers/function.js'
import Breadcrumb from "./../../../Shares/Breadcrumb.vue";
export default {
	name: "CreateOrder",
	// components: { Breadcrumb },
	data() {
		return {
			loading: false,
			product_types: {},
			product_keys: [],
			method_learns: [],
			day_numbers: [],
			breadcrumbs: [
				{
					text: "Dashboard",
					disabled: false,
					href: "/admin"
				},
				{
					text: "Tạo đơn",
					disabled: false,
					href: "/admin/order/create"
				}
			],
			products: [],
			product_bonus: [],
			product_selected: [],
			provinces: [],
			list_emails: [],
			emails: '',
			search: null,
			customer_info: {
				email: '',
				full_name: '',
				phone: '',
				address: '',
				company: '',
				tax_code: '',
				province_id: '',
				note: ''
			}
		};
	},
	methods: {
		onPasteEmail(evt) {
			this.querySearch(evt.clipboardData.getData('text'));
		},
		changeProduct() {
			this.product_selected = this.values;
		},
		total(data) {
			return data.map(item => item.price * item.amount - item.owe - item.discount).reduce((prev, next) => prev + next);
		},
		onSubmitOrder() {
			this.loading = true;
			if (this.product_selected.length == 0) {
				this.$swal({
					icon: 'warning',
					title: 'Chưa chọn sản phẩm',
					timer: 2000
				});
				this.loading = false
			} else {
				const requestOptions = {
					method: "POST",
					headers: {
						'Authorization': 'Bearer ' + this.$cookie.get("is_login"),
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						'products': this.product_selected,
						'customer_info': this.customer_info
					})
				};
				fetch(api.create_order, requestOptions)
				.then((response) => response.json())
				.then((result) => {
					if (result.success) {
						this.$swal({
							icon: 'success',
							title: result.message,
							timer: 2000,
						});
						this.product_selected = [];
						this.customer_info = {
							email: '',
							full_name: '',
							phone: '',
							address: '',
							company: '',
							tax_code: '',
							province_id: '',
							note: ''
						}
						this.emails = '';
						this.values = '';
					} else {
						this.$swal({
							icon: 'error',
							title: result.message,
							timer: 2000,
						});
					}
					this.loading = false
				});
			}
		},
		searchEmail() {
			if (this.customer_info.email != '') {
				this.querySearch(this.customer_info.email);
			} else {
				this.list_emails = [];
			}
		},
		pickEmail(emailItem) {
			this.customer_info = emailItem;
			this.list_emails = [];
		},
		querySearch: debounce(function(query) {
			// const query = this.query;
			const requestOptions = {
				method: "POST",
				headers: {
					'Authorization': 'Bearer ' + this.$cookie.get("is_login"),
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ query: query }),
			};
			fetch(api.get_email, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success) {
					this.list_emails = result.datas;
				}
			});
		}, 100),
		changeEmail() {
			this.customer_info = this.emails;
		},

		onChangeUseDayNumber(index, day_number) {
			const product_selected = [...this.product_selected];

			if (day_number >= 365) {
				product_selected[index].key_type = 1;
				product_selected[index].price = product_selected[index].price_root;
			} else {
				product_selected[index].key_type = 0;
				product_selected[index].price = 0;
			}
			this.product_selected = product_selected;
		},
		
		onChangeTypeKey(index, type_key) {
			const product_selected = [...this.product_selected];

			if (type_key == 0) {
				product_selected[index].use_day_number = 7;
				product_selected[index].price = 0;
			} else {
				product_selected[index].use_day_number = 365;
				product_selected[index].price = product_selected[index].price_root;
			}
		}
	},
	watch: {
		search(query) {
			if (query != '') {
				// debounce(function(e, query){
				// 	this.querySearch(query);
				// }, 1000)
				// this.query = query;
				this.querySearch(query);
			}
		}
		// search: debounce(function(e, query){
		// 	this.querySearch(query);
		// }, 1000)
	},
	mounted() {
		const requestOptions = {
			method: "GET",
			headers: {
				'Authorization': 'Bearer ' + this.$cookie.get("is_login")
			}
		};
		fetch(api.get_product, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			if (result.success == true) {
				this.products = result.datas;
				this.product_types = result.product_types;
				this.day_numbers = result.day_numbers;
				this.product_keys = result.product_keys;
				this.method_learns = result.method_learns;
				this.product_bonus = result.product_bonus;
				this.provinces = result.provinces;
				//console.log(result.datas);
			}			
		});
	}
};
</script>

<style scoped></style>
