<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
		<h3>
            Export chứng chỉ
		</h3>
		<br/>
		<v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="3"
				sm="3"
				md="3"
			>
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        outlined
                        dense
                        v-model="state.date_exam"
                        label="Chọn ngày thi để Export"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        no-title
                        v-model="state.date_exam"
                        @input="menu = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col
				cols="2"
				sm="4"
				md="4"
			>
                <v-radio-group
                v-model="state.type_export"
                style="margin-top: 0px"
                row
                >
                <v-radio
                    label="Trích ngang (tài khoản)"
                    value="1"
                ></v-radio>
                <v-radio
                    label="Tạo mã (Sheet 1)"
                    value="0"
                ></v-radio>
                </v-radio-group>
               
            </v-col>
            <v-col cols="2"
				sm="1"
				md="1">
                <v-btn
                    class="text-transform"
                    color="success"
                    @click="onExport"
                    :loading="state.loading"
                >
                    <v-icon left>mdi-download</v-icon> Export
                </v-btn>
            </v-col>
        </v-row>
        <hr>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
// import Breadcrumb from "./../../Shares/Breadcrumb.vue";
export default {
	name: "ExportCertificate",
	// components: { Breadcrumb },
	data() {
		return {
            menu: false,
            state: {
                date_exam: '',
                type_export: null,
                loading: false,
            },
			breadcrumbs: [
				{
					text: "Dashboard",
					disabled: false,
					href: "/admin"
				},
				{
					text: "Tạo đơn",
					disabled: false,
					href: "/admin/order/create"
				}
			],
		};
	},
	methods: {
        onExport() {
            this.state = {
                ...this.state,
                loading: true
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    date_exam: this.state.date_exam,
                    type_export: this.state.type_export
                })
            };
            fetch(api.export_certificate, requestOptions)
            .then(res => res.blob())
            .then(
                (result) => {
                    this.state = {
                        ...this.state,
                        loading: false
                    }
                    const url = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${Date.now()}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            )
        }
	},
	mounted() {
        
	}
};
</script>

<style scoped>

</style>
