<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
		<br>
		<h3>
			Thông tin yêu cầu gửi key
		</h3>
	    <br/>
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
			<form ref="reset_form" @submit.prevent="onSubmitSendKey">
				<v-row>
					<v-col cols="12" md="12">
						<h3>Thông tin khách hàng</h3>
					</v-col>
					<v-col md="12" style="padding: 0px 12px" v-if="list_emails.length > 0">
						<v-chip
						class="ma-2 cursor"
						v-for="(emailItem, index) in list_emails" :key="index"
						>
							<span @click="pickEmail(emailItem)">
								{{emailItem.email}}
							</span>
						</v-chip>
					</v-col>
					<v-col cols="6" md="4">
						<v-text-field
							label="Email *"
							autocomplete="off"
							outlined
							v-model="state.form.email"
							@keyup.delete="searchEmail"
							@keypress="searchEmail"
							@paste="onPasteEmail"
							required
							dense
						></v-text-field>
						<!-- <v-autocomplete
							autocomplete="new-email"
							required
							v-model="emails"
							:items="list_emails"
							:search-input.sync="search"
							:error-messages="errors.email"
							item-text="email"
							item-value="email"
							outlined
							dense
							return-object
							@change="changeEmail"
							label="Địa chỉ Email *"
						></v-autocomplete> -->
					</v-col>
					<v-col cols="6" md="4">
						<v-text-field
							label="Họ tên *"
							outlined
							required
							v-model="state.form.full_name"
							:error-messages="errors.full_name"
							dense
						></v-text-field>
					</v-col>
					<v-col cols="6" md="4">
						<v-text-field
							label="Số điện thoại *"
							v-model="state.form.phone"
							:error-messages="errors.phone"
							required
							outlined
							dense
						></v-text-field>
					</v-col>
					<v-col cols="6" md="4">
						<v-select
						:items="state.type_key"
						v-model="state.form.type_key"
						:error-messages="errors.type_key"
						item-text="name"
						item-value="value"
						label="Loại key"
						outlined
						dense
						></v-select>
					</v-col>
					<v-col cols="6" md="4">
						<v-select
						:items="state.times"
						v-model="state.form.day_use"
						item-text="name"
						item-value="value"
						label="Số ngày"
						outlined
						dense
						></v-select>
					</v-col>
					<v-col cols="6" md="4">
						<v-autocomplete
							:items="state.products"
							outlined
							dense
							item-text="name"
							item-value="id"
							label="Chọn sản phẩm *"
							v-model="state.form.product_id"
							chips
            				small-chips
						></v-autocomplete>
					</v-col>
					<v-col cols="12" md="12">
						<v-textarea
						solo
						name="input-7-4"
						label="Ghi chú"
						v-model="state.form.note"
						></v-textarea>
					</v-col>
					<v-col md="12">
						<v-btn
						depressed
						x-large
						color="success"
						dark
						type="submit"
						style="width: 100%"
						:loading="loading"
						>
							<v-icon left>mdi-send</v-icon> Gửi yêu cầu
    					</v-btn>
					</v-col>
				</v-row>
			</form>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
import {debounce} from './../../../../helpers/function.js'
import Breadcrumb from "./../../Shares/Breadcrumb.vue";
export default {
	name: "SendKey",
	// components: { Breadcrumb },
	data() {
		return {
			search: null,
			emails: '',
			list_emails: [],
			loading: false,
			breadcrumbs: [
				{
					text: "Dashboard",
					disabled: false,
					href: "/admin"
				},
				{
					text: "Tạo đơn",
					disabled: false,
					href: "/admin/order/create"
				}
			],
			errors: {

			},
			state: {
				times: [],
				products: [],
				type_key: [
					{
						'name': 'Key thương mại',
						'value': 1
					},
					{
						'name': 'Key thử nghiệm',
						'value': 0
					},
					{
						'name': 'Key lớp học',
						'value': 2
					}
				],
				form: {
					email: '',
					full_name: '',
					phone: '',
					type_key: 0,
					product_id: '',
					note: '',
					day_use: 7
				}
			}
		};
	},
	methods: {
		// onChangeTypeKey() {
		// 	if (this.state.form.type_key > 0) {
		// 		this.state = {
		// 			...this.state,
		// 			form: {
		// 				...this.state.form,
		// 				day_use: 365
		// 			}
		// 		}
		// 	} else {
		// 		this.state = {
		// 			...this.state,
		// 			form: {
		// 				...this.state.form,
		// 				day_use: 7
		// 			}
		// 		}
		// 	}
		// },
		onPasteEmail(evt) {
			this.querySearch(evt.clipboardData.getData('text'));
		},
		querySearch: debounce(function(query) {
			const requestOptions = {
				method: "POST",
				headers: {
					'Authorization': 'Bearer ' + this.$cookie.get("is_login"),
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ query: query }),
			};
			fetch(api.get_email, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success) {
					this.list_emails = result.datas;
				}
			});
		}, 100),
		searchEmail() {
			this.querySearch(this.state.form.email);
		},
		pickEmail(emailItem) {
			// this.state.form = emailItem;
			this.state = {
				...this.state,
				form: {
					...this.state.form,
					email: emailItem.email,
					full_name: emailItem.full_name,
					phone: emailItem.phone,
				}
			}
			this.list_emails = [];
		},
		changeEmail() {
			this.state = {
				...this.state,
				form: {
					...this.state.form,
					email: this.emails.email,
					full_name: this.emails.full_name,
					phone: this.emails.phone,
				}
			}
			//this.state.form.email = this.emails;
		},
		onSubmitSendKey() {
			if (this.state.form.product_id == '') {
				this.$swal({
					icon: 'warning',
					title: 'Chưa chọn sản phẩm',
					timer: 2000
				});
			} else {
				this.loading = true;
				const requestOptions = {
					method: "POST",
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + this.$cookie.get("is_login")
					},
					body: JSON.stringify(this.state.form)
				};
				fetch(api.send_key, requestOptions)
				.then((response) => response.json())
				.then((result) => {
					if (result.success) {
						this.$swal({
							icon: 'success',
							title: result.message,
						});
						this.state = {
							...this.state,
							form: {
								email: '',
								full_name: '',
								phone: '',
								type_key: 0,
								product_id: '',
								note: '',
								day_use: 7
							}
						}
						this.list_emails = [];
						this.emails = '';
						this.loading = false
					} else {
						if (result.type == 'validate') {
							this.errors = result.errors;
						} else if (result.type == 'error') {
							this.$swal({
								icon: 'error',
								title: result.message,
								timer: 2000,
							});
						}
						this.loading = false
					}
				});
			}
		}
	},
	watch: {
		search(query) {
			if (query != '') {
				// debounce(function(e, query){
				// 	this.querySearch(query);
				// }, 1000)
				// this.query = query;
				this.querySearch(query);
			}
		}
		// search: debounce(function(e, query){
		// 	this.querySearch(query);
		// }, 1000)
	},
	mounted() {
		const requestOptions = {
			method: "GET",
			headers: {
				'Authorization': 'Bearer ' + this.$cookie.get("is_login")
			}
		};
		fetch(api.send_key_form, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			if (result.success == true) {
				this.state = {
					...this.state,
					products: result.products,
					times: result.times
				}
			}			
		});
	}
};
</script>

<style scoped></style>
