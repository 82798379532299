<template>
	<div>
        <br>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
		<h3>
            Import chứng chỉ
		</h3>
		<br>
        <v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <form @submit.prevent="onSubmitImport">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    label="Tên đợt upload"
                                    v-model="form.name_upload"
                                    :error-messages="errors.name"
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-file-input
                                    v-model="form.file_upload"
                                    label="File input"
                                    :error-messages="errors.file"
                                    outlined
                                    dense
                                ></v-file-input>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-btn
                                    color="primary"
                                    depressed
                                    type="submit"
                                >
                                    Xác nhận
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                Lưu ý:
                                <p>
                                    Download file import mẫu <a target="_blank" :href="url_file_sample">Tại đây</a>. File import mẫu có thay đổi 1 xíu ở cột nhập thành phố. Hiện tại cột thành phố nhập ID chứ không phải nhập chữ như ở hệ thống cũ nữa. ID thành phố/tỉnh gõ và lấy chữ số ở đầu dưới đây:
                                </p>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-autocomplete
                                    :items="states.provinces"
                                    outlined
                                    dense
                                    multiple
                                    item-text="name_extra_id"
                                    item-value="id"
                                    label="Mã tỉnh"
                                    chips
                                    small-chips
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <p>
                                    - Đặt tên đợt nếu dùng ngày tháng thì theo định dạng ví dụ 10-10-2020, không dùng 10/10/2020 nhé.
                                </p>
                                <p>
                                    - Dữ liệu phải đúng như file mẫu để import, nhập dữ liệu tránh thừa các dấu cách ở cuối.
                                </p>
                                <p>
                                    - Tại cột lĩnh vực nếu có nhiều thì phân cách nhau bằng dấu chấm phẩy (;) riêng lĩnh vực cuối cùng không có
                                </p>
                                <p>
                                    - Cột hạng sát hạch nhập hạng phân cách nhau bởi dấu chấm phẩy tương tự cột lĩnh vực
                                </p>
                                <p>
                                    - Trước khi import đánh dấu khoảng 20 dòng phía dưới cùng của bảng, bấm Ctrl trừ (-) vài lần để xóa các dòng dữ liệu thừa mà mắt thường có thể không nhìn thấy.
                                </p>
                                
                            </v-col>
                        </v-row>
                    </v-container>
                </form>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
import Breadcrumb from "./../../Shares/Breadcrumb.vue";
export default {
	name: "ImportCertificate",
	// components: { Breadcrumb },
	data() {
		return {
			loading: true,
            url_file_sample: api.url_file_sample,
            form: {
                name_upload: '',
                file_upload: []
            },
            key_search: '',
            states: {
                dialog: false,
                loading: true,
                message: null,
                provinces: []
            },
            errors: {
                name: '',
                file: '',
            }
		};
	},
	methods: {
        onSubmitImport() {
            const formData = new FormData();
            formData.append('file', this.form.file_upload);
            formData.append('name', this.form.name_upload);
            const requestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: formData
            };
            fetch(api.import_certificate, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.$swal({
                        title: result.message,
                        icon: 'success',
                        timer: 2000
                    })
                } else {
                    if (result.type == 'validate') {
                        this.errors = result.errors;
                    } else {
                        this.$swal({
                            title: result.message,
                            icon: 'error',
                            timer: 2000
                        })
                    }
                }
            });
        },
        callApi() {
            const requestOptions = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                }
            };
            fetch(api.get_province, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        provinces: result.provinces
                    }
                }
            });
        }
	},
	mounted() {
        this.callApi();
	}
};
</script>

<style scoped>

</style>
