<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
		<h3>
			Khách sử dụng khóa mềm
		</h3>
		<br/>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
                style="padding-top: 0px; padding-bottom: 0px"
			>
                <h3 >Lọc dữ liệu</h3>
            </v-col>
            <v-col
				cols="2"
				sm="2"
				md="2"
			>
                <v-text-field
                dense
                v-model="states.filters.key_search"
                label="Từ khóa tìm kiếm..."
                hide-details
                outlined
                ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field
                    label="Từ"
                    type="date"
                    dense
                    outlined
                    v-model="states.filters.date_start"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field
                    label="Đến"
                    type="date"
                    dense
                    outlined
                    v-model="states.filters.date_end"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="2"
				sm="3"
				md="3">
                <v-autocomplete
                    chips
                    small-chips
                    :items="states.products"
                    item-value="id"
                    item-text="name"
                    outlined
                    v-model="states.filters.product_id"
                    dense
                    label="Sản phẩm"
                ></v-autocomplete>
            </v-col>
            <v-col cols="2"
				sm="3"
				md="3">
                <v-autocomplete
                    chips
                    small-chips
                    :items="states.provinces"
                    item-value="id"
                    item-text="name"
                    outlined
                    v-model="states.filters.province_id"
                    dense
                    label="Tỉnh/TP"
                ></v-autocomplete>
            </v-col>
            <v-col cols="2"
				sm="4"
				md="4">
                <v-btn
                    class="text-transform"
                    color="primary"
                    @click="search"
                    style="margin-right: 10px"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="success"
                    style="margin-right: 10px"
                    @click="exportCustomerUseKeySoftware"
                    :loading="states.loading_export"
                >
                    <v-icon left>mdi-download</v-icon> Export
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="error"
                    @click="reload"
                >
                    <v-icon left>mdi-reload</v-icon> Tải lại
                </v-btn>
            </v-col>
        </v-row>
        <hr>
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table class="list-order">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Sản phẩm</th>
                            <th class="text-left">
                                Khách hàng
                            </th>
                            <th class="text-left">
                                Quản lý
                            </th>
                            <th class="text-left">
                                Ghi chú
                            </th>
                            <th class="text-left">
                                Hành động
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="states.loading">
                            <td class="text-center" colspan="12">
                                <v-progress-circular
                                :size="50"
                                color="primary"
                                indeterminate
                                ></v-progress-circular>
                            </td>
                        </tr>
                        <!-- <tr v-if="states.message != null">
                            <td class="text-center" colspan="12">
                                <p>
                                    {{states.message}}
                                </p>
                                <v-btn
                                    :to="{name: 'list-order'}"
                                    large
                                    depressed
                                    color="error"
                                    style="margin-right: 7px"
                                    >
                                    <v-icon small left>mdi-reload</v-icon> Tải lại
                                </v-btn>
                            </td>
                        </tr> -->
                        <tr v-for="(customerItem, index) in states.customers" :key="index">
                            <td>
                                {{index + 1}}
                            </td>
                            <td>
                                <p>
                                    Phần mềm: <b>{{customerItem.product.name}} - {{Number(customerItem.product.price).toLocaleString()}}</b>
                                </p>
                                <p>
                                    Key: <b>{{customerItem.license}}</b>
                                </p>
                                <p>
                                    Loại key: <b>{{customerItem.type_key.name}}</b>
                                </p>
                                <p>
                                    Ngày hết hạn: <b>{{customerItem.date_expire}}</b>
                                </p>
                                
                            </td>
                            <td>
                                <p>
                                    <b>{{customerItem.customer.name}}</b>
                                </p>
                                <p>
                                    {{customerItem.customer.phone}} / {{customerItem.customer.email}}
                                </p>
                                <p>
                                    Ngày hoạt động cuối: <b>{{customerItem.last_runing_date}}</b>
                                </p>
                            </td>
                            <td>
                                {{customerItem.order.saler}}
                            </td>
                            <td>
                                <v-textarea
                                outlined
                                name="input-7-4"
                                label="Ghi chú"
                                v-model="customerItem.note"
                                @change="updateKey(customerItem)"
                                :value="customerItem.note"
                                ></v-textarea>
                            </td>
                            <td>
                                <p>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                dark
                                                depressed
                                                color="warning"
                                                style="margin-right: 7px"
                                                :to="{name: 'edit-key', params: {id: customerItem.id}}"
                                                >
                                                <v-icon small>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Sửa/Gia hạn</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                dark
                                                depressed
                                                color="error"
                                                style="margin-right: 7px"
                                                @click="deleteCustomer(customerItem.id, index)"
                                                >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Xóa</span>
                                    </v-tooltip>
                                </p>
                                <p>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                dark
                                                depressed
                                                color="success"
                                                style="margin-right: 7px"
                                                @click="resendKey(customerItem)"
                                                >
                                                <v-icon small>mdi-email-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Gửi lại mail</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                dark
                                                depressed
                                                color="primary"
                                                style="margin-right: 7px"
                                                @click="blockKey(customerItem, index)"
                                                >
                                                <v-icon small>mdi-lock</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Block key</span>
                                    </v-tooltip>
                                </p>
                                <p>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                depressed
                                                color="secondary"
                                                style="margin-right: 7px"
                                                @click="resetKey(customerItem)"
                                                >
                                                <v-icon small>mdi-restore</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Đặt lại key</span>
                                    </v-tooltip>
                                    <v-tooltip top v-if="customerItem.order.id != undefined">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                @click="printBill(customerItem.order)"
                                                depressed
                                                color="secondary"
                                                style="margin-right: 7px"
                                                >
                                                <v-icon small>mdi-printer</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>In hóa đơn {{customerItem.order.id}}</span>
                                    </v-tooltip>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
			</v-col>
		</v-row>
        <div class="text-center" v-if="states.filters.total_page > 1">
            <v-container>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            @input="nextPage"
                            v-model="states.filters.page"
                            class="my-4"
                            :length="states.filters.total_page"
                            :total-visible="7"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
	</div>
</template>

<script>
import { api } from "../../../../helpers/const.js";
import {convertNumber, printBill} from "../../../../helpers/function.js";

export default {
	name: "UseKeySoftware",
	data() {
		return {
            states: {
                loading_export: false,
                loading: true,
                products: [{id: -1, name: 'Tất cả'}],
                provinces: [],
                filters: {
                    key_search: '',
                    date_start: new Date().getFullYear() + '-' + convertNumber(parseInt(1 + new Date().getMonth())) + '-01',
                    date_end: new Date().getFullYear() + '-' + convertNumber(parseInt(1 + new Date().getMonth())) + '-' + convertNumber(new Date().getDate()),
                    product_id: -1,
                    province_id: -1,
                    page: 1,
                    total_page: 0,
                    type_search: 'search'
                },
                customers: [],
            }
		};
	},
	methods: {
        reload() {
            this.states = {
                ...this.states,
                customers: [],
                loading: true,
                filters: {
                    key_search: '',
                    date_start: new Date().getFullYear() + '-' + convertNumber(parseInt(1 + new Date().getMonth())) + '-01',
                    date_end: new Date().getFullYear() + '-' + convertNumber(parseInt(1 + new Date().getMonth())) + '-' + convertNumber(new Date().getDate()),
                    product_id: -1,
                    province_id: -1,
                    page: 1,
                    total_page: 0,
                }
            }
            this.callApi();
        },
        search() {
            const filters = {...this.states.filters};
            filters.page = 1;
            filters.total_page = 0;
            this.states = {
                ...this.states,
                filters: filters,
                customers: [],
                loading: true
            }
            this.callApi();
        },
        printBill(data) {
            printBill(data);
        },
        nextPage(page) {
            this.states = {
                ...this.states,
                page: page,
                customers: [],
                loading: true
            }
            this.callApi();
        },
        exportCustomerUseKeySoftware() {
            this.states = {
                ...this.states,
                loading_export: true,
            }
            const body = this.states.filters;
            body['type_search'] = 'export';
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(body)
            };
            fetch(api.customer_use_key_software, requestOptions)
            .then(res => res.blob())
            //.then((response) => response.json())
            .then(
                (result) => {
                    this.states = {
                        ...this.states,
                        loading_export: false,
                    }
                    const date = new Date();
                    const date_export = date.getDate() + '/' + parseInt(date.getMonth() + 1) + '/' + date.getFullYear();
                    const url = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `khach-hang-su-dung-khoa-mem_${date_export}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            )
        },
        resendKey(data) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn gửi lại key này?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            email: data.customer.email,
                            product_id: data.product.id,
                            key: data.license
                        })
                    };
                    fetch(api.send_key_to_email, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 2000
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    })
                }
            })
        },
        deleteCustomer(id, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                            },
                            body: JSON.stringify({
                                id: id
                            })
                        };
                        fetch(api.remove_key, requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.success) {
                                this.$swal({
                                    title: result.message,
                                    timer: 1000,
                                    icon: 'success'
                                })
                                const customer_copy = [...this.states.customers];
                                customer_copy.splice(index, 1);
                                this.states = {
                                    ...this.states,
                                    customers: customer_copy
                                }
                            } else {
                                this.$swal({
                                    title: result.message,
                                    timer: 1000,
                                    icon: 'error'
                                })
                            }
                        })
                }
            })
            
        },
        updateKey(data) {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    id: data.id,
                    data_update: {
                        note: data.note,
                    }
                })
            };
            fetch(api.update_key_global, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.$swal({
                        title: result.message,
                        timer: 500,
                        icon: 'success'
                    })
                } else {
                    this.$swal({
                        title: result.message,
                        timer: 1000,
                        icon: 'error'
                    })
                }
            })
        },
        blockKey(data, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn block key này?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: data.id,
                        })
                    };
                    fetch(api.block_key, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 2000
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    })
                }
                
            })
        },
        resetKey(data) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn đặt lại key?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: data.id
                        })
                    };
                    fetch(api.reset_key, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                timer: 1000,
                                icon: 'success'
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                timer: 1000,
                                icon: 'error'
                            })
                        }
                    })
                }
            })
        },
        updateOrder(data) {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    order_id: data.id,
                    data_update: {
                        note: data.note
                    }
                })
            };
            fetch(api.update_order_global, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        title: result.message,
                        timer: 1000,
                        icon: 'error'
                    })
                }
            })
        },
        callApi() {
            const body = {...this.states.filters};
            body['type_search'] = 'search';
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(body)
            };
            fetch(api.customer_use_key_software, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    const products = [...this.states.products, ...result.products];
                    this.states = {
                        ...this.states,
                        customers: result.customers,
                        products: products,
                        provinces: result.provinces,
                        filters: {
                            ...this.states.filters,
                            total_page: result.total_page
                        },
                        loading: false
                    }
                } else {
                    this.states = {
                        ...this.states,
                        message: result.message,
                        loading: false
                    }
                }
            });
        }
	},
	mounted() {
		this.callApi()
	}
};
</script>

<style scoped>
table tr td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    white-space: nowrap;
}
</style>
