<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
		<br>
        <h3>
			Tổng hợp số liệu KPI
		</h3>
		<br/>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
                style="padding-top: 0px; padding-bottom: 0px"
			>
                <h3 >Lọc dữ liệu</h3>
            </v-col>
            
            <v-col cols="2"
				sm="3"
				md="3">
                <v-autocomplete
                    chips
                    small-chips
                    :items="states.months"
                    item-value="value"
                    item-text="name"
                    outlined
                    v-model="states.filters.month"
                    dense
                    label="Nhân viên"
                ></v-autocomplete>
            </v-col>
            <v-col cols="2"
				sm="3"
				md="3">
                <v-autocomplete
                    chips
                    small-chips
                    :items="states.years"
                    item-value="value"
                    item-text="name"
                    outlined
                    v-model="states.filters.year"
                    dense
                    label="Nhân viên"
                ></v-autocomplete>
            </v-col>
             <v-col cols="2"
				sm="3"
				md="3">
                <v-btn
                    class="text-transform"
                    color="primary"
                    @click="search"
                    style="margin-right: 10px"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="success"
                    @click="exportKpi"
                    :loading="states.loading_export"
                >
                    <v-icon left>mdi-download</v-icon> Export
                </v-btn>
            </v-col>
        </v-row>
        <hr>
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table class="list-order">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    STT
                                </th>
                                <th>Nhân viên</th>
                                <th class="text-left">
                                    Doanh số
                                </th>
                                <th class="text-left">
                                    Trả VACC
                                </th>
                                <th class="text-left">
                                    Trả VACE
                                </th>
                                <th class="text-left">
                                    Thưởng
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="states.loading">
                                <td class="text-center" colspan="12">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-if="states.message != null">
                                <td class="text-center" colspan="13">
                                    <p>
                                        {{states.message}}
                                    </p>
                                    <v-btn
                                        :to="{name: 'list-order'}"
                                        large
                                        depressed
                                        color="error"
                                        style="margin-right: 7px"
                                        >
                                        <v-icon small left>mdi-reload</v-icon> Tải lại
                                    </v-btn>
                                </td>
                            </tr>
                            <tr v-for="(userItem, index) in states.users" :key="index">
                                <td>
                                    {{index + 1}}
                                </td>
                                <td>
                                    {{userItem.full_name}}
                                </td>
                                <td>
                                    {{Number(userItem.total_revenue).toLocaleString()}}
                                </td>
                                <td>
                                    {{Number(userItem.total_revenue_vacc).toLocaleString()}}
                                </td>
                                <td>
                                    {{Number(userItem.total_revenue_vace).toLocaleString()}}
                                </td>
                                <td>
                                    {{Number(userItem.bonus).toLocaleString()}}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
export default {
	name: "DetailEmployee",
	// components: { Breadcrumb },
	data() {
		return {
            states: {
                loading: true,
                message: null,
                users: [],
                months: [],
                years: [],
                filters: {
                    month: parseInt(new Date().getMonth() + 1),
                    year: new Date().getFullYear(),
                    type_search: 'search'
                }
            }
		};
	},
	methods: {
        search() {
            this.states = {
                ...this.states,
                loading: true,
                users: []
            }
            this.callApi();
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.statistical_kpi, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        ...this.states,
                        loading: false,
                        users: result.data_users,
                        months: result.months,
                        years: result.years
                    }
                } else {
                    this.states = {
                        ...this.states,
                        message: result.message,
                        loading: false
                    }
                }
            });
        },
        exportKpi() {
            const filters = {...this.states.filters};
            filters.type_search = 'export';
            this.states = {
                ...this.states,
                filters: filters,
                loading_export: true
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.statistical_kpi, requestOptions)
            .then(res => res.blob())
            .then(
                (result) => {
                    filters.type_search = 'search';
                    this.states = {
                        ...this.states,
                        loading_export: false,
                        filters: filters
                    }
                    const date = new Date();
                    const date_export = date.getDate() + '/' + parseInt(date.getMonth() + 1) + '/' + date.getFullYear();
                    const url = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `kpi.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            )
        },
	},
	mounted() {
		this.callApi()
	}
};
</script>

<style scoped>
table tr td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    white-space: nowrap;
}
</style>
