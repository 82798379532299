const domain = process.env.VUE_APP_API;
const domain_editor = process.env.VUE_APP_EDITOR;
export const api = {
    url_file_sample: process.env.VUE_APP_FILE_IMPORT_SAMPLE,
    editor: domain_editor,
    domain: domain,
    login: domain + 'login',
    forget_password: domain + 'forget-password',
    reset_password: domain + 'reset-password',
    product_add: domain + 'product/add',
    product_edit: domain + 'product/edit',
    product_list: domain + 'product/list',
    get_product: domain + 'product/get',
    get_email: domain + 'order/email',
    create_order: domain + 'order/create',
    update_order: domain + 'order/update',
    get_data_order: domain + 'order/get-data-order',
    get_category: domain + 'category/list',
    list_order: domain + 'order/list',
    list_order_from_gxd: domain + 'order/from-gxd',
    my_order: domain + 'order/my-order',
    delete_order: domain + 'order/delete',
    update_order_global: domain + 'order/update-global',
    update_all: domain + 'update-all',
    accept_order: domain + 'order/accept',
    list_order_search: domain + 'order/search',
    create_role: domain + 'role/create',
    list_role: domain + 'role/list',
    delete_role: domain + 'role/delete',
    permission_list: domain + 'permission/list',
    permission_create: domain + 'permission/create',
    permission_update: domain + 'permission/update',
    permission_delete: domain + 'permission/delete',
    upload_file: domain + 'file-manager/store',
    list_file: domain + 'file-manager/list',
    remove_file: domain + 'file-manager/delete',
    search_file: domain + 'file-manager/search',
    create_email: domain + 'email/create',
    email_list: domain + 'email/list',
    update_email: domain + 'email/update',
    delete_email: domain + 'email/delete',
    resend_key_hardware: domain + 'email/resend-key-hardware',
    get_smtp_setting: domain + 'email/get-smtp-info',
    update_smpt_setting: domain + 'email/update-smtp',
    get_key: domain + 'key/get-key',
    filter_key_global: domain + 'key/filter-global',
    update_key_global: domain + 'key/update-key',
    info_key: domain + 'key/info',
    create_key: domain + 'key/create-key',
    send_key_form: domain + 'key/send-key-form',
    send_key: domain + 'key/send-key',
    update_key: domain + 'key/update',
    update_key_actived: domain + 'key/update-key-actived',
    search_key: domain + 'key/search-key',
    delete_key: domain + 'key/delete',
    get_key_by_product: domain + 'key/product-by-key',
    remove_key: domain + 'key/delete-key',
    reset_key: domain + 'key/reset-key',
    list_user: domain + 'user/list',
    create_user: domain + 'user/create',
    update_user: domain + 'user/update',
    delete_user: domain + 'user/delete',
    import_certificate: domain + 'certificate/import',
    export_certificate: domain + 'certificate/export',
    import_extra_certificate: domain + 'certificate/import/extra',
    list_certificate_wait_accept: domain + 'certificate/import/wait-accept',
    list_certificate_approved: domain + 'certificate/import/approved',
    merge_certificate: domain + 'certificate/import/merge',
    get_province: domain + 'address/get-province',
    delete_certificate_import: domain + 'certificate/import/delete',
    delete_one_certificate_import: domain + 'certificate/import/one-delete',
    accept_one_certificate_import: domain + 'certificate/import/one-accept',
    accept_certificate_import: domain + 'certificate/import/accept',
    certificate_import_detail: domain + 'certificate/import/detail',
    certificate_import_detail_approved: domain + 'certificate/detail-approved',
    certificate_list: domain + 'certificate/list',
    search_certificate: domain + 'certificate/search',
    update_decree_in_certificate: domain + 'certificate/import/update-decree',
    update_date_exam_certificate: domain + 'certificate/import/update-date-exam',
    update_date_exam_certificate_approved: domain + 'certificate/update-date-exam-certificate-approved',
    update_one_date_exam_certificate: domain + 'certificate/import/update-one-date-exam',
    get_edit_certificate_import: domain + 'certificate/import/edit',
    update_certificate_import: domain + 'certificate/import/update',
    update_certificate_accepted: domain + 'order/update-certificate',
    dashboard: domain + 'dashboard',
    send_mail_remind: domain + 'email/send-mail-remind',
    vacc_vace: domain + 'statistical/vacc-vace',
    vacc: domain + 'statistical/vacc',
    vace: domain + 'statistical/vace',
    export_vacc_vace_by_date_payment: domain + 'statistical/export-vacc-vace-by-date_payment',
    status_payment: domain + 'statistical/status-payment',
    employee_and_product: domain + 'statistical/employee-and-product',
    statistical_employee_detail: domain + 'statistical/employee-detail',
    statistical_kpi: domain + 'statistical/kpi',
    statistical_product: domain + 'statistical/product',
    statistical_owe: domain + 'statistical/owe',
    change_status_vacc_vace: domain + 'statistical/change-status-vacc-vace',
    change_status_owe: domain + 'statistical/change-status-owe',
    search_all: domain + 'search',
    update_order_from_search: domain + 'order/update-order-from-search',
    update_status_vacc_vace: domain + 'order/update-status-vacc-vace',
    update_key_from_search: domain + 'key/update-key-from-search',
    update_key_from_dashboard: domain + 'key/update-key-from-dashboard',
    run_queue: domain + 'run-queue',
    get_key_sended_email: domain + 'key/sended-email',
    block_key: domain + 'key/block',
    send_key_to_email: domain + 'order/send-key-to-email',
    resend_email: domain + 'order/resend-email',
    customer_not_active: domain + 'customer/not-active',
    customer_about_to_expire: domain + 'customer/about-to-expire',
    customer_region: domain + 'customer/region',
    customer_bonus_key: domain + 'customer/bonus-key',
    delete_customer_bonus_key: domain + 'customer/delete-bonus-key',
    customer_use_key_hardware: domain + 'customer/use-key-hardware',
    customer_use_course: domain + 'customer/use-course',
    customer_use_key_software: domain + 'customer/use-key-software',
    customer_use_key_test: domain + 'customer/use-key-test',
    customer_use_certificate: domain + 'customer/use-certificate'
}