<template>
    <div>
        <!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
        <h3>
            Danh sách quyền truy cập
        </h3>
        <br/>
        <v-row justify="left">
			<v-dialog @click:outside="state = {
                                    ...state,
                                    dialog: false,
                                    errors: {
                                        'name': '',
                                        'code': '',
                                        'permission_group_id': '',
                                        'description': ''
                                    },
                                    form_datas: {
                                        'name': '',
                                        'code': '',
                                        'permission_group_id': '',
                                        'description': ''
                                    },
                                    permission_id: ''
                                }" v-model="state.dialog" persistent max-width="800px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn class="text-transform" color="primary" dark v-bind="attrs" v-on="on">
						<v-icon left>mdi-plus</v-icon>Thêm mới
					</v-btn>
				</template>
				<v-card>
					<v-card-title>
						<span class="text-h5">Thông tin quyền</span>
					</v-card-title>
					<form @submit.prevent="onSubmitRole">
						<v-card-text>
							<v-container>
								<v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                            v-model="state.form_datas.code"
                                            label="Mã quyền truy cập *"
                                            :disabled="state.permission_id != ''"
                                            :error-messages="state.errors.code"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                            v-model="state.form_datas.name"
                                            label="Tên quyền truy cập*"
                                            outlined
                                            :error-messages="state.errors.name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-select
                                            :items="state.permission_groups"
                                            item-text="name"
                                            item-value="id"
                                            label="Nhóm quyền"
                                            outlined
                                            name="day_number"
                                            :error-messages="state.errors.permission_group_id"
                                            required
                                            v-model="state.form_datas.permission_group_id"
                                        >
                                        </v-select>
                                    </v-col>
                                    
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea
                                        outlined
                                        name="input-7-4"
                                        label="Mô tả quyền truy cập"
                                        v-model="state.form_datas.description"
                                        :error-messages="state.errors.description"
                                        ></v-textarea>
                                    </v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
                                @click="state = {
                                    ...state,
                                    dialog: false,
                                    errors: {
                                        'name': '',
                                        'code': '',
                                        'permission_group_id': '',
                                        'description': ''
                                    },
                                    index_edit: '',
                                    permission_id: ''
                                }"
							>
								Đóng
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								type="submit"
							>
								Lưu
							</v-btn>
						</v-card-actions>
					</form>
				</v-card>
			</v-dialog>
		</v-row>
        <br>
        <v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    STT
                                </th>
                                <!-- <th>
                                    Nhóm quyền
                                </th> -->
                                <th>Mã</th>
                                <th class="text-left">
                                    Tên
                                </th>
                                <th class="text-left">
                                    Mô tả
                                </th>
                                <th>
                                    Hành động
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="state.loading">
                                <td class="text-center" colspan="6">
                                    <v-progress-circular
                                    :size="40"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <template v-for="permissionGrItem in state.permissions">
                                <tr v-bind:key="permissionGrItem" style="background: #eee">
                                    <td colspan="6">{{ permissionGrItem.name }}</td>
                                </tr>
                                <tr v-for="(permissionItem, index) in permissionGrItem.permissions" :key="index">
                                    <td>
                                        {{ index+1 }}
                                    </td>
                                    <!-- <td>
                                        {{ permissionItem.permission_group.name }}
                                    </td> -->
                                    <td>
                                        {{ permissionItem.code }}
                                    </td>
                                    <td>
                                        {{ permissionItem.name }}
                                    </td>
                                    <td>
                                        {{ permissionItem.description }}
                                    </td>
                                    <td>
                                        <v-btn
                                            small
                                            depressed
                                            color="warning"
                                            style="margin-right: 7px"
                                            @click="state = {
                                                ...state,
                                                'dialog': true,
                                                'form_datas': {
                                                    'name': permissionItem.name,
                                                    'code': permissionItem.code,
                                                    'description': permissionItem.description,
                                                    'permission_group_id': permissionGrItem.id,
                                                },
                                                'permission_id': permissionItem.id,
                                                'index_edit': index,
                                                'errors': {
                                                    'name': '',
                                                    'code': '',
                                                    'permission_group_id': '',
                                                    'description': ''
                                                }
                                            }"
                                            >
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                        
                                        <v-btn
                                            small
                                            depressed
                                            color="error"
                                            @click="deletePermission(permissionItem.id, permissionGrItem.index, index)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Breadcrumb from "../../Shares/Breadcrumb.vue";
import { api } from "../../../../helpers/const.js";

export default {
    name: "Permission",
    // components: { Breadcrumb },
    data() {
        return {
            state: {
                'dialog': false,
                'loading': true,
                'permissions': [],
                'permission_groups': [],
                'form_datas': {
                    'name': '',
                    'code': '',
                    'permission_group_id': '',
                    'description': ''
                },
                'permission_id': '',
                'index_edit': '',
                'errors': {
                    'name': '',
                    'code': '',
                    'permission_group_id': '',
                    'description': ''
                }
            }
        }
    },
    methods: {
        deletePermission(id, index_group, index) {
            if (confirm('Bạn có chắc chắn muốn xóa quyền này?')) {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                    },
                    body: JSON.stringify({
                        id: id
                    })
                };
                fetch(api.permission_delete, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.success) {
                        const copy_permissions = [...this.state.permissions];
                        copy_permissions[index_group]['permissions'].splice(index, 1);
                        this.state = {
                            ...this.state,
                            permissions: copy_permissions
                        }
                        this.$swal({
                            icon: 'success',
                            title: result.message,
                            timer: 1000
                        });
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: result.message,
                            timer: 1000
                        });
                    }
                })
            }

            return false;
        },
        onSubmitRole() {
            if (this.state.permission_id != '') {
                const data_send = this.state.form_datas;
                data_send['permission_id'] = this.state.permission_id;
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                    },
                    body: JSON.stringify(data_send)
                };
                fetch(api.permission_update, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.success) {
                        const copy_permissions = [...this.state.permissions];
                        copy_permissions[this.state.index_edit] = result.data;
                        this.state = {
                            ...this.state,
                            permissions: copy_permissions,
                            dialog: false,
                            form_datas: {
                                'name': '',
                                'code': '',
                                'permission_group_id': '',
                                'description': ''
                            },
                            index_edit: '',
                            permission_id: ''
                        }
                        this.$swal({
                                icon: 'success',
                                title: result.message,
                                timer: 1000
                            });
                    } else {
                        if (result.type == 'validate') {
                            this.state = {
                                ...this.state,
                                errors: result.errors,
                            }
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: result.message,
                                timer: 1000
                            });
                        }
                    }
                });
            } else {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                    },
                    body: JSON.stringify(this.state.form_datas)
                };
                fetch(api.permission_create, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.success) {
                        const copy_permissions = [...this.state.permissions];
                        for (var i = 0; i < copy_permissions.length; i++) {
                            if (copy_permissions[i].id == this.state.form_datas.permission_group_id) {
                                var key = i;
                                break;
                            }
                        }
                        const new_pms = [...copy_permissions[key].permissions, result.data];
                        copy_permissions[key].permissions = new_pms;
                        this.state = {
                            ...this.state,
                            permissions: copy_permissions,
                            dialog: false,
                            form_datas: {
                                'name': '',
                                'code': '',
                                'permission_group_id': '',
                                'description': ''
                            }
                        }
                        this.$swal({
                                icon: 'success',
                                title: result.message,
                                timer: 1000
                            });
                    } else {
                        if (result.type == 'validate') {
                            this.state = {
                                ...this.state,
                                errors: result.errors,
                            }
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: result.message,
                                timer: 1000
                            });
                        }
                    }
                });
            }
        }
    },
    mounted() {
		const requestOptions = {
			method: "GET",
			headers: {
				'Authorization': 'Bearer ' + this.$cookie.get("is_login")
			}
		};
		fetch(api.permission_list, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			if (result.success) {
				this.state = {
                    ...this.state,
                    permissions: result.datas,
                    loading: false,
                    permission_groups: result.permission_groups,
                }
            }
		});
	}
}
</script>

<style scoped>

</style>