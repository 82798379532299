<template>
    <div>
        <!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
        <h3>
            Danh sách mẫu Email
        </h3>
        <br/>
        <v-row justify="left">
            <router-link :to="{name: 'add-email'}" class="a-none">
                <v-btn class="text-transform" color="primary">
                    <v-icon left>mdi-plus</v-icon>Thêm mới
                </v-btn>
            </router-link>
		</v-row>
        <br>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="2"
				sm="3"
				md="3"
			>
                <v-text-field
                dense
                label="Từ khóa tìm kiếm..."
                v-model="state.name_search"
                outlined
                ></v-text-field>
            </v-col>
            <v-col cols="2"
				sm="3"
				md="3">
                <v-autocomplete
                        dense
                        v-model="products"
                        :items="state.products"
                        outlined
                        multiple
                        item-text="name"
                        item-value="id"
                        label="Chọn sản phẩm"
                        chips
                        small-chips
                    ></v-autocomplete>
            </v-col>
            <v-col cols="2"
				sm="1"
				md="1">
                <v-btn
                    class="text-transform"
                    color="primary"
                    @click="search"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
            </v-col>
        </v-row>
        <hr>
        <v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    STT
                                </th>
                                <th>Tiêu đề</th>
                                <th>Tên mẫu Email</th>
                                <th class="text-left">
                                    Sản phẩm
                                </th>
                                <th class="text-left">
                                    Hành động
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="state.loading">
                                <td align="center" colspan="5">
                                    <v-progress-circular
                                    :size="40"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-for="(email, index) in state.list_emails" :key="index">
                                <td>
                                    {{index + 1}}
                                </td>
                                <td>
                                    {{email.title}}
                                </td>
                                <td>
                                    {{email.name}}
                                </td>
                                <td>
                                    {{email.product_name}}
                                </td>
                                <td>
                                    <router-link class="a-none" :to="{ name: 'edit-email', params: { id: email.id }}">
                                        <v-btn
                                            small
                                            depressed
                                            color="warning"
                                            style="margin-right: 7px"
                                            >
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                    </router-link>
                                    <v-btn
                                        small
                                        depressed
                                        color="error"
                                        @click="deleteEmail(email.id, index)"
                                        >
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
    
</template>

<script>
import Breadcrumb from "./../../Shares/Breadcrumb.vue";
import { api } from "./../../../../helpers/const.js";

// import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder.js';

export default {
    name: "ListEmail",
    // components: { Breadcrumb },
    data() {
        return {
            linkStyle: {
                "z-index": "2222223333 !important"
            },
            breadcrumbs: [
                {
                    text: "Dashboard",
                    disabled: false,
                    href: "/admin"
                },
                {
                    text: "Mẫu Email",
                    disabled: false,
                    href: "/admin/email"
                }
            ],
            products: [],
            state: {
                list_emails: [],
                loading: true,
                products: [],
                name_search: ''
            }
            
        }
    },
    methods: {
        search() {
            this.state = {
                ...this.state,
                list_emails: [],
                loading: true
            }
            this.get_email();
        },
        deleteEmail(id, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: id
                        })
                    };
                    fetch(api.delete_email, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            const copy = [...this.state.list_emails];
                            copy.splice(index, 1);
                            this.state = {
                                ...this.state,
                                list_emails: copy
                            }
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 1500
                            })
                        }
                    });
                }
            })
        },
        get_email() {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    products: this.products,
                    name_search: this.state.name_search
                })
            };
            fetch(api.email_list, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                this.state = {
                    ...this.state,
                    list_emails: result.datas,
                    loading: false,
                    products: result.products
                }
            });
        }
    },
    mounted() {
		this.get_email()
	}
}
</script>

<style scoped>
.v-dialog__content {
    z-index: 1234 !important;
}
</style>
