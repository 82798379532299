<template>
	<div>
		<header class="menu d-none d-sm-block">
			<v-container class="p-t-0 p-b-0">
				<router-link class="a-none" :to="{ name: 'dashboard'}">
					<v-btn class="button" text>
						<v-icon left>mdi-home</v-icon> Dashboard
					</v-btn>
				</router-link>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						text
						class="button"
						v-bind="attrs"
						v-on="on"
						>
						<v-icon left>mdi-cart</v-icon> Đơn hàng <v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'create-order'}" class="a-none">
									Tạo đơn
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'list-order'}" class="a-none">
									Duyệt đơn hàng
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'my-order'}" class="a-none">
									Theo dõi yêu cầu
								</router-link>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<router-link class="a-none" :to="{ name: 'list-product'}">
					<v-btn
						v-bind="attrs"
						v-on="on"
						text		
						class="button"				
						>
						<v-icon left>mdi-card-text</v-icon> Sản phẩm
					</v-btn>
				</router-link>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						v-bind="attrs"
						v-on="on"
						text
						class="button"
						>
						<v-icon left>mdi-certificate</v-icon> Chứng chỉ <v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'certificate-import'}" class="a-none">
									Import
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'certificate-export'}" class="a-none">
									Export
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'certificate-approved'}" class="a-none">
									Đã duyệt
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'certificate-wait-approve'}" class="a-none">
									Đợi duyệt
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'certificate-list'}" class="a-none">
									Danh sách dự thi
								</router-link>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						v-bind="attrs"
						v-on="on"
						class="button"
						text
						>
						<v-icon left>mdi-chart-bar</v-icon> Kinh doanh <v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'statistical-status-payment'}" class="a-none">
									Trạng thái thanh toán
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'statistical-owe'}" class="a-none">
									Nợ
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'statistical-detail-employee'}" class="a-none">
									Chi tiết nhân viên
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'statistical-all-employee'}" class="a-none">
									Tổng hợp nhân viên
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'statistical-vacc'}" class="a-none">
									Phải trả cho VACC
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'statistical-vace'}" class="a-none">
									Phải trả cho VACE
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'statistical-product'}" class="a-none">
									Theo sản phẩm
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<!-- <v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'statistical-vacc-vace'}" class="a-none">
									Theo thời gian
								</router-link>
							</v-list-item-title>
						</v-list-item> -->
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'statistical-kpi'}" class="a-none">
									Số liệu KPI
								</router-link>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						v-bind="attrs"
						v-on="on"
						text
						class="button"
						>
						<v-icon left>mdi-account</v-icon> Khách hàng <v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'customer-use-key-hardware'}" class="a-none">
									Sử dụng khóa cứng
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'customer-use-key-software'}" class="a-none">
									Sử dụng khóa mềm
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'customer-use-course'}" class="a-none">
									Tham gia khóa học
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'customer-use-certificate'}" class="a-none">
									Dự thi chứng chỉ
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'customer-use-key-test'}" class="a-none">
									Khách dùng thử
								</router-link>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						v-bind="attrs"
						v-on="on"
						text
						class="button"
						>
						<v-icon left>mdi-account</v-icon> CSKH <v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'customer-not-active'}" class="a-none">
									Khách chưa kích hoạt
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'customer-about-to-expire'}" class="a-none">
									Khách sắp hết hạn
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'customer-expired'}" class="a-none">
									Khách đã hết hạn
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="'#'" class="a-none">
									Phân loại khách hàng
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'customer-use-today'}" class="a-none">
									Sử dụng trong ngày
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'customer-region'}" class="a-none">
									Biểu đồ khu vực
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<!-- <v-list-item>
							<v-list-item-title>
								<router-link :to="'#'" class="a-none">
									Thống kê nhân viên
								</router-link>
							</v-list-item-title>
						</v-list-item> -->
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'customer-bonus-key'}" class="a-none">
									Key tặng kèm
								</router-link>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<!-- <v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						v-bind="attrs"
						v-on="on"
						text
						>
						<v-icon left>mdi-email-search</v-icon> Email
						</v-btn>
					</template>
					<v-list>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{ name: 'list-email'}" class="a-none">
									<v-icon small>mdi-arrow-top</v-icon> Danh sách
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link to="/" class="a-none">
									Cấu hình Email
								</router-link>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu> -->
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						v-bind="attrs"
						v-on="on"
						text
						class="button"
						>
						<v-icon left>mdi-cog</v-icon> Cài đặt <v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{ name: 'list-role'}" class="a-none">
									Quyền
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'list-permission'}" class="a-none">
									Quyền truy cập
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'setting-smtp'}" class="a-none">
									Cấu hình SMTP
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{ name: 'list-email'}" class="a-none">
									Email
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{ name: 'list-user'}" class="a-none">
									User
								</router-link>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						class="button"
						v-bind="attrs"
						v-on="on"
						text
						>
						<v-icon left>mdi-key</v-icon> Key <v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{ name: 'send-key'}" class="a-none">
									Yêu cầu gửi key
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'key-waiting-send'}" class="a-none">
									Chờ gửi đi
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{ name: 'key-sended-today'}" class="a-none">
									Gửi trong ngày
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'key-sended-email'}" class="a-none">
									Đã gửi qua Email
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<!-- <router-link :to="{name: 'key-actived'}" class="a-none"> -->
								<router-link :to="{name: 'customer-use-key-software'}" class="a-none">
									Đã kích hoạt
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'list-key'}" class="a-none">
									Kho key
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<router-link :to="{name: 'key-detail'}" class="a-none">
									Tổng quan
								</router-link>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<!-- <router-link :to="{name: 'list-key'}" class="a-none">
					<v-btn
						v-bind="attrs"
						v-on="on"
						text
						>
						<v-icon left>mdi-key</v-icon> Key
					</v-btn>
				</router-link> -->
				<!-- <router-link :to="{name: 'list-user'}" class="a-none">
					<v-btn
						class="button"
						v-bind="attrs"
						v-on="on"
						text
						>
						<v-icon left>mdi-account-multiple</v-icon> User
					</v-btn>
				</router-link> -->
				<v-btn
					v-bind="attrs"
					v-on="on"
					text
					@click="dialog = true"
					class="text-center button"
					>
					<v-icon style="font-size: 22px" center>mdi-magnify</v-icon>
				</v-btn>
				<v-dialog
					@click:outside="dialog = false"
					v-model="dialog"
					persistent
					max-width="600px"
					>
					<v-card>
						<v-card-title>
						<span class="text-h5">Tìm kiếm tại đây</span>
						</v-card-title>
						<v-card-text>
						<v-container>
							<v-row>
							<v-col
								cols="12"
								sm="12"
								md="12"
							>
								<form @submit.prevent="onSubmitSearch">
									<v-text-field
									label="Nhập từ khóa tìm kiếm*"
									autofocus
									required
									v-model="query"
									outlined
									></v-text-field>
								</form>
							</v-col>
							</v-row>
						</v-container>
						</v-card-text>
					</v-card>
				</v-dialog>
				
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
						v-bind="attrs"
						v-on="on"
						text
						class="button"
						>
						<v-icon left>mdi-account</v-icon> {{full_name}} <v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item>
							<v-list-item-title>
								<!-- <router-link :to="{name: 'create-order'}" class="a-none"> -->
									<v-icon left>mdi-account</v-icon> Thông tin
								<!-- </router-link> -->
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title class="cursor" @click="logout">
								<v-icon left>mdi-logout</v-icon> Đăng xuất
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				
			</v-container>
		</header>
		<div class="d-block d-sm-none">
			<v-navigation-drawer
				style="position: fixed"
				v-model="drawer"
				absolute
				temporary
				>
				<v-list
					nav
					dense
				>
					<v-list-item-group
					v-model="group"
					active-class="deep-purple--text text--accent-4"
					>
						<v-list-item>
							<v-list-item-icon>
								<v-icon>mdi-home</v-icon>
							</v-list-item-icon>
							<v-list-item-title>Dashboard</v-list-item-title>
						</v-list-item>

						<v-list-item>
							<v-list-item-icon>
							<v-icon>mdi-card-text</v-icon>
							</v-list-item-icon>
							<v-list-item-title>
								<router-link style="color: rgba(0, 0, 0, 0.87)" :to="{name: 'list-product'}" class="a-none">
									Sản phẩm
								</router-link>
							</v-list-item-title>
						</v-list-item>
						<v-list-group
						prepend-icon="mdi-cart"
						no-action
						>
							<template v-slot:activator>
								<v-list-item-content>
									<v-list-item-title>Đơn hàng</v-list-item-title>
								</v-list-item-content>
							</template>

							<v-list-item
							>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'create-order'}" class="a-none">
										Tạo đơn
									</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'list-order'}" class="a-none">
											Duyệt đơn hàng
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'my-order'}" class="a-none">
											Theo dõi yêu cầu
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-group>
						<v-list-group
						prepend-icon="mdi-certificate"
						no-action
						>
							<template v-slot:activator>
								<v-list-item-content>
									<v-list-item-title>Chứng chỉ</v-list-item-title>
								</v-list-item-content>
							</template>

							<v-list-item
							>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'certificate-import'}" class="a-none">
											Import
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'certificate-export'}" class="a-none">
											Export
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'certificate-approved'}" class="a-none">
											Đã duyệt
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'certificate-wait-approve'}" class="a-none">
											Đợi duyệt
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'certificate-list'}" class="a-none">
											Danh sách dự thi
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-group>
						<v-list-group
						prepend-icon="mdi-chart-bar"
						no-action
						>
							<template v-slot:activator>
								<v-list-item-content>
									<v-list-item-title>Kinh doanh</v-list-item-title>
								</v-list-item-content>
							</template>

							<v-list-item
							>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'statistical-status-payment'}" class="a-none">
											Trạng thái thanh toán
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'statistical-owe'}" class="a-none">
											Nợ
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'statistical-detail-employee'}" class="a-none">
											Chi tiết nhân viên
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'statistical-all-employee'}" class="a-none">
											Tổng hợp nhân viên
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'statistical-vacc'}" class="a-none">
											Phải trả cho VACC
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'statistical-vace'}" class="a-none">
											Phải trả cho VACE
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'statistical-product'}" class="a-none">
											Theo sản phẩm
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'statistical-kpi'}" class="a-none">
											Số liệu KPI
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-group>
						<v-list-group
						prepend-icon="mdi-account"
						no-action
						>
							<template v-slot:activator>
								<v-list-item-content>
									<v-list-item-title>Khách hàng</v-list-item-title>
								</v-list-item-content>
							</template>

							<v-list-item
							>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'customer-use-key-hardware'}" class="a-none">
											Sử dụng khóa cứng
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'customer-use-key-software'}" class="a-none">
											Sử dụng khóa mềm
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'customer-use-course'}" class="a-none">
											Tham gia khóa học
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'customer-use-certificate'}" class="a-none">
											Dự thi chứng chỉ
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'customer-use-key-test'}" class="a-none">
											Khách dùng thử
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-group>
						<v-list-group
						prepend-icon="mdi-account"
						no-action
						>
							<template v-slot:activator>
								<v-list-item-content>
									<v-list-item-title>CSKH</v-list-item-title>
								</v-list-item-content>
							</template>

							<v-list-item
							>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'customer-not-active'}" class="a-none">
											Khách chưa kích hoạt
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'customer-about-to-expire'}" class="a-none">
											Khách sắp hết hạn
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'customer-expired'}" class="a-none">
											Khách đã hết hạn
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="'#'" class="a-none">
											Phân loại khách hàng
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'customer-use-today'}" class="a-none">
											Sử dụng trong ngày
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'customer-region'}" class="a-none">
											Biểu đồ khu vực
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'customer-bonus-key'}" class="a-none">
											Key tặng kèm
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-group>
						<v-list-group
						prepend-icon="mdi-cog"
						no-action
						>
							<template v-slot:activator>
								<v-list-item-content>
									<v-list-item-title>Cài đặt</v-list-item-title>
								</v-list-item-content>
							</template>

							<v-list-item
							>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{ name: 'list-role'}" class="a-none">
											Quyền
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'list-permission'}" class="a-none">
											Quyền truy cập
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'setting-smtp'}" class="a-none">
											Cấu hình SMTP
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{ name: 'list-email'}" class="a-none">
											Email
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{ name: 'list-user'}" class="a-none">
											User
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-group>
						<v-list-group
						prepend-icon="mdi-key"
						no-action
						>
							<template v-slot:activator>
								<v-list-item-content>
									<v-list-item-title>Key</v-list-item-title>
								</v-list-item-content>
							</template>

							<v-list-item
							>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{ name: 'send-key'}" class="a-none">
											Yêu cầu gửi key
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'key-waiting-send'}" class="a-none">
											Chờ gửi đi
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{ name: 'key-sended-today'}" class="a-none">
											Gửi trong ngày
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'key-sended-email'}" class="a-none">
											Đã gửi qua Email
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'customer-use-key-software'}" class="a-none">
											Đã kích hoạt
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'list-key'}" class="a-none">
											Kho key
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<router-link :to="{name: 'key-detail'}" class="a-none">
											Tổng quan
										</router-link>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-group>
						<v-list-item>
							<v-list-item-icon>
							<v-icon>mdi-logout</v-icon>
							</v-list-item-icon>
							<v-list-item-title @click="logout">
								Đăng xuất
							</v-list-item-title>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-navigation-drawer>
			<v-app-bar
				color="deep-dark accent-4"
				style="background: #1e1e2d !important"
				dense
				dark
				>
				<v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
				<v-spacer></v-spacer>


				<v-btn icon @click="dialog = true">
					<v-icon>mdi-magnify</v-icon>
				</v-btn>

				<!-- <v-menu
					left
					bottom
				>
					<template v-slot:activator="{ on, attrs }">
					<v-btn
						icon
						v-bind="attrs"
						v-on="on"
					>
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
					</template>

					<v-list>
					<v-list-item
						v-for="n in 5"
						:key="n"
						@click="() => {}"
					>
						<v-list-item-title>Option {{ n }}</v-list-item-title>
					</v-list-item>
					</v-list>
				</v-menu> -->
			</v-app-bar>
		</div>
		<main>
			<v-container class="p-t-0 p-b-0">
				<router-view></router-view>
			</v-container>
		</main>
	</div>
	
</template>

<script>
export default {
	data: () => ({
      	items: [
			{ title: 'Tạo đơn' },
			{ title: 'Duyệt đơn hàng' },
		],
		drawer: false,
		full_name: '',
		query: '',
		dialog: false,
    }),
	methods: {
		logout() {
			this.$cookie.delete('is_login');
			this.$router.push('/');
		},
		search() {
			this.$router.push('/admin/search?query=' + this.query);
		},
		onSubmitSearch() {
			this.dialog = false;
			this.$router.push('/admin/search?query=' + this.query);
		}
	},
	mounted() {
		this.full_name = this.$cookie.get('full_name');
	}
};
</script>

<style lang="scss">
.menu .button{
	padding: 0px 9px !important;
	font-size: 13px;
}
header {
	background: #1e1e2d;
	color: #a2a3b7;
    height: 70px;
    line-height: 70px;
    position: sticky;
    top: 0px;
	z-index: 4;
	box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
	& button {
		color: #a2a3b7 !important;
	}
	& a.router-link-exact-active {
		& button {
			background: #1976d2;
			& span {
				color: #fff !important;
			}
		}
	}
}
.t {
	padding: 0px;
	background: #fff;
	height: 100%;
	list-style-type: none;
	margin-top: 0;
	overflow: hidden;
	overflow-x: hidden;
	overflow-y: visible;
	right: 0;
	top: 0;
}
.sidebar-menu {
	position: fixed;
	height: 100%;
	top: 0px;
	left: 0px;
}
</style>
