<template>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
</template>

<script>
export default {
  	name: 'Breadcrumb',
    props: ['data'],
    data() {
        return {
            breadcrumbs: this.data
        }
    }
}
</script>

<style scoped>

</style>
