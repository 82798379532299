<template>
    <v-row justify="left">
        <v-dialog @click:outside="dialog = false" v-model="dialog" persistent max-width="800px">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="text-transform" color="primary" dark v-bind="attrs" v-on="on">
                    <v-icon left>mdi-plus</v-icon>Quản lý file
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="text-h5">Quản lý File, Image</span>
                </v-card-title>
                <form @submit.prevent="uploadFile">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Tìm kiếm tên"
                                    v-model="state.name_file_search"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                                <v-btn
                                depressed
                                color="success"
                                @click="search"
                                >
                                Tìm kiếm
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-file-input
                                    label="File input"
                                    v-model="state.file"
                                    outlined
                                    dense
                                ></v-file-input>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                                <v-btn
                                depressed
                                color="primary"
                                type="submit"
                                >
                                Thêm mới
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </form>
                <v-card-title>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <p class="text-h5 m-b-0">Danh sách</p>
                        </v-col>
                        <v-col v-for="(file, index) in state.list_files" :key="index" cols="12" sm="4" md="4">
                            <p class="text-center">
                                <img style="width: 100%; height: 100px; object-fit: contain;" :src="file.name" alt="">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                @click="copy(file.name)"
                                                depressed
                                                small
                                                v-bind="attrs"
                                                v-on="on"
                                                class="text-transform"
                                                color="default"
                                                style="margin-right: 10px"
                                                >
                                                <v-icon left>mdi-link-plus</v-icon> Copy
                                            </v-btn>
                                    </template>
                                    <span>Click để copy</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                @click="removeFile(file.id, index)"
                                                depressed
                                                small
                                                v-bind="attrs"
                                                v-on="on"
                                                class="text-transform"
                                                color="error"
                                                >
                                                <v-icon left>mdi-delete</v-icon> Xóa
                                            </v-btn>
                                    </template>
                                    <span>Click để xóa</span>
                                </v-tooltip>
                            </p>
                            
                        </v-col>
                    </v-row>
                </v-card-title>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { api } from "./../../../helpers/const.js";

export default {
    name: 'FileManager',
    data() {
        return {
            dialog: false,
            state: {
                file: {},
                list_files: [],
                name_file_search: ''
            },
        }
    },
    methods: {
        copy(link) {
            navigator.clipboard.writeText(link);
        },
        search() {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    name: this.state.name_file_search
                })
            };
            fetch(api.search_file, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        icon: 'error',
                        title: result.message,
                        timer: 1500
                    });
                } else {
                    this.state = {
                        ...this.state,
                        list_files: result.datas
                    }
                }
            })
        },
        removeFile(id, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: id
                        })
                    };
                    fetch(api.remove_file, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            const copy = [...this.state.list_files];
                            copy.splice(index, 1);
                            this.state = {
                                ...this.state,
                                list_files: copy
                            }
                            this.$swal({
                                icon: 'success',
                                title: result.message,
                                timer: 1500
                            });
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: result.message,
                                timer: 1500
                            });
                        }
                    })
                }
            })
        },
        uploadFile() {
            const formData = new FormData();
            formData.append('file', this.state.file);
            const requestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: formData
            };
            fetch(api.upload_file, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.$swal({
                        icon: 'success',
                        title: result.message,
                        timer: 1500
                    });
                    this.state = {
                        ...this.state,
                        list_files: [result.data, ...this.state.list_files]
                    }
                } else {
                    this.$swal({
                        icon: 'error',
                        title: result.message,
                        timer: 1500
                    });
                }
            })
        }
    },
    mounted() {
		const requestOptions = {
			method: "GET",
			headers: {
				'Authorization': 'Bearer ' + this.$cookie.get("is_login")
			}
		};
		fetch(api.list_file, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			if (result.success == true) {
				this.state = {
                    ...this.state,
                    list_files: result.datas
                }
			}			
		});
	}
}
</script>