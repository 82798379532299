<template>
<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table class="list-order">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                STT
                            </th>
                            <th>Đơn hàng</th>
                            <th class="text-left">
                                Khách hàng
                            </th>
                            <th class="text-left">
                                SL x Đơn giá
                            </th>
                            <th class="text-left">
                                Giảm giá
                            </th>
                            <th class="text-left">
                                GXD
                            </th>
                            <th class="text-left">
                                Tiền đi
                            </th>
                            <th>Ghi chú</th>
                            <th>
                                Trạng thái
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="loading">
                            <td class="text-center" colspan="12">
                                <v-progress-circular
                                :size="50"
                                color="primary"
                                indeterminate
                                ></v-progress-circular>
                            </td>
                        </tr>
                        <tr v-if="message != null">
                            <td class="text-center" colspan="12">
                                <p>
                                    {{message}}
                                </p>
                                <v-btn
                                    :to="{name: 'list-order'}"
                                    large
                                    depressed
                                    color="error"
                                    style="margin-right: 7px"
                                    >
                                    <v-icon small left>mdi-reload</v-icon> Tải lại
                                </v-btn>
                            </td>
                        </tr>
                        <tr v-for="(item, index) in list_orders"
                            :key="index"
                            >
                            <td>{{ index +  1 }}</td>
                            <td>
                                <p>
                                    Sản phẩm: <b>{{item.product.name}}</b>
                                </p>
                                <p>
                                    Tạo đơn: <b>{{item.created_at}}</b>
                                </p>
                                <p>
                                    Duyệt đơn: <b>{{item.date_accept}}</b>
                                </p>
                                <p>
                                    Người tạo: <b>{{item.saler}}</b>
                                </p>
                                <p>
                                    Người duyệt: <b>{{item.user_accept}}</b>
                                </p>
                                <p>
                                    Mã đơn: <b>{{ item.code }}</b>
                                </p>
                                <p>
                                    Ngày thanh toán: <b>{{ item.product.vacc ? item.date_payment_vacc : item.date_payment_vace }}</b>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <v-icon small left>mdi-email</v-icon> {{item.email}}
                                </p>
                                <p>
                                    <v-icon small left>mdi-account</v-icon>{{item.full_name}}
                                </p>
                                <p>
                                    <v-icon small left>mdi-phone</v-icon> {{item.phone}}
                                </p>
                            </td>
                            <td>
                                {{item.amount}}  x <b>{{Number(item.price).toLocaleString()}}</b>
                            </td>
                            <td>
                                {{Number(item.discount).toLocaleString()}}
                            </td>
                            <td>
                                <b>
                                    {{Number(item.price * item.amount - item.discount - item.payment * item.amount).toLocaleString()}}
                                </b>
                            </td>
                            <td>
                                <b>
                                    {{Number(item.payment * item.amount).toLocaleString()}}
                                </b>
                            </td>
                            <td>
                                <v-textarea
                                outlined
                                name="input-7-4"
                                label="Ghi chú"
                                v-model="item.note"
                                @change="onChangeNoteOrder(item)"
                                ></v-textarea>
                            </td>
                            <td>
                                <v-chip
                                    @click="changeStatusVaccVace(item, index, 0)"
                                    class="cursor"
                                    style="margin-right: 10px" 
                                    :color="!item['status_' + type] ? 'primary' : 'default'">
                                    <!-- {{item.status_vacc_vace.name}} -->
                                    Chưa trả
                                </v-chip>
                                <v-chip
                                    @click="changeStatusVaccVace(item, index, 1)"
                                    class="cursor"
                                    :color="item['status_' + type] ? 'primary' : 'default'">
                                    Đã trả
                                </v-chip>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
			</v-col>
		</v-row>
</template>

<script>
import { api } from "./../../../../helpers/const.js";

export default {
    name: 'DataOrderVaccVace',
    props: {
        list_orders: [],
        loading: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: ""
        },
        message: null
    },
    data() {
		return {
            state: {
                orders: []
            }
        };
    },
    methods: {
        onChangeNoteOrder(data) {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    order_id: data.id,
                    data_update: {
                        note: data.note
                    }
                })
            };
            fetch(api.update_order_global, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        icon: 'error',
                        title: result.message,
                        timer: 1000
                    });
                }
            });
        },
        changeStatusVaccVace(order, index, status) {
            const old_order = [...this.state.orders];
            old_order[index]['status_' + this.type] = status;
            this.state = {
                ...this.state,
                orders: old_order
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    order_id: order.id,
                    status: status,
                    type: this.type
                })
            };
            fetch(api.change_status_vacc_vace, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        icon: 'error',
                        title: result.message,
                        timer: 1000
                    });
                    old_order[index]['status_' + this.type] = !status;
                    this.state = {
                        ...this.state,
                        orders: old_order
                    }
                }
            });
        },
    },
    watch: {
        list_orders: function(newVal) {
            this.state = {
                ...this.state,
                orders: [...newVal]
            }
        }
        // properties: function(newVal) {
        //     this.$emit('propertiesUpdated', newVal);
        // },
    }
} 
</script>