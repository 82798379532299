<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
		<h3>
			Tất cả yêu cầu của bạn
		</h3>
				<br/>
		<v-row justify="left">
            <v-btn :to="{name: 'create-order'}" class="text-transform" color="primary" dark v-bind="attrs" v-on="on">
                <v-icon left>mdi-plus</v-icon> Thêm mới
            </v-btn>
		</v-row>
		<br>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
                style="padding-top: 0px; padding-bottom: 0px"
			>
                <h3 >Lọc đơn hàng</h3>
            </v-col>
            <v-col
				cols="6"
				sm="2"
				md="2"
			>
                <v-text-field
                dense
                v-model="form_search.key_search"
                label="Từ khóa tìm kiếm..."
                outlined
                ></v-text-field>
            </v-col>
            
            <v-col cols="6"
				sm="2"
				md="2">
                <v-select
                    outlined
                    dense
                    :items="status_init"
                    label="Trạng thái"
                    v-model="form_search.status_selected"
                    item-text="name"
                    item-value="value"
                    return-object
                ></v-select>
            </v-col>
            <v-col cols="12"
				sm="2"
				md="2">
                <v-select
                    outlined
                    dense
                    :items="states.product_type"
                    label="Loại sản phẩm"
                    v-model="form_search.product_type"
                    item-text="name"
                    item-value="value"
                ></v-select>
            </v-col>
            <v-col cols="12"
				sm="3"
				md="3">
                <v-btn
                    class="text-transform"
                    color="primary"
                    @click="submitForm"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="error"
                    style="margin-left: 10px"
                    @click="reloadPage"
                >
                    <v-icon left>mdi-reload</v-icon> Tải lại
                </v-btn>
            </v-col>
         </v-row>
         <hr>
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table class="list-order">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                STT
                            </th>
                            <th>Đơn hàng</th>
                            <th class="text-left">
                                Khách hàng
                            </th>
                            <th class="text-left">
                                Sản phẩm
                            </th>
                            <th class="text-left">
                                Đơn giá
                            </th>
                            <!-- <th class="text-left">
                                Giảm
                            </th>
                            <th class="text-left">
                                Nợ
                            </th>
                            <th class="text-left">
                                Nhận
                            </th> -->
                            <th>Ghi chú</th>
                            <th>
                                Trạng thái
                            </th>
                            <th class="text-left">
                                Hành động
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="states.loading">
                            <td class="text-center" colspan="12">
                                <v-progress-circular
                                :size="50"
                                color="primary"
                                indeterminate
                                ></v-progress-circular>
                            </td>
                        </tr>
                        <tr v-if="states.message != null">
                            <td class="text-center" colspan="12">
                                <p>
                                    {{states.message}}
                                </p>
                                <v-btn
                                    :to="{name: 'list-order'}"
                                    large
                                    depressed
                                    color="error"
                                    style="margin-right: 7px"
                                    >
                                    <v-icon small left>mdi-reload</v-icon> Tải lại
                                </v-btn>
                            </td>
                        </tr>
                        <tr v-for="(item, index) in states.orders"
                            :key="index"
                            >
                            <td>{{ index +  1 }} {{states.message}}</td>
                            <td>
                                <p>
                                    Tạo đơn: <b>{{item.created_at}}</b>
                                </p>
                                <p>
                                    Duyệt đơn: <b>{{item.date_accept}}</b>
                                </p>
                                <p>
                                    Người tạo: <b>{{item.saler}}</b>
                                </p>
                                <p>
                                    Người duyệt: <b>{{item.user_accept}}</b>
                                </p>
                                <p>
                                    Mã đơn: <b>{{ item.code }}</b>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <v-icon small left>mdi-email</v-icon> {{item.email}}
                                </p>
                                <p>
                                    <v-icon small left>mdi-account</v-icon>{{item.full_name}}
                                </p>
                                <p>
                                    <v-icon small left>mdi-phone</v-icon> {{item.phone}}
                                </p>
                            </td>
                            <td>
                                <p>
                                    {{item.product.name}}
                                </p>
                                <p v-if="item.product.type_num == 0 || item.product.type_num == 1">
                                    Loại key:
                                    <v-chip
                                    class="ma-2"
                                    :color="item.key_type == 0 ? 'red' : 'success'"
                                    text-color="white"
                                    >
                                    {{item.key_type == 0 ? 'Thử nghiệm - ' : 'Thương mại - '}}
                                    {{item.use_day_number + ' ngày'}}
                                    </v-chip>
                                </p>
                                <p v-if="item.bonus_product != ''">
                                    <b>Tặng key: </b> {{item.bonus_product}}
                                </p>
                                <p v-if="item.key_sended != ''">
                                    <b>Key gửi:</b> <span @click="copyText(item.key_sended)" title="Click để copy" class="cursor">{{item.key_sended}}</span>
                                </p>
                                <p>
                                    <span v-bind:class="'label label-' + item.product.color">
                                        {{item.product.type}}
                                    </span>
                                </p>
                            </td>
                            <td>
                                <p>
                                    {{item.amount}}  x <b>{{Number(item.price).toLocaleString()}}</b>
                                </p>
                                <p>
                                    Giảm giá: <b>{{Number(item.discount).toLocaleString()}}</b>
                                </p>
                                <p>
                                    Nợ: <b>{{Number(item.owe).toLocaleString()}}</b>
                                </p>
                                <p>
                                    Đã nhận: <b>{{Number(item.price * item.amount - item.discount - item.owe).toLocaleString()}}</b>
                                </p>
                            </td>
                            <!-- <td>
                                {{item.amount}}  x <b>{{Number(item.price).toLocaleString()}}</b>
                            </td>
                            <td>
                                {{Number(item.discount).toLocaleString()}}
                            </td>
                            <td>
                                {{Number(item.owe).toLocaleString()}}
                            </td>
                            <td>
                                <b>
                                    {{Number(item.price * item.amount - item.discount - item.owe).toLocaleString()}}
                                </b>
                            </td> -->
                            <td>{{item.note}}</td>
                            <td>
                                <!-- <span v-bind:class="'label label-' + item.status.color">
                                    {{item.status.name}}
                                </span> -->
                                <v-chip
                                    class="ma-2"
                                    :color="item.status.color"
                                    text-color="white"
                                    >
                                    {{item.status.name}}
                                </v-chip>
                            </td>
                            <td style="width: 150px">
                                <p>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                :to="{name: 'edit-order', params: {id: item.id}}"
                                                small
                                                depressed
                                                color="warning"
                                                style="margin-right: 7px"
                                                >
                                                <v-icon small>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Sửa đơn hàng</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                @click="printBill(item)"
                                                depressed
                                                color="secondary"
                                                style="margin-right: 7px"
                                                >
                                                <v-icon small>mdi-printer</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>In hóa đơn</span>
                                    </v-tooltip>
                                </p>  
                                <p>
                                    <v-btn
                                        small
                                        depressed
                                        color="error"
                                        @click="deleteOrder(item.id, index)"
                                        >
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
			</v-col>
		</v-row>
        <div class="text-center" v-if="states.total_page > 1">
            <v-container>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            @input="nextPage"
                            v-model="page_active"
                            class="my-4"
                            :length="states.total_page"
                            :total-visible="7"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
	</div>
</template>

<script>
import { api } from "./../../../../../helpers/const.js";
import {printBill} from "./../../../../../helpers/function";

export default {
	name: "MyOrder",
	data() {
		return {
            loading_accept: false,
			loading: true,
			breadcrumbs: [
				{
					text: "Dashboard",
					disabled: false,
					href: "/admin"
				},
				{
					text: "Tạo đơn",
					disabled: false,
					href: "/admin/order/create"
				}
			],
            states: {
                orders: [],
                users: [],
                products: [],
                product_type: [],
                loading: true,
                message: null,
                total_page: 1,
                page_active: 1
            },
            status_init: [
                {name: 'Đã duyệt', value: 1},
                {name: 'Chưa duyệt', value: 0},
                {name: 'Tất cả', value: -1}
            ],
            form_search: {
                key_search: '',
                status_selected: {name: 'Tất cả', value: -1},
                products: [],
                users: [],
                product_type: ''
            }
		};
	},
	methods: {
        printBill(data) {
            printBill(data);
        },
        reloadPage() {
            window.location.reload()
        },
        nextPage(page) {
            this.states = {
                ...this.states,
                orders: [],
                page_active: page,
                loading: true
            }
            this.callApi();
        },
        copyText(text) {
            navigator.clipboard.writeText(text);
        },
        acceptOrder(item, index) {
            const copy = [...this.states.orders];
            copy[index].loading = true;
            this.states = {
                ...this.states,
                orders: copy
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    id: item.id
                })
            };
            fetch(api.accept_order, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    copy.splice(index, 1);
                    this.states = {
                        ...this.states,
                        orders: copy
                    }
                    this.$swal({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                    // fetch(api.run_queue);
                } else {
                    copy[index].loading = false;
                    this.states = {
                        ...this.states,
                        orders: copy
                    }
                    this.$swal({
                        title: result.message,
                        icon: 'error',
                        timer: 2000
                    })
                }                
            });
        },
        deleteOrder(id, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: id
                        })
                    };
                    fetch(api.delete_order, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            const copy = [...this.states.orders];
                            copy.splice(index, 1);
                            this.states = {
                                ...this.states,
                                orders: copy
                            }
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    });
                }
            })
        },
		submitForm() {
            this.states = {
                ...this.states,
                orders: [],
                page_active: 1,
                total_page: 1,
                loading: true
            }
            this.callApi();
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    page_active: this.states.page_active,
                    form_search: this.form_search
                })
            };
            fetch(api.my_order, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        orders: result.datas,
                        users: result.users,
                        products: result.products,
                        product_type: result.product_type,
                        loading: false,
                        total_page: result.total_page
                    }
                } else {
                    this.states = {
                        message: result.message,
                        loading: false
                    }
                }
            });
        }
	},
	watch: {
		
	},
	mounted() {
		this.callApi();
	}
};
</script>

<style scoped>
table tr td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    white-space: nowrap;
}
</style>
