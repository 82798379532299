<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
// import Login from './components/Logins/Index.vue';
export default {
  name: 'App',

  // components: {
  //   Login
  // },

  data: () => ({
    //
  }),
};
</script>

<style>
table tr td {
  white-space: unset !important;
}
.bg-white {
  background: #fff;
}
#app {
  background: #EEF0F8;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.row {
  margin: 0px !important;
}
.p-0 {
  padding: 0px !important;
}
.p-t-0 {
  padding-top: 0px !important;
}
.p-b-0 {
  padding-bottom: 0px !important;
}
.a-none {
  text-decoration: none !important;
}
.text-transform {
  text-transform: inherit !important;
}
.label {
  padding: 0.5rem 0.75rem;
  border-radius: 0.42rem;
  text-align: center;
}
.label-primary{
  background: #3699FF;
  color: #fff;
}
.label-info {
  background: #8950FC;
  color: #fff;
}
.label-warning {
  background: #fb8c00;
  color: #fff;
}
.label-danger {
  color: #fff;
  background-color: #F64E60;
}
.label-success {
  color: #fff;
  background-color: #1BC5BD;
}
@media (min-width: 1264px) {
  .container {
      max-width: 1500px !important;
  }
}
</style>
<style lang="scss">
.menu {
  & button {
    box-shadow: none !important;
    text-transform: inherit;
  }
}
.cursor {
  cursor: pointer !important;
}
</style>
