<template>
    <div class="container" style="border: 7px double #000;">
        <v-row>
            <v-col
				cols="6"
				sm="6"
				md="6"
			>
                <h3 class="text-left h3">
                    CÔNG TY CỔ PHẦN GIÁ XÂY DỰNG 
                </h3>
                <p>
                    Địa chỉ: Số 124A Nguyễn Ngọc Nại, Khương Mai, Thanh Xuân, Hà Nội
                </p>
                <p>
                    Website khóa học: gxd.edu.vn
                </p>
                <p>
                    Website phần mềm: gxd.vn
                </p>
                <p>
                    Email: daotao@giaxaydung.com; phanmem@giaxaydung.com
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col
				cols="12"
				sm="12"
				md="12"
			>
            <center>
                <h1 class="t-center">PHIẾU THU</h1>
                <p>
                    Ngày {{new Date().getDate()}} tháng {{new Date().getMonth() + 1}} năm {{new Date().getFullYear()}}
                </p>
            </center>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <p>
                    Khách hàng: {{state.order.address}}
                </p>
                <p>
                    SĐT: {{state.order.phone}}
                </p>
                <p>
                    Email: {{state.order.email}}
                </p>
                <p>
                    Công ty: {{state.order.company}}
                </p>
                <p>
                    MST: {{state.order.tax_code}}
                </p>
                <p>
                    Địa chỉ: {{state.order.address}}
                </p>
            </v-col>
        </v-row>
        <v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table class="list-order">
                    <template v-slot:default>
                        <thead>
                            <tr align="center">
                                <th class="text-left">
                                    STT
                                </th>
                                <th>Sản phẩm</th>
                                <th class="text-left">
                                    Đơn giá
                                </th>
                                <th class="text-left">
                                    Số lượng
                                </th>
                                <th class="text-left">
                                    Thành tiền
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1
                                </td>
                                <td>
                                    {{state.order.product.name}}
                                </td>
                                <td>
                                    {{Number(state.order.product.price).toLocaleString()}}
                                </td>
                                <td>
                                    {{state.order.product.amount}}
                                </td>
                                <td align="right">
                                    <b>
                                        {{Number(state.order.product.price * state.order.product.amount).toLocaleString()}}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td align="right" colspan="4">
                                    Giảm giá
                                </td>
                                <td align="right">
                                    <b>
                                        {{Number(state.order.product.discount).toLocaleString()}}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td align="right" colspan="4">
                                    Tổng cộng
                                </td>
                                <td align="right">
                                    <b>
                                        {{Number(state.order.product.price * state.order.product.amount - state.order.product.discount).toLocaleString()}}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td align="right" colspan="4">
                                    Đã thanh toán
                                </td>
                                <td align="right">
                                    <b v-if="state.order.status_owe">
                                        {{Number(state.order.product.price * state.order.product.amount - state.order.product.discount - state.order.product.owe).toLocaleString()}}
                                    </b>
                                    <b v-else>
                                        {{Number(state.order.product.price * state.order.product.amount - state.order.product.discount).toLocaleString()}}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5">
                                    <b>Ghi chú:</b> {{state.order.note}}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <br>
        <v-row>
            <v-col
				cols="6"
				sm="6"
				md="6"
			>
                <center>
                    <h4 class="t-center">Khách hàng</h4>
                    <br><br><br>
                    <h4>
                        {{state.order.full_name}}
                    </h4>
                </center>
            </v-col>
            <v-col
				cols="6"
				sm="6"
				md="6"
			>
                <center>
                    <h4 class="t-center">Người lập phiếu</h4>
                    <br><br><br>
                    <h4>
                        {{state.order.user.name}}
                    </h4>
                </center>
            </v-col>
        </v-row>
        <br>
        <v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table class="list-order">
                    <template v-slot:default>
                        <thead>
                            <tr align="center">
                                <th class="text-left">
                                    Liên tục mở các lớp	
                                </th>
                                <th>Cung cấp phần mềm GXD</th>
                                <th class="text-left">
                                    Chứng chỉ nghiệp vụ xây dựng
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    -	Đánh giá hiệu quả dự án và lập tổng mức đầu tư <br>
                                    -	Kỹ Sư  Quantity Surveyor (QS) <br>
                                    -	Đọc bản vẽ và đo bóc khối lượng <br>
                                    -	Lập dự toán công trình <br>
                                    -	Lập hồ sơ dự thầu, lập giá thầu <br>
                                    -	Lập hồ sơ quản lý chất lượng <br>
                                    -	Lập hồ sơ thanh quyết toán <br>
                                    -	Nghiệm vụ đấu thầu <br>
                                </td>
                                <td>
                                    -	Phần mềm Lập dự án GXD <br>
                                    -	Phần mềm Quản lý dự án GXD <br>
                                    -	Phần mềm Dự toán GXD <br>
                                    -	Phần mềm Dự thầu/Đầu thầu GXD <br>
                                    -	Phần mềm Quản lý chất lượng GXD <br>
                                    -	Phần mềm Thanh Quyết toán GXD <br>
                                    -	Phầm mềm nghiệm thu - thanh toán GXD <br>
                                </td>
                                <td>
                                    -	Tư vấn giám sát <br>
                                    -	Thiết kế xây dựng <br>
                                    -	Định giá xây dựng <br>
                                    -	Quản lý dự án <br>
                                    -	Khảo sát xây dựng <br>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { api } from "./../../../../../helpers/const.js";
export default {
	name: "BillOrder",
	data() {
		return {
            state: {
                order: {},
                loading_page: false,
            }
		};
	},
	mounted() {
		const requestOptions = {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.$cookie.get("is_login")
			},
			body: JSON.stringify({
				id: this.$route.params.id
			})
		};
		fetch(api.get_data_order, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			if (result.success) {
				this.state = {
					...this.state,
					order: result.data_edit,
					loading_page: false
				}
			} 			
		});
	}
};
</script>

<style scoped>
.container {
    width: 900px;
    margin: auto;
}
body {
    font-family: DejaVu Sans;
    }
.row {
    margin: 0px !important;
}
table tr td,table tr th {
    border: 1px solid #ccc;
    padding: 10px;
}
</style>

