<template>
	<div>
        <br>
		<h3>
            Dashboard
		</h3>
		<br/>
        <v-dialog @click:outside="state.dialog_mail=false" v-model="state.dialog_mail" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Chọn Email</span>
                </v-card-title>
                <form @submit.prevent="onSubmitRemind">
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-select
                                        :items="category_emails"
                                        @change="onChangeCategoryEmail"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        dense
                                        label="Loại Email *"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-autocomplete
                                        v-model="state.content_email.email_id"
                                        autocomplete="new-email"
                                        required
                                        :items="state.list_emails"
                                        :search-input.sync="search"
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        dense
                                        label="Chọn Email *"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="state.dialog_mail=false"
                        >
                            Đóng
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            :loading="loading_resend"
                            type="submit"
                        >
                            Xác nhận
                        </v-btn>
                    </v-card-actions>
                </form>
            </v-card>
        </v-dialog>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
			>
                <template>
                    <v-tabs v-model="tab">
                        <v-tab :key="'tab-0'">
                            Đơn hàng từ GXD
                        </v-tab>
                        <v-tab :key="'tab-1'">
                            Sắp hết hạn
                        </v-tab>
                        <v-tab :key="'tab-2'">
                            Đã hết hạn
                        </v-tab>
                        <v-tab :key="'tab-3'">
                            Kích hoạt trong ngày
                        </v-tab>
                        <v-tab :key="'tab-4'">
                            Đơn hàng GXD đã chăm sóc
                        </v-tab>
                    </v-tabs>
                </template>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        :key="'tab-1'"
                    >
                        <v-card flat>
                            <v-card-text style="padding-left: 0px; padding-right: 0px">
                                <OrderFromGxd :tab="tab" :is_care="false"/>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item
                        :key="'tab-1'"
                    >
                        <v-card flat>
                            <v-card-text>
                                <v-row class="bg-white">
                                    <v-col
                                        cols="2"
                                        sm="3"
                                        md="3"
                                    >
                                        <v-text-field
                                        dense
                                        label="Số ngày sắp hết hạn"
                                        v-model="state.day_number"
                                        outlined
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="2"
                                        sm="1"
                                        md="1">
                                        <v-btn
                                            class="text-transform"
                                            color="primary"
                                            @click="search"
                                        >
                                            <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <hr>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Khách hàng</th>
                                                <th>Sản phẩm</th>
                                                <th>Chăm sóc</th>
                                                <th>Ghi chú</th>
                                                <th>Ngày hết hạn</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="state.loading">
                                                <td class="text-center" colspan="6">
                                                    <v-progress-circular
                                                    :size="50"
                                                    color="primary"
                                                    indeterminate
                                                    ></v-progress-circular>
                                                </td>
                                            </tr>
                                            <template v-else>
                                                <tr v-if="state.message_tab_1 != ''">
                                                    <td colspan="6" align="center">
                                                        <p class="m-0">
                                                            {{state.message_tab_1}}
                                                        </p>
                                                        <p>
                                                            <v-btn
                                                            elevation="2"
                                                            color="primary"
                                                            class="text-transform"
                                                            @click="refresh"
                                                            >
                                                                Tải lại
                                                            </v-btn>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr v-else-if="state.key_about_expired.length == 0">
                                                    <td colspan="6" align="center">
                                                        Dữ liệu trống
                                                    </td>
                                                </tr>
                                                <template v-else>
                                                    <tr v-for="(dataItem, index) in state.key_about_expired" :key="index">
                                                        <td>
                                                            {{index + 1}}
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <b>Tên: </b> {{dataItem.customer.name}}
                                                            </p>
                                                            <p>
                                                                <b>Email: </b> {{dataItem.customer.email}}
                                                            </p>
                                                            <p>
                                                                <b>SĐT: </b> {{dataItem.customer.phone}}
                                                            </p>
                                                            <p>
                                                                <b>Địa chỉ: </b> {{dataItem.customer.address}}
                                                            </p>
                                                            <p>
                                                                <b>Key: </b> {{dataItem.license}}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <!-- Nhân viên: <b>{{dataItem.saler.name}}</b> -->
                                                                <v-select
                                                                    dense
                                                                    v-model="dataItem.saler.id"
                                                                    :items="state.users"
                                                                    @change="onChangeSaler(dataItem)"
                                                                    item-text="name"
                                                                    item-value="id"
                                                                    label="Nhân viên"
                                                                    hide-details
                                                                    outlined
                                                                ></v-select>
                                                            </p>
                                                            <p>
                                                                {{dataItem.product}}
                                                            </p>
                                                            <v-chip :color="dataItem.type_key.color">
                                                                {{dataItem.type_key.name}}
                                                            </v-chip>
                                                        </td>
                                                        <td>
                                                            <v-switch
                                                            v-model="dataItem.status_remind"
                                                            @change="onChangeKeyItem(dataItem)"
                                                            ></v-switch>
                                                            <!-- {{dataItem.content_remind}} -->
                                                        </td>
                                                        <td>
                                                            <v-textarea
                                                            outlined
                                                            name="input-7-4"
                                                            label="Ghi chú"
                                                            v-model="dataItem.note"
                                                            @change="onChangeKeyItem(dataItem)"
                                                            ></v-textarea>
                                                        </td>
                                                        <td>
                                                            {{dataItem.expire_date}}
                                                        </td>
                                                        <td> 
                                                            <p>
                                                                <v-btn
                                                                    :to="{name: 'edit-key', params: {id: dataItem.id}}"
                                                                    small
                                                                    depressed
                                                                    color="warning"
                                                                    style="margin-right: 7px"
                                                                    >
                                                                    <v-icon small>mdi-pencil</v-icon>
                                                                </v-btn>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                        small
                                                                        color="success"
                                                                        depressed
                                                                        v-bind="attrs"
                                                                        style="margin-right: 7px"
                                                                        v-on="on"
                                                                        @click="openDialog(dataItem, index, 'key_about_expired')"
                                                                        >
                                                                        <v-icon small>mdi-email</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Gửi mail nhắc nhở</span>
                                                                </v-tooltip>
                                                                <!-- <v-btn
                                                                    small
                                                                    depressed
                                                                    color="success"
                                                                    style="margin-right: 7px"
                                                                    >
                                                                    <v-icon small>mdi-email</v-icon>
                                                                </v-btn> -->
                                                                <v-btn
                                                                    small
                                                                    depressed
                                                                    color="error"
                                                                    @click="deleteKey(dataItem, index)"
                                                                    >
                                                                    <v-icon small>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <div class="text-center" v-if="state.total_page_key_about_expired > 1">
                                    <v-container>
                                        <v-row justify="center">
                                            <v-col cols="8">
                                                <v-container class="max-width">
                                                    <v-pagination
                                                    @input="nextPageKeyAboutExpire"
                                                    v-model="state.page_active_tab_2"
                                                    class="my-4"
                                                    :length="state.total_page_key_about_expired"
                                                    :total-visible="7"
                                                    ></v-pagination>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item
                        :key="'tab-2'"
                    >
                        <v-card flat>
                            <v-card-text>
                                <v-row class="bg-white">
                                    <v-col
                                        cols="2"
                                        sm="3"
                                        md="3"
                                    >
                                        <v-text-field
                                        dense
                                        label="Số ngày đã hết hạn"
                                        v-model="state.day_number_expired"
                                        outlined
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="2"
                                        sm="1"
                                        md="1">
                                        <v-btn
                                            class="text-transform"
                                            color="primary"
                                            @click="search"
                                        >
                                            <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <hr>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Khách hàng</th>
                                                <th>Sản phẩm</th>
                                                <th>Chăm sóc</th>
                                                <th>Ghi chú</th>
                                                <th>Ngày hết hạn</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="state.loading">
                                                <td class="text-center" colspan="12">
                                                    <v-progress-circular
                                                    :size="50"
                                                    color="primary"
                                                    indeterminate
                                                    ></v-progress-circular>
                                                </td>
                                            </tr>
                                            <template v-else>
                                                <tr v-if="state.message_tab_1 != ''">
                                                    <td colspan="7" align="center">
                                                        <p class="m-0">
                                                            {{state.message_tab_1}}
                                                        </p>
                                                        <p>
                                                            <v-btn
                                                            elevation="2"
                                                            color="primary"
                                                            class="text-transform"
                                                            @click="refresh"
                                                            >
                                                                Tải lại
                                                            </v-btn>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr v-else-if="state.key_expired.length == 0">
                                                    <td colspan="7" align="center">
                                                        Dữ liệu trống
                                                    </td>
                                                </tr>
                                                <template v-else>
                                                    <tr v-for="(dataItem, index) in state.key_expired" :key="index">
                                                        <td>
                                                            {{index + 1}}
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <b>Tên: </b> {{dataItem.customer.name}}
                                                            </p>
                                                            <p>
                                                                <b>Email: </b> {{dataItem.customer.email}}
                                                            </p>
                                                            <p>
                                                                <b>SĐT: </b> {{dataItem.customer.phone}}
                                                            </p>
                                                            <p>
                                                                <b>Địa chỉ: </b> {{dataItem.customer.address}}
                                                            </p>
                                                            <p>
                                                                <b>Key: </b> {{dataItem.license}}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <!-- Nhân viên: <b>{{dataItem.saler.name}}</b> -->
                                                                <v-select
                                                                    dense
                                                                    v-model="dataItem.saler.id"
                                                                    @change="onChangeSaler(dataItem)"
                                                                    :items="state.users"
                                                                    item-text="name"
                                                                    item-value="id"
                                                                    label="Nhân viên"
                                                                    hide-details
                                                                    outlined
                                                                ></v-select>
                                                            </p>
                                                            <p>
                                                                {{dataItem.product}}
                                                            </p>
                                                            <br>
                                                            <v-chip :color="dataItem.type_key.color">
                                                                {{dataItem.type_key.name}}
                                                            </v-chip>
                                                        </td>
                                                        <td>
                                                            <v-switch
                                                            v-model="dataItem.status_remind"
                                                            @change="onChangeKeyItem(dataItem)"
                                                            ></v-switch>
                                                        </td>
                                                        <td>
                                                            <v-textarea
                                                            outlined
                                                            name="input-7-4"
                                                            label="Ghi chú"
                                                            v-model="dataItem.note"
                                                            @change="onChangeKeyItem(dataItem)"
                                                            ></v-textarea>
                                                        </td>
                                                        <td>
                                                            {{dataItem.expire_date}}
                                                        </td>
                                                        <td> 
                                                            <p>
                                                                <v-btn
                                                                    :to="{name: 'edit-key', params: {id: dataItem.id}}"
                                                                    small
                                                                    depressed
                                                                    color="warning"
                                                                    style="margin-right: 7px"
                                                                    >
                                                                    <v-icon small>mdi-pencil</v-icon>
                                                                </v-btn>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                        small
                                                                        color="success"
                                                                        depressed
                                                                        v-bind="attrs"
                                                                        style="margin-right: 7px"
                                                                        v-on="on"
                                                                        @click="openDialog(dataItem, index, 'key_expired')"
                                                                        >
                                                                        <v-icon small>mdi-email</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Gửi mail nhắc nhở</span>
                                                                </v-tooltip>
                                                                <v-btn
                                                                    small
                                                                    depressed
                                                                    color="error"
                                                                    @click="deleteKey(dataItem, index)"
                                                                    >
                                                                    <v-icon small>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                            
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <div class="text-center" v-if="state.total_page_key_expired > 1">
                                    <v-container>
                                        <v-row justify="center">
                                            <v-col cols="8">
                                                <v-container class="max-width">
                                                    <v-pagination
                                                    @input="nextPageKeyExpire"
                                                    v-model="state.page_active_tab_3"
                                                    class="my-4"
                                                    :length="state.total_page_key_expired"
                                                    :total-visible="7"
                                                    ></v-pagination>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item
                        :key="'tab-3'"
                    >
                        <v-card flat>
                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Khách hàng</th>
                                                <th>Sản phẩm</th>
                                                <th>Nhân viên</th>
                                                <th>Loại key</th>
                                                <th>Ngày hết hạn</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="state.loading">
                                                <td class="text-center" colspan="12">
                                                    <v-progress-circular
                                                    :size="50"
                                                    color="primary"
                                                    indeterminate
                                                    ></v-progress-circular>
                                                </td>
                                            </tr>
                                            <template v-else>
                                                <tr v-if="state.message_tab_1 != ''">
                                                    <td colspan="12" align="center">
                                                        <p class="m-0">
                                                            {{state.message_tab_1}}
                                                        </p>
                                                        <p>
                                                            <v-btn
                                                            elevation="2"
                                                            color="primary"
                                                            class="text-transform"
                                                            @click="refresh"
                                                            >
                                                                Tải lại
                                                            </v-btn>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr v-else-if="state.key_active_in_day.length == 0">
                                                    <td colspan="12" align="center">
                                                        Dữ liệu trống
                                                    </td>
                                                </tr>
                                                <template v-else>
                                                    <tr v-for="(dataItem, index) in state.key_active_in_day" :key="index">
                                                        <td>
                                                            {{index + 1}}
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <b>Tên: </b> {{dataItem.customer.name}}
                                                            </p>
                                                            <p>
                                                                <b>Email: </b> {{dataItem.customer.email}}
                                                            </p>
                                                            <p>
                                                                <b>SĐT: </b> {{dataItem.customer.phone}}
                                                            </p>
                                                            <p>
                                                                <b>Địa chỉ: </b> {{dataItem.customer.address}}
                                                            </p>
                                                            <p>
                                                                <b>Key: </b> {{dataItem.license}}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            {{dataItem.product}}
                                                        </td>
                                                        <td>
                                                            <!-- {{dataItem.saler.name}} -->
                                                            <v-select
                                                                dense
                                                                v-model="dataItem.saler.id"
                                                                @change="onChangeSaler(dataItem)"
                                                                :items="state.users"
                                                                item-text="name"
                                                                item-value="id"
                                                                label="Nhân viên"
                                                                hide-details
                                                                outlined
                                                            ></v-select>
                                                        </td>
                                                        <td>
                                                            <v-chip :color="dataItem.type_key.color">
                                                                {{dataItem.type_key.name}}
                                                            </v-chip>
                                                        </td>
                                                        <td>
                                                            {{dataItem.expire_date}}
                                                        </td>
                                                        <td> 
                                                            <p>
                                                                <v-btn
                                                                    small
                                                                    depressed
                                                                    :to="{name: 'edit-key', params: {id: dataItem.id}}"
                                                                    color="warning"
                                                                    style="margin-right: 7px"
                                                                    >
                                                                    <v-icon small>mdi-pencil</v-icon>
                                                                </v-btn>
                                                                
                                                                <v-btn
                                                                    small
                                                                    depressed
                                                                    color="error"
                                                                    @click="deleteKey(dataItem, index)"
                                                                    >
                                                                    <v-icon small>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                            
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item
                        :key="'tab-4'"
                    >
                        <v-card flat>
                            <v-card-text style="padding-left: 0px; padding-right: 0px">
                                <OrderFromGxd :tab="tab" :is_care="true"/>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
import OrderFromGxd from "./OrderFromGxd.vue";
export default {
	name: "Dashboard",
    components: { OrderFromGxd },
	data() {
		return {
            tab: null,
            key_data_by_state: "",
            loading_resend: false,
            state: {
                dialog_mail: false,
                day_number: 7,
                day_number_expired: 7,
                loading: true,
                key_active_in_day: [],
                key_about_expired: [],
                list_emails: [],
                list_email_init: [],
                category_emails: [],
                key_expired: [],
                message_tab_1: '',
                page_active_tab_3: 1,
                page_active_tab_2: 1,
                total_page_key_expired: 1,
                total_page_key_about_expired: 1,
                users: [],
                content_email: {
                    email_id: '',
                    email_recive: '',
                    product_id: '',
                    index: ''
                }
            }
		};
	},
	methods: {
        onChangeCategoryEmail(newCategoryId) {
            const list_email_copy = [...this.state.list_email_init]
            const new_list_email = list_email_copy.filter((item) => item.category_id == newCategoryId)
            this.state.list_emails = new_list_email
        },
        onChangeSaler(orderItem) {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    id: orderItem.id,
                    data_update: {
                        "user_id": orderItem.saler.id
                    }
                })
            };
            fetch(api.update_key_global, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        icon: 'success',
                        title: result.message,
                        timer: 1500,
                    });
                }
            })
        },
        onChangeKeyItem(data) {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(data)
            };
            fetch(api.update_key_from_dashboard, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        icon: 'success',
                        title: result.message,
                        timer: 1500,
                    });
                }
            })
        },
        onSubmitRemind() {
            this.loading_resend = true;
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.state.content_email)
            };
            fetch(api.send_mail_remind, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    const oldState = {...this.state};
                    const data = oldState[this.key_data_by_state];
                    // const data = [...this.state.key_about_expired];
                    data[this.state.content_email.index] = result.data_updated; 
                    this.state = {
                        ...this.state,
                        key_about_expired: data,
                        dialog_mail: false
                    }
                    this.loading_resend = false;
                    this.$swal({
                        icon: 'success',
                        title: result.message,
                        timer: 2000,
                    });
                    fetch(api.run_queue);
                } else {
                    this.loading_resend = false;
                    this.$swal({
                        icon: 'error',
                        title: result.message,
                        timer: 2000,
                    });
                }
            })
        },
        openDialog(dataItem, index, key_data_by_state) {
            this.key_data_by_state = key_data_by_state;
            this.state = {
                ...this.state,
                dialog_mail: true,
                content_email: {
                    email_recive: dataItem.customer.email,
                    product_id: dataItem.product_id,
                    key_id: dataItem.id,
                    index: index
                }
            }
        },
        refresh() {
            this.state = {
                ...this.state,
                loading: true
            }
            this.callApi();
        },
        search() {
            this.state = {
                ...this.state,
                key_about_expired: [],
                loading: true
            }
            this.callApi();
        },
        nextPageKeyExpire(page) {
            this.state = {
                ...this.state,
                loading: true,
                key_expired: [],
                page_active_tab_3: page
            }
            this.callApi();
        },
        nextPageKeyAboutExpire(page) {
            this.state = {
                ...this.state,
                loading: true,
                key_about_expired: [],
                page_active_tab_2: page
            }
            this.callApi();
        },
        deleteKey(license, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: license.id
                        })
                    };
                    fetch(api.delete_key, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            const data_copy = [...this.state.key_active_in_day];
                            data_copy.splice(index, 1);
                            this.state = {
                                ...this.state,
                                key_active_in_day: data_copy
                            }
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 2000
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    })
                }
            })
        },
        callApi() {
            const requestOptions = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                }
            };
            fetch(api.dashboard + '?page=' + this.state.page_active_tab_3 + '&page_tab_2=' + this.state.page_active_tab_2 + '&day_number=' + this.state.day_number + '&day_number_expired=' + this.state.day_number_expired, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.state = {
                        ...this.state,
                        loading: false,
                        users: result.users,
                        key_active_in_day: result.key_active_in_day,
                        key_expired: result.key_expired,
                        key_about_expired: result.key_about_expired,
                        total_page_key_expired: result.total_page_key_expired,
                        total_page_key_about_expired: result.total_page_key_about_expired,
                        list_emails: result.list_emails,
                        list_email_init: result.list_emails
                    }
                } else {
                    this.state = {
                        ...this.state,
                        loading: false,
                        message_tab_1: result.message
                    }
                }
            })
            fetch(api.get_category + "?type=email", requestOptions)
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        this.category_emails = result.data
                    }
                }
            )
        }
	},
	mounted() {
        this.callApi();
	}
};
</script>

<style scoped>
table tr td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}
</style>
