export function debounce (fn, delay) {
    var timeoutID = null
    return function () {
      clearTimeout(timeoutID)
      var args = arguments
      var that = this
      timeoutID = setTimeout(function () {
        fn.apply(that, args)
      }, delay)
    }
}

export function convertNumber(number) {
  return number < 10 ? '0' + number : number;
}

export function printBill(data) {
  window.open('/bill-order/' + data.id, '_blank');
}