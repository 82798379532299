<template>
    <div>
		<br>
        <h3>
			Danh sách key tặng kèm khóa học
		</h3>
        <br>
        <hr>
        <v-row class="bg-white">
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
                <v-simple-table class="list-order">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>
                                    STT
                                </th>
                                <th>
                                    Thông tin key
                                </th>
                                <th>
                                    Khách hàng
                                </th>
                                <th>Trạng thái key</th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="states.loading">
                                <td class="text-center" colspan="5">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-if="states.message != null">
                                <td class="text-center" colspan="5">
                                    <p>
                                        {{states.message}}
                                    </p>
                                    <v-btn
                                        :to="{name: 'list-order'}"
                                        large
                                        depressed
                                        color="error"
                                        style="margin-right: 7px"
                                        >
                                        <v-icon small left>mdi-reload</v-icon> Tải lại
                                    </v-btn>
                                </td>
                            </tr>
                            <tr v-for="(customerItem, index) in states.customers" :key="index">
                                <td>
                                    {{index + 1}}
                                </td>
                                <td>
                                    <p>
                                        Key: <b>{{customerItem.key.license}}</b>
                                    </p>
                                    <p>
                                        Tặng kèm: <b>{{customerItem.product_bonus.name}}</b>
                                    </p>
                                    <p>
                                        Loại key: <b>Key thương mại - 1 năm</b>
                                    </p>
                                    <p>
                                        Ngày tạo: <b>{{customerItem.created_at}}</b>
                                    </p>
                                    <p>
                                        Ngày gửi: <b>{{customerItem.date_accept}}</b>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        Sản phẩm: <b>{{customerItem.product.name}}</b>
                                    </p>
                                    <p>
                                        {{customerItem.customer.name}}
                                    </p>
                                    <p>
                                        {{customerItem.customer.email}}
                                    </p>
                                    <p>
                                        {{customerItem.customer.phone}}
                                    </p>
                                </td>
                                <td>
                                    <v-chip
                                    class="ma-2"
                                    :color="customerItem.key.status == 1 ? 'error' : 'primary'"
                                    >
                                    {{customerItem.key.status == 1 ? 'Chưa kích hoạt' : 'Đã kích hoạt'}}
                                    </v-chip>
                                </td>
                                <td align="center">
                                    <v-btn
                                        small
                                        depressed
                                        color="error"
                                        @click="deleteCustomer(customerItem.id, index)"
                                        >
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
		<div class="text-center" v-if="states.filters.total_page > 1">
            <v-container>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            @input="nextPage"
                            v-model="states.filters.page"
                            class="my-4"
                            :length="states.filters.total_page"
                            :total-visible="7"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
	</div>
</template>
<script>
import { api } from "./../../../../helpers/const.js";
export default {
	name: "BonusKey",
	data() {
		return {
            states: {
                loading: true,
                message: null,
                customers: [],
                filters: {
                    page: 1,
                    total_page: 0
                }
            }
        }
    },
    methods: {
        nextPage(page) {
            this.states = {
                ...this.states,
                page: page,
                customers: [],
                loading: true
            }
            this.callApi();
        },
        deleteCustomer(id, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                            },
                            body: JSON.stringify({
                                id: id
                            })
                        };
                        fetch(api.delete_customer_bonus_key, requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.success) {
                                this.$swal({
                                    title: result.message,
                                    timer: 1000,
                                    icon: 'success'
                                })
                                const customer_copy = [...this.states.customers];
                                customer_copy.splice(index, 1);
                                this.states = {
                                    ...this.states,
                                    customers: customer_copy
                                }
                            } else {
                                this.$swal({
                                    title: result.message,
                                    timer: 1000,
                                    icon: 'error'
                                })
                            }
                        })
                }
            })
            
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.customer_bonus_key, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        ...this.states,
                        loading: false,
                        customers: result.orders,
                        filters: {
                            ...this.states.filters,
                            total_page: result.total_page,
                        },
                    }
                } else {
                    this.states = {
                        ...this.states,
                        loading: false,
                        message: result.message
                    }
                }
            })
        }
    },
    mounted() {
		this.callApi()
	}
}
</script>
