
import Login from './../components/Logins/Index.vue';
import ForgetPassword from './../components/ForgetPasswords/Index.vue';
import ResetPassword from './../components/ResetPasswords/Index.vue';
import Error from './../components/Error/Index.vue';
import Admin from './../components/Admins/Index.vue';
import Dashboard from './../components/Admins/Pages/Dashboards/Index.vue'
import CreateOrder from './../components/Admins/Pages/Orders/Creates/Index.vue'
import EditOrder from './../components/Admins/Pages/Orders/Edits/Index.vue'
import ListOrder from './../components/Admins/Pages/Orders/Lists/Index.vue'
import BillOrder from './../components/Admins/Pages/Orders/Bills/Index.vue'
import MyOrder from './../components/Admins/Pages/Orders/Lists/MyOrder.vue'
import Product from './../components/Admins/Pages/Products/Index.vue'
import Email from './../components/Admins/Pages/Emails/Index.vue'
import Smtp from './../components/Admins/Pages/Emails/Components/Smtp.vue'
import ListUser from './../components/Admins/Pages/Users/List.vue'
import ListRole from './../components/Admins/Pages/Roles/List.vue'
import Permission from './../components/Admins/Pages/Permissions/List.vue'
import AddEmail from './../components/Admins/Pages/Emails/Components/Add'
import EditEmail from './../components/Admins/Pages/Emails/Components/Edit'
import Key from './../components/Admins/Pages/Keys/List.vue'
import KeyDetail from './../components/Admins/Pages/Keys/Detail.vue'
import EditKey from './../components/Admins/Pages/Keys/EditKey.vue'
import SendKey from './../components/Admins/Pages/Keys/SendKey.vue'
import ImportCertificate from './../components/Admins/Pages/Certificates/Import.vue'
import ExportCertificate from './../components/Admins/Pages/Certificates/Export.vue'
import DetailCertificate from './../components/Admins/Pages/Certificates/Components/Detail.vue'
import DetailCertificateApproved from './../components/Admins/Pages/Certificates/Components/DetailApproved.vue'
import ListCertificate from './../components/Admins/Pages/Certificates/Components/List.vue'
import EditCertificate from './../components/Admins/Pages/Certificates/Components/Edit.vue'
import CertificateApproved from './../components/Admins/Pages/Certificates/Approved.vue'
import CertificateWaitApprove from './../components/Admins/Pages/Certificates/WaitApprove.vue'
import Vacc from './../components/Admins/Pages/Statisticals/Vacc.vue'
import Vace from './../components/Admins/Pages/Statisticals/Vace.vue'
import StatusPayment from './../components/Admins/Pages/Statisticals/StatusPayment.vue'
import DetailEmployee from './../components/Admins/Pages/Statisticals/DetailEmployee.vue'
import StatisticalKpi from './../components/Admins/Pages/Statisticals/Kpi.vue'
import StatisticalProduct from './../components/Admins/Pages/Statisticals/Product.vue'
import AllEmployee from './../components/Admins/Pages/Statisticals/AllEmployee.vue'
import OweMoney from './../components/Admins/Pages/Statisticals/OweMoney.vue'
import Search from './../components/Admins/Pages/Searchs/Search.vue'
import KeySendedToday from './../components/Admins/Pages/Keys/ListSendedToday.vue'
import KeySendedEmail from './../components/Admins/Pages/Keys/SendedEmail.vue'
import KeyWaitingSend from './../components/Admins/Pages/Keys/WaitingSend.vue'
import KeyActived from './../components/Admins/Pages/Keys/Actived.vue'
import CustomerNotActive from "./../components/Admins/Pages/Customers/NotActive.vue";
import AboutToExpire from "./../components/Admins/Pages/Customers/AboutToExpire.vue";
import CustomerExpired from "./../components/Admins/Pages/Customers/Expired.vue";
import CustomerUseKeyToDay from "./../components/Admins/Pages/Customers/UseToday.vue";
import CustomerByRegion from "./../components/Admins/Pages/Customers/Region.vue";
import CustomerByBonusKey from "./../components/Admins/Pages/Customers/BonusKey.vue";
import CustomerUseKeyHardware from "./../components/Admins/Pages/Customers/UseKeyHardware.vue";
import CustomerUseKeySoftware from "./../components/Admins/Pages/Customers/UseKeySoftware.vue";
import CustomerUseCourse from "./../components/Admins/Pages/Customers/UseCourse.vue";
import CustomerUseKeyTest from "./../components/Admins/Pages/Customers/UseKeyTest.vue";
import CustomerUseCertificate from "./../components/Admins/Pages/Customers/UseCertificate.vue";

const routes = [
    {path: '/', component: Login},
    {
        path: '/forget-password',
        name: 'forget-password',
        component: ForgetPassword
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: ResetPassword
    },
    {
        path: '/bill-order/:id',
        name: 'bill-order',
        component: BillOrder
    },
    {
        path: '/admin',
        name: 'layout',
        component: Admin,
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: Dashboard
            },
            {
                path: 'order/create',
                name: 'create-order',
                component: CreateOrder
            },
            {
                path: 'order/:id/edit',
                name: 'edit-order',
                component: EditOrder
            },
            {
                path: 'order/list',
                name: 'list-order',
                component: ListOrder
            },
            {
                path: 'order/my-order',
                name: 'my-order',
                component: MyOrder
            },
            {
                path: 'product/list',
                name: 'list-product',
                component: Product
            },
            {
                path: 'email/list',
                name: 'list-email',
                component: Email
            },
            {
                path: 'email/add',
                name: 'add-email',
                component: AddEmail
            },
            {
                path: 'email/:id',
                name: 'edit-email',
                component: EditEmail
            },
            {
                path: 'user/list',
                name: 'list-user',
                component: ListUser  
            },
            {
                path: 'user/role',
                name: 'list-role',
                component: ListRole  
            },
            {
                path: 'user/permission',
                name: 'list-permission',
                component: Permission  
            },
            {
                path: 'key/list',
                name: 'list-key',
                component: Key
            },
            {
                path: 'key/detail',
                name: 'key-detail',
                component: KeyDetail
            },
            {
                path: 'key/:id/edit',
                name: 'edit-key',
                component: EditKey
            },
            {
                path: 'key/send-key',
                name: 'send-key',
                component: SendKey
            },
            {
                path: 'key/sended-today',
                name: 'key-sended-today',
                component: KeySendedToday
            },
            {
                path: 'key/sended-email',
                name: 'key-sended-email',
                component: KeySendedEmail
            },
            {
                path: 'key/waiting-send',
                name: 'key-waiting-send',
                component: KeyWaitingSend
            },
            {
                path: 'key/actived',
                name: 'key-actived',
                component: KeyActived
            },
            {
                path: 'certificate/import',
                name: 'certificate-import',
                component: ImportCertificate
            },
            {
                path: 'certificate/export',
                name: 'certificate-export',
                component: ExportCertificate
            },
            {
                path: 'certificate/list',
                name: 'certificate-list',
                component: ListCertificate
            },
            {
                path: 'certificate/approved',
                name: 'certificate-approved',
                component: CertificateApproved
            },
            {
                path: 'certificate/wait-approve',
                name: 'certificate-wait-approve',
                component: CertificateWaitApprove
            },
            {
                path: 'certificate/import/:name_upload/detail',
                name: 'certificate-detail',
                component: DetailCertificate
            },
            {
                path: 'certificate/:name_upload/approved/detail',
                name: 'certificate-detail-approved',
                component: DetailCertificateApproved
            },
            {
                path: 'certificate/import/:id/edit',
                name: 'certificate-import-edit',
                component: EditCertificate
            },
            {
                path: 'statistical/vacc',
                name: 'statistical-vacc',
                component: Vacc
            },
            {
                path: 'statistical/vace',
                name: 'statistical-vace',
                component: Vace
            },
            {
                path: 'statistical/status-payment',
                name: 'statistical-status-payment',
                component: StatusPayment
            },
            {
                path: 'statistical/kpi',
                name: 'statistical-kpi',
                component: StatisticalKpi
            },
            {
                path: 'statistical/all-employee',
                name: 'statistical-all-employee',
                component: AllEmployee
            },
            {
                path: 'statistical/product',
                name: 'statistical-product',
                component: StatisticalProduct
            },
            {
                path: 'statistical/detail-employee',
                name: 'statistical-detail-employee',
                component: DetailEmployee
            },
            {
                path: 'statistical/owe',
                name: 'statistical-owe',
                component: OweMoney
            },
            {
                path: 'search',
                name: 'search',
                component: Search
            },
            {
                path: 'setting/smtp',
                name: 'setting-smtp',
                component: Smtp
            },
            {
                path: 'customer/not-active',
                name: 'customer-not-active',
                component: CustomerNotActive
            },
            {
                path: 'customer/about-to-expire',
                name: 'customer-about-to-expire',
                component: AboutToExpire
            }
            ,
            {
                path: 'customer/expired',
                name: 'customer-expired',
                component: CustomerExpired
            },
            {
                path: 'customer/use-today',
                name: 'customer-use-today',
                component: CustomerUseKeyToDay
            },
            {
                path: 'customer/region',
                name: 'customer-region',
                component: CustomerByRegion
            },
            {
                path: 'customer/bonus-key',
                name: 'customer-bonus-key',
                component: CustomerByBonusKey
            },
            {
                path: 'customer/use-key-hardware',
                name: 'customer-use-key-hardware',
                component: CustomerUseKeyHardware
            },
            {
                path: 'customer/use-key-software',
                name: 'customer-use-key-software',
                component: CustomerUseKeySoftware
            },
            {
                path: 'customer/use-course',
                name: 'customer-use-course',
                component: CustomerUseCourse
            },
            {
                path: 'customer/use-key-test',
                name: 'customer-use-key-test',
                component: CustomerUseKeyTest
            },
            {
                path: 'customer/use-certificate',
                name: 'customer-use-certificate',
                component: CustomerUseCertificate
            }
        ]
    },
    {path: '/:pathMatch(.*)*', component: Error}
]

export default routes
