<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
		<h3 class="m-b-10">
            Danh sách dự thi chứng chỉ
		</h3>
        <br>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="2"
				sm="2"
				md="2"
			>
                <v-text-field
                dense
                label="Tên khách hàng..."
                v-model="state.filter.key"
                outlined
                ></v-text-field>
            </v-col>
            <v-col
				cols="2"
				sm="2"
				md="2"
			>
                <v-text-field
                dense
                type="date"
                label="Từ ngày"
                v-model="state.filter.date_from"
                outlined
                ></v-text-field>
            </v-col>
            <v-col
				cols="2"
				sm="2"
				md="2"
			>
                <v-text-field
                dense
                type="date"
                label="Đến ngày"
                v-model="state.filter.date_to"
                outlined
                ></v-text-field>
            </v-col>
            <v-col
				cols="2"
				sm="2"
				md="2"
			>
                <v-select
                    v-model="status_exam"
                    :items="state.status_exam"
                    item-text="name"
                    item-value="value"
                    label="Trạng thái"
                    outlined
                    dense
                ></v-select>
            </v-col>
            
            <v-col cols="2"
				sm="1"
				md="1">
                <v-btn
                    class="text-transform"
                    color="primary"
                    @click="search"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
            </v-col>
        </v-row>
        <hr>
        <v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center">
                                    STT
                                </th>
                                <th class="text-left">
                                    Thông tin cá nhân
                                </th>
                                <th class="text-left">
                                    Lĩnh vực theo nghị định
                                </th>
                                <th>
                                    Lĩnh vực và hạng
                                </th>
                                <th>Trạng thái</th>
                                <th>Lượt thi</th>
                                <th>Ngày thi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="state.loading">
                                <td class="text-center" colspan="7">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-if="state.message != null">
                                <td class="text-center" colspan="6">
                                    {{state.message}} <br>
                                    <v-btn
                                        class="text-transform"
                                        depressed
                                        @click="reloadPage"
                                        color="primary"
                                    >
                                        Tải lại
                                    </v-btn>
                                </td>
                            </tr>
                            <tr v-for="(certificate, index) in state.certificates" :key="index">
                                <td align="center">
                                    {{index+1}}
                                </td>
                                <td>
                                    <p>
                                        <b>Tên</b>: {{certificate.customer.full_name}}
                                    </p>
                                    <p>
                                        <b>SĐT</b>: {{certificate.customer.phone}}
                                    </p>
                                    <p>
                                        <b>Địa chỉ: </b>{{certificate.customer.address}}
                                    </p>
                                    <p>
                                        <b>CMT: </b>{{certificate.customer.cmnd}}
                                    </p>
                                    <p class="m-b-0">
                                        <b>Trình độ: </b>{{certificate.customer.qualification}}
                                    </p>
                                </td>
                                <td style="width: 25%; padding-right: 20px !important">
                                    <v-autocomplete
                                        v-model="certificate.field_decree"
                                        :items="state.type_exam_list"
                                        outlined
                                        dense
                                        multiple
                                        label="Chọn lĩnh vực"
                                        chips
                                        small-chips
                                        @change="changeProduct"
                                    ></v-autocomplete>
                                    <v-btn
                                        small
                                        depressed
                                        color="primary"
                                        style="margin-right: 7px"
                                        @click="updateFieldDecree(certificate)"
                                        >
                                        Cập nhật
                                    </v-btn>
                                </td>
                                <td>
                                    <v-textarea
                                    name="input-7-1"
                                    filled
                                    label="Lĩnh vực thi"
                                    hint="Cách nhau bằng dấu ; không để dấu ; ở cuối"
                                    auto-grow
                                    v-model="certificate.type_exam"
                                    ></v-textarea>
                                    <v-text-field
                                        v-model="certificate.rank_exam"
                                        label="Hạng thi"
                                        hint="Cách nhau bằng dấu ; không để dấu ; ở cuối"
                                        filled
                                    ></v-text-field>
                                    <v-btn
                                        small
                                        depressed
                                        color="primary"
                                        style="margin-right: 7px"
                                        @click="updateTypeAndRankExam(certificate)"
                                        >
                                        Cập nhật
                                    </v-btn>
                                    <!-- <table class="table-type-exam" v-if="certificate.type_exam_and_rank.length > 0">
                                        <tr v-for="(type_exam, index1) in certificate.type_exam_and_rank" :key="index1">
                                            <td>
                                                {{type_exam.type_exam}}
                                            </td>
                                            <td>
                                                {{type_exam.rank_exam}}
                                            </td>
                                        </tr>
                                    </table> -->
                                </td>
                                <td align="center">
                                    <span :class="certificate.date_exam == null ? 'label label-danger' : 'label label-success'">
                                        {{certificate.date_exam == null ? 'Chưa thi' : 'Đã thi'}}
                                    </span>
                                </td>
                                <td>
                                    {{certificate.retest}}
                                </td>
                                <td>
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            v-model="certificate.date_exam"
                                            label="Date"
                                            prepend-icon="mdi-calendar"
                                            v-bind="attrs"
                                            @blur="date = certificate.date_exam"
                                            v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="certificate.date_exam"
                                            @change="changeDateExam(certificate)"
                                            no-title
                                            @input="menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
			</v-col>
		</v-row>
        <div class="text-center" v-if="state.total_page > 1">
            <v-container>
            <v-row justify="center">
                <v-col cols="8">
                    <v-container class="max-width">
                        <v-pagination
                        @input="nextPage"
                        v-model="state.filter.page"
                        class="my-4"
                        :length="state.total_page"
                        :total-visible="7"
                        ></v-pagination>
                    </v-container>
                </v-col>
            </v-row>
            </v-container>
        </div>
	</div>
</template>

<script>
import { api } from "./../../../../../helpers/const.js";
import Breadcrumb from "./../../../Shares/Breadcrumb.vue";
import {convertNumber} from "./../../../../../helpers/function.js";

export default {
	name: "DetailCertificate",
	// components: { Breadcrumb },
	data() {
        const date = new Date();
		return {
            status_exam: -1,
            state: {  
                total_page: 0,
                menu: false,
                loading: true,
                message: null,
                certificates: [],
                type_exam_list: [],
                file_upload: [],
                status_exam: [
                    {
                        'value': -1,
                        'name': 'Tất cả'
                    },
                    {
                        'value': 0,
                        'name': 'Chưa thi'
                    },
                    {
                        'value': 1,
                        'name': 'Đã thi'
                    }
                ],
                filter: {
                    page: 1,
                    key: '',
                    date_from: new Date().getFullYear() + '-' + convertNumber(parseInt(new Date().getMonth() + 1)) + '-01',
                    date_to: new Date().getFullYear() + '-' + convertNumber(parseInt(1 + new Date().getMonth())) + '-' + convertNumber(new Date().getDate()),
                    status: {}
                }
            },
            errors: {
                name: '',
                file: '',
            }
		};
	},
	methods: {
        changeDateExam(data) {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(data)
            };
            fetch(api.update_certificate_accepted, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        title: result.message,
                        icon: 'error',
                        timer: 2000
                    })
                }
            });
        },
        updateFieldDecree(data) {
            if (data.field_decree.length == 0) {
                this.$swal({
                    title: 'Chưa chọn lĩnh vực theo nghị định',
                    icon: 'warning',
                    timer: 1500
                })
            } else {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                    },
                    body: JSON.stringify(data)
                };
                fetch(api.update_certificate_accepted, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.success) {
                        this.$swal({
                            title: result.message,
                            icon: 'success',
                            timer: 1500
                        })
                    } else {
                        this.$swal({
                            title: result.message,
                            icon: 'error',
                            timer: 2000
                        })
                    }
                });
            }
        },
        updateTypeAndRankExam(data) {
            if (data.type_exam == null || data.rank_exam == null) {
                this.$swal({
                    title: 'Chưa chọn lĩnh vực hoặc hạng thi',
                    icon: 'warning',
                    timer: 2500
                });
            } else {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                    },
                    body: JSON.stringify(data)
                };
                fetch(api.update_certificate_accepted, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.success) {
                        this.$swal({
                            title: result.message,
                            icon: 'success',
                            timer: 1500
                        })
                    } else {
                        this.$swal({
                            title: result.message,
                            icon: 'error',
                            timer: 2000
                        })
                    }
                });
            }
        },
        reloadPage() {
            this.states = {
                ...this.states,
                loading: true,
                message: null
            }
            this.callApi();
        },
        nextPage(page) {
            this.state = {
                ...this.state,
                loading: true,
                certificates: [],
                filter: {...this.state.filter, page: page}
            }
            this.callApi();
        },
        search() {
            this.state = {
                ...this.state,
                loading: true,
                certificates: []
            }
            this.callApi();
        },
        callApi() {
            const body = {...this.state.filter};
            body['status_exam'] = this.status_exam;
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(body)
            };
            fetch(api.certificate_list, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.state = {
                        ...this.state,
                        loading: false,
                        certificates: result.datas,
                        type_exam_list: result.type_exam_list,
                        total_page: result.total_page
                    }
                } else {
                    this.state = {
                        ...this.state,
                        message: result.message,
                        loading: false
                    }
                }
            });
        }
	},
	mounted() {
        this.callApi();
	}
};
</script>

<style scoped>
table tr td {
    padding: 20px 0px !important;
}
.table-type-exam {
    border-collapse: collapse;
}
.table-type-exam tr td {
    border: 1px solid #ccc;
    padding: 10px !important;
}
</style>
