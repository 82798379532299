<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
		<br>
		<h3>
			Danh sách sản phẩm
		</h3>
		<br/>
		<v-row justify="left">
			<v-dialog v-model="dialog" persistent max-width="600px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn color="primary" dark v-bind="attrs" v-on="on">
						Thêm sản phẩm
					</v-btn>
				</template>
				<v-card>
					<v-card-title>
						<span class="text-h5">Thông tin sản phẩm</span>
					</v-card-title>
					<form @submit.prevent="onSubmitProduct">
						<v-card-text>
							<v-container>
								<v-row>
										<v-col cols="12" sm="6" md="6">
											<v-text-field
												label="Tên sản phẩm"
												v-model="editedItem.name"
												name="name"
												:error-messages="errors.name"
											></v-text-field>
										</v-col>
										
										<v-col cols="12" sm="6" md="6">
											<v-text-field
												label="Số lần được đổi máy"
												name="number_of_change"
												v-model="editedItem.number_of_change"
												:error-messages="errors.number_of_change"
											></v-text-field>
										</v-col>
										<v-col cols="4">
											<!-- Sủ dụng  return-object để lấy giá trị trong select khi submit -->
											<v-select
												:items="product_status"
												item-text="name"
												item-value="value"
												label="Trạng thái"
												v-model="editedItem.status"
												name="product_status"
												:error-messages="errors.status"
											></v-select>
										</v-col>
										<v-col cols="4">
											<v-select
												:items="product_types"
												label="Loại sản phẩm"
												item-text="name"
												v-model="editedItem.type"
												item-value="value"
												name="product_type"
												:error-messages="errors.type"
											></v-select>
										</v-col>
										<v-col cols="4">
											<v-text-field
												label="Mã sản phẩm"
												v-model="editedItem.code"
												name="code"
												:disabled="statusEdit == true"
												:error-messages="errors.code"
											></v-text-field>
										</v-col>
										<v-col cols="4">
											<v-text-field
												label="Giá bán"
												v-model="editedItem.price"
												type="number"
												name="price"
												:error-messages="errors.price"
											></v-text-field>
										</v-col>
										<v-col cols="4">
											<v-text-field
												label="Tiền nộp đi"
												v-model="editedItem.payment"
												type="number"
												name="payment"
												:error-messages="errors.payment"
											></v-text-field>
										</v-col>
										<v-col cols="4" sm="4" md="4">
											<v-text-field
												label="Chiết khấu"
												v-model="editedItem.percent"
												type="number"
												value="10"
												name="percent"
												:error-messages="errors.percent"
											></v-text-field>
										</v-col>
										
										<v-col cols="4">
											<v-text-field
												label="Versions"
												name="version"
												v-model="editedItem.version"
											></v-text-field>
										</v-col>
										<v-col cols="4">
											<v-text-field
												label="Key version"
												name="key_version"
												v-model="editedItem.key_version"
											></v-text-field>
										</v-col>
										
										<v-col cols="4">
											<v-text-field
												label="API Sendy"
												name="api_sendy"
												v-model="editedItem.api_sendy"
											></v-text-field>
										</v-col>
										<v-col cols="12">
											<v-textarea
											outlined
											name="description"
											label="Mô tả sản phẩm"
											rows="3"
											value=""
											v-model="editedItem.description"
											></v-textarea>
										</v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialog = false, statusEdit = false, editedItem = {
									id: '',
									name: '',
									price: '',
									payment: '',
									percent: 10,
									type: '',
									number_of_change: '',
									status: 1,
									description: '',
								}"
							>
								Đóng
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								type="submit"
							>
								Lưu
							</v-btn>
						</v-card-actions>
					</form>
				</v-card>
			</v-dialog>
		</v-row>
		<br/>
		<div v-if="loading == true" class="text-center">
			<v-progress-circular
			:size="50"
			color="primary"
			indeterminate
			></v-progress-circular>
		</div>
		<v-card v-if="loading == false">
			<v-card-title>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				></v-text-field>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="desserts"
				:search="search"
			>
				<template v-slot:item.action="{ item }">
					<v-btn
					size="small"
					depressed
					color="warning"
					@click="editItem(item)"
					>
						<v-icon
							small
							
						>
							mdi-pencil
						</v-icon>
					</v-btn>
					
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
import Breadcrumb from "./../../Shares/Breadcrumb.vue";
export default {
	name: "Product",
	// components: { Breadcrumb },
	data() {
		return {
			loading: true,
			dialog: false,
			errors: {
				"name": "",
				"status": "",
				"type": "",
				"price": "",
				"payment": "",
				"percent": "",
				"number_of_change": "",
				"code": "",
			},
			product_types: [
				{ name: 'Khóa mềm', value: 0 },
				{ name: 'Khóa cứng', value: 1 },
				{ name: 'Khóa học', value: 2 },
				{ name: 'Chứng chỉ', value: 3 },
			],
			product_status_selected: {
				name: 'Công bố',
				value: 1
			},
			product_status: [
				{name: 'Công bố', value: 1},
				{name: 'Đang phát triển', value: 0}
			],
			breadcrumbs: [
				{
					text: "Dashboard",
					disabled: false,
					href: "breadcrumbs_dashboard"
				},
				{
					text: "Sản phẩm",
					disabled: false,
					href: "breadcrumbs_link_1"
				}
			],
			search: "",
			headers: [
				{
					text: "Phần mềm",
					align: "start",
					filterable: true,
					value: "name"
				},
				{ text: "Giá bán", value: "price" },
				{ text: "Giá mua", value: "payment" },
				{ text: "Loại", value: "type_text" },
				{ text: "Số lần đổi máy", value: "number_of_change" },
				{ text: "Trạng thái", value: "status_text" },
				{ text: "Hành động", value: "action" }
			],
			desserts: [],
			editedItem: {
				id: '',
				name: '',
				price: '',
				payment: 0,
				percent: 10,
				type: '',
				number_of_change: '',
				status: 1,
				description: '',
				code: ''
			},
			statusEdit: false
		};
	},
	methods: {
		editItem (item) {
			this.editedItem = Object.assign({}, item)
			this.dialog = true
			this.statusEdit = true
			this.errors = {
				"name": "",
				"status": "",
				"type": "",
				"price": "",
				"payment": "",
				"percent": "",
				"number_of_change": "",
				"code": "",
			}
		},
		addProduct() {
			console.log(this.name, this.product_type, this.product_status_selected.value);
		},
		onSubmitProduct(e) {
			const data = e.target.elements;

			if (this.statusEdit == true) {
				const requestOptions = {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						'Authorization': 'Bearer ' + this.$cookie.get("is_login")
					},
					body: JSON.stringify(this.editedItem)
				};
				fetch(api.product_edit, requestOptions)
				.then((response) => response.json())
				.then((result) => {
					if (result.success == false) {
						if (result.type == "validate") {
							this.errors = result.errors;
						} else if (result.type == "lose") {
							this.$swal({
								icon: 'error',
								title: result.message,
								timer: 1000
							});
						}
					} else {
						this.statusEdit = false;
						this.dialog = false;
						this.desserts = result.datas;
						this.editedItem = {
												name: '',
												price: '',
												payment: '',
												percent: 10,
												type: '',
												number_of_change: '',
												status: 1,
												description: '',
												code: ''
											}
						this.$swal({
							icon: 'success',
  							title: result.message,
							  timer: 1000
						});
					}
				});
				
			} else {
				const requestOptions = {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						'Authorization': 'Bearer ' + this.$cookie.get("is_login")
					},
					body: JSON.stringify({
						name: data.name.value,
						number_of_change: data.number_of_change.value,
						status: this.product_status_selected.value,
						type: data.product_type.value,
						price: data.price.value,
						payment: data.payment.value,
						percent: data.percent.value,
						version: data.version.value,
						key_version: data.key_version.value,
						api_sendy: data.api_sendy.value,
						description: data.description.value,
						code: data.code.value
					})
				};
				fetch(api.product_add, requestOptions)
				.then((response) => response.json())
				.then((result) => {
					if (result.success == true) {
						this.dialog = false;
						this.desserts = result.datas;
						this.$swal({
							icon: 'success',
  							title: result.message,
							timer: 1000
						});
					} else {
						if (result.type == "validate") {
							this.errors = result.errors;
						} else if (result.type == "lose") {
							this.$swal({
								icon: 'error',
								title: result.message,
								timer: 1000
							});
						}
					}
				});
			}
			
		}
	},
	mounted() {
		const requestOptions = {
			method: "GET",
			headers: {
				'Authorization': 'Bearer ' + this.$cookie.get("is_login")
			}
		};
		fetch(api.product_list, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			if (result.success == true) {
				this.desserts = result.datas;
				this.loading = false;
			}			
		});
	}
};
</script>

<style scoped></style>
