<template>
    <div>
        <!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
        <h3>
            <router-link :to="{name: 'list-email'}" class="a-none">
                Sửa Email
            </router-link>
        </h3>
        <br/>
        <FileManager/>
        <br>
        <form @submit.prevent="editEmail">
            <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
                <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        label="Tên mẫu Email *"
                        hint="Dùng để tìm kiếm khi chọn email gửi"
                        persistent-hint
                        outlined
                        name="name"
                        v-model="state.oldEmail.name"
                        :error-messages="state.errors.name"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        label="Tiêu đề Email *"
                        hint="Làm tiêu đề email khi gửi Mail"
                        persistent-hint
                        outlined
                        v-model="state.oldEmail.title"
                        :error-messages="state.errors.title"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-select
                        v-model="state.oldEmail.category_id"
                        :error-messages="state.errors.category_id"
                        :items="categories"
                        outlined
                        item-text="name"
                        item-value="id"
                        label="Loại Email *"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-file-input
                        v-model="state.oldEmail.attachments"
                        label="Chọn tài liệu"
                        hint="Tệp đính kèm khi gửi mail"
                        multiple
                        persistent-hint
                        outlined
                    ></v-file-input>
                    <p style="color: red" v-for="(attachmentItem, index) in state.attachments" :key="index">
                        {{ state.errors["attachments." + index] !== undefined ? state.errors["attachments." + index] : '' }}
                    </p>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="state.oldEmail.category_id == 1">
                    <v-autocomplete
                        v-model="state.oldEmail.product"
                        :items="products"
                        outlined
                        :error-messages="state.errors.product_id"
                        item-text="name"
                        item-value="id"
                        return-object
                        label="Chọn sản phẩm *"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="state.oldEmail.category_id != 1">
                    <p>
                        Nội dung email
                    </p>
                    <ckeditor :error-messages="state.errors.content" id="kh" ref="ta" :config="editorConfig" :editor-url="editorUrl" v-model="state.oldEmail.content"></ckeditor>
                </v-col>
                <v-col cols="12" :sm="state.oldEmail.category_id == 1 ? '6' : '12'" :md="state.oldEmail.category_id == 1? '6' : '12'" v-if="state.oldEmail.category_id == 1">
                    <p>
                        Nội dung
                    </p>
                    <ckeditor :error-messages="state.errors.content" id="kh" ref="ta" :config="editorConfig" :editor-url="editorUrl" v-model="state.oldEmail.content"></ckeditor>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="state.oldEmail.category_id == 1">
                    <p>
                        Nội dung dùng thử
                    </p>
                    <ckeditor id="kh" ref="ta" :config="editorConfig" :editor-url="editorUrl" v-model="state.oldEmail.content_trial"></ckeditor>
                </v-col>
            </v-row>
            <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
                <v-col cols="12" sm="12" md="12">
                    <v-btn
                    depressed
                    color="primary"
                    width="100%"
                    type="submit"
                    :loading="loading"
                    >
                    Xác nhận
                    </v-btn>
                </v-col>
            </v-row>
        </form>
    </div>
    
</template>

<script>
import Breadcrumb from "./../../../Shares/Breadcrumb.vue";
import FileManager from "./../../../../Admins/Shares/FileManager.vue"
import { api } from "./../../../../../helpers/const.js";
export default {
    name: "ListEmail",
    components: {FileManager },
    data() {
        return {
            editorUrl: api.editor,
            linkStyle: {
                "z-index": "2222223333 !important"
            },
            loading: false,
            dialog: false,
            dialog_file: false,
            products: [],
            categories: [],
            breadcrumbs: [
                {
                    text: "Dashboard",
                    disabled: false,
                    href: "/admin"
                },
                {
                    text: "Mẫu Email",
                    disabled: false,
                    href: "/admin/email"
                }
            ],
            state: {
                id: this.$route.params.id,
                name: '',
                title: '',
                content: '',
                content_trial: '',
                products: [],
                errors: {
                    name: '',
                    title: '',
                    content: '',
                    product: '',
                    attachments: "",
                    category_id: ''
                },
                oldEmail: {
                    name: '',
                    title: '',
                    content: '',
                    content_trial: '',
                    product: {},
                    category_id: '',
                    attachments: []
                }
            }
        }
    },
    methods: {
        editEmail() {
            this.loading = true;
            const formData = new FormData();
            formData.append('name', this.state.oldEmail.name);
            formData.append('title', this.state.oldEmail.title);
            formData.append('content', this.state.oldEmail.content);
            formData.append('content_trial', this.state.oldEmail.content_trial);
            formData.append('category_id', this.state.oldEmail.category_id);
            formData.append('product_id', this.state.oldEmail.product.id == null ? '' : this.state.oldEmail.product.id);
            formData.append('email_id', this.state.id)
            for (let i = 0; i < this.state.oldEmail.attachments.length; i++) {
                formData.append('attachments[]', this.state.oldEmail.attachments[i]);
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: formData
            };
            fetch(api.update_email, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.$swal({
                        icon: 'success',
                        title: result.message,
                        timer: 1500
                    });
                    // this.$router.push('/admin/email/list');
                } else {
                    if (result.type == 'validate') {
                        this.state = {
                            ...this.state,
                            errors: result.errors
                        }
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: result.message,
                            timer: 1500
                        });
                    }
                }
                this.loading = false;
            });
        }
    },
    mounted() {
		const requestOptions = {
			method: "GET",
			headers: {
				'Authorization': 'Bearer ' + this.$cookie.get("is_login")
			}
		};
		fetch(api.domain + 'email/' + this.state.id + '/edit', requestOptions)
		.then((response) => response.json())
		.then((result) => {
			if (result.success == true) {
                this.products = result.products;
                this.state = {
                    ...this.state,
                    oldEmail: result.email
                }
			}			
		});
        fetch(api.get_category + "?type=email", requestOptions)
        .then((res) => res.json())
        .then(
            (result) => {
                if (result.success) {
                    this.categories = result.data
                }
            }
        )
	}
}
</script>

<style scoped>
.v-dialog__content {
    z-index: 1234 !important;
}
</style>
