<template>
    <div>
		<br>
        <h3>
			Danh sách khách hàng đã hết hạn
		</h3>
		<br/>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
                style="padding-top: 0px; padding-bottom: 0px"
			>
                <h3 >Lọc dữ liệu</h3>
            </v-col>
        </v-row>
        <v-row class="bg-white" style="padding: 0px 10px 0px 10px;">
            <v-col cols="2"
				sm="3"
				md="3">
                <v-select
                    outlined
                    dense
                    :items="states.list_time"
                    label="Chọn thời gian"
                    v-model="states.filters.time"
                    item-text="name"
                    item-value="value"
                ></v-select>
            </v-col>
            <v-col cols="2"
				sm="3"
				md="3">
                <v-btn
                    class="text-transform"
                    color="primary"
                    @click="search"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
                <v-btn
                    style="margin-left: 10px"
                    class="text-transform"
                    color="success"
                    @click="exportCustomerAboutToExpire"
                    :loading="states.loading_export"
                >
                    <v-icon left>mdi-download</v-icon> Export
                </v-btn>
            </v-col>
        </v-row>
        <hr>
		<DataCustomer 
            v-bind:message="states.message"
            v-bind:loading="states.loading"
            v-bind:customers="states.customers"
        ></DataCustomer>
        <div class="text-center" v-if="states.filters.total_page > 1">
            <v-container>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            @input="nextPage"
                            v-model="states.filters.page"
                            class="my-4"
                            :length="states.filters.total_page"
                            :total-visible="7"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
	</div>
</template>
<script>
import { api } from "./../../../../helpers/const.js";
import DataCustomer from "./DataCustomer";
export default {
	name: "AboutToExpire",
    components: { 
        DataCustomer
    },
	data() {
		return {
            states: {
                list_time: [],
                loading: true,
                message: null,
                customers: [],
                loading_export: false,
                filters: {
                    type_customer: 'expired',
                    time: 1,
                    page: 1,
                    type_search: 'search',
                    total_page: 0
                }
            }
        }
    },
    methods: {
        nextPage(page) {
            this.states = {
                ...this.states,
                page: page,
                customers: [],
                loading: true
            }
            this.callApi();
        },
        updateKey(data) {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    id: data.id,
                    data_update: {
                        note: data.note,
                    }
                })
            };
            fetch(api.update_key_global, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.$swal({
                        title: result.message,
                        timer: 500,
                        icon: 'success'
                    })
                } else {
                    this.$swal({
                        title: result.message,
                        timer: 1000,
                        icon: 'error'
                    })
                }
            })
        },
        search() {
            this.states = {
                ...this.states,
                loading: true
            }
            this.callApi();
        },
        exportCustomerAboutToExpire() {
            this.states = {
                ...this.states,
                loading_export: true,
                filters: {
                    ...this.states.filters,
                    type_search: 'export'
                }
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.customer_about_to_expire, requestOptions)
            .then(res => res.blob())
            .then(
                (result) => {
                    this.states = {
                        ...this.states,
                        loading_export: false,
                        filters: {
                            ...this.states.filters,
                            type_search: 'search'
                        }
                    }
                    const date = new Date();
                    const date_export = date.getDate() + '/' + parseInt(date.getMonth() + 1) + '/' + date.getFullYear();
                    const url = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `customer_about_to_expire_${date_export}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            )
        },
        resetKey(data) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn đặt lại key?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: data.id
                        })
                    };
                    fetch(api.reset_key, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                timer: 1000,
                                icon: 'success'
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                timer: 1000,
                                icon: 'error'
                            })
                        }
                    })
                }
            })
        },
        blockKey(data, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn block key này?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: data.id,
                            day_number: this.states.filters.time,
                        })
                    };
                    fetch(api.block_key, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 2000
                            })
                            const customer_copy = [...this.states.customers];
                            customer_copy.splice(index, 1);
                            this.states = {
                                ...this.states,
                                customers: customer_copy
                            }
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    })
                }
                
            })
        },
        resendKey(data) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn gửi lại key này?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            email: data.customer.email,
                            product_id: data.product.id,
                            key: data.license
                        })
                    };
                    fetch(api.send_key_to_email, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 2000
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    })
                }
            })
        },
        deleteCustomer(id, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                            },
                            body: JSON.stringify({
                                id: id
                            })
                        };
                        fetch(api.remove_key, requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.success) {
                                this.$swal({
                                    title: result.message,
                                    timer: 1000,
                                    icon: 'success'
                                })
                                const customer_copy = [...this.states.customers];
                                customer_copy.splice(index, 1);
                                this.states = {
                                    ...this.states,
                                    customers: customer_copy
                                }
                            } else {
                                this.$swal({
                                    title: result.message,
                                    timer: 1000,
                                    icon: 'error'
                                })
                            }
                        })
                }
            })
            
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.customer_about_to_expire, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        ...this.states,
                        loading: false,
                        customers: result.customers,
                        filters: {
                            ...this.states.filters,
                            total_page: result.total_page,
                        },
                        list_time: result.list_time
                    }
                } else {
                    this.states = {
                        ...this.states,
                        loading: false,
                        message: result.message
                    }
                }
            })
        }
    },
    mounted() {
		this.callApi()
	}
}
</script>
