<template>
  <p>
      Error Page
  </p>
</template>

<script>
export default {
    name: 'Error'
}
</script>

<style>

</style>