<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
		<h3>
			Trạng thái thanh toán
		</h3>
		<br/>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
                style="padding-top: 0px; padding-bottom: 0px"
			>
                <h3 >Lọc dữ liệu</h3>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field
                    label="Từ"
                    type="date"
                    dense
                    outlined
                    v-model="states.filters.date_start"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field
                    label="Đến"
                    type="date"
                    dense
                    outlined
                    v-model="states.filters.date_end"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="2"
				sm="2"
				md="2">
                <v-autocomplete
                    hide-details
                    :items="states.status"
                    multiple
                    item-value="id"
                    item-text="name"
                    outlined
                    v-model="states.filters.status"
                    dense
                    label="Trạng thái trả"
                ></v-autocomplete>
            </v-col>
            <v-col cols="2"
				sm="3"
				md="3">
                <v-btn
                    class="text-transform"
                    color="primary"
                    @click="search"
                    style="margin-right: 10px"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="success"
                    @click="exportStatusPayment"
                    :loading="states.loading_export"
                >
                    <v-icon left>mdi-download</v-icon> Export
                </v-btn>
            </v-col>
        </v-row>
        <hr>
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table class="list-order">
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Đơn hàng</th>
                            <th class="text-left">
                                Thông tin thêm
                            </th>
                            <th class="text-left">
                                Khách hàng
                            </th>
                            <th class="text-left">
                                Quản lý
                            </th>
                            <th class="text-left">
                                Ghi chú
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="states.loading">
                            <td class="text-center" colspan="12">
                                <v-progress-circular
                                :size="50"
                                color="primary"
                                indeterminate
                                ></v-progress-circular>
                            </td>
                        </tr>
                        <tr v-if="states.message != null">
                            <td class="text-center" colspan="12">
                                <p>
                                    {{states.message}}
                                </p>
                                <v-btn
                                    :to="{name: 'list-order'}"
                                    large
                                    depressed
                                    color="error"
                                    style="margin-right: 7px"
                                    >
                                    <v-icon small left>mdi-reload</v-icon> Tải lại
                                </v-btn>
                            </td>
                        </tr>
                        <tr v-for="(item, index) in states.orders"
                            :key="index"
                            >
                            <td>
                                <p>
                                    Tạo đơn: <b>{{item.created_at}}</b>
                                </p>
                                <p>
                                    Duyệt đơn: <b>{{item.date_accept}}</b>
                                </p>
                            </td>
                            <td>
                                <p>
                                    Sản phẩm: <b>{{item.product.name}}</b>
                                </p>
                                <p>
                                    Số tiền: <b>{{Number(item.price * item.amount).toLocaleString()}}</b>
                                </p>
                                <p>
                                    Giảm giá: <b>{{Number(item.discount).toLocaleString()}}</b>
                                </p>
                                <p>
                                    Ngày thanh toán: <b>{{item.date_payment}}</b>
                                </p>
                                <p>
                                    Trạng thái thanh toán:
                                    <v-chip
                                        class="ma-2"
                                        v-if="item.status_payment"
                                        color="success"
                                    >
                                        Đã thanh toán
                                    </v-chip>
                                    <v-chip
                                        class="ma-2"
                                        v-if="!item.status_payment"
                                        color="error"
                                    >
                                        Chưa thanh toán
                                    </v-chip>
                                </p>
                            </td>
                            <td>
                                <p>
                                    {{item.full_name}}
                                </p>
                                <p>
                                    SĐT: <b>{{item.phone}}</b>
                                </p>
                                <p>
                                    Email: <b>{{item.email}}</b>
                                </p>
                                <p>
                                    Địa chỉ: <b>{{item.address}}</b>
                                </p>
                                <p>
                                    Địa phương: <b>{{item.province}}</b>
                                </p>
                                <p>
                                    Cổng ty: <b>{{item.company}}</b>
                                </p>
                                <p>
                                    MST: <b>{{item.tax_code}}</b>
                                </p>
                            </td>
                            <td>
                                <b>
                                    {{item.saler}}
                                </b>
                            </td>
                            <td>
                                {{item.note}}
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
			</v-col>
		</v-row>
        <div class="text-center" v-if="states.filters.total_page > 1">
            <v-container>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            @input="nextPage"
                            v-model="states.filters.page"
                            class="my-4"
                            :length="states.filters.total_page"
                            :total-visible="7"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
import {debounce} from './../../../../helpers/function.js'
import {convertNumber} from "./../../../../helpers/function.js";

export default {
	name: "StatusPayment",
	// components: { Breadcrumb },
	data() {
		return {
            menu: false,
            menu2: false,
			breadcrumbs: [
				{
					text: "Dashboard",
					disabled: false,
					href: "/admin"
				},
				{
					text: "Tạo đơn",
					disabled: false,
					href: "/admin/order/create"
				}
			],
            states: {
                loading_export: false,
                loading: true,
                status: [{name: 'Chưa trả', id: 0}, {name: 'Đã trả', id: 1}],
                types: ['All', 'VACC', 'VACE'],
                filters: {
                    key_search: '',
                    date_start: "",
                    date_end: "",
                    status: [],
                    type: '',
                    page: 1,
                    total_page: 0,
                    type_search: 'search'
                },
                orders: [],
            }
		};
	},
	methods: {
        changeStatusVaccVace(order, index, status) {
            const old_order = [...this.states.orders];
            old_order[index].status_vacc_vace = status;
            this.states = {
                ...this.states,
                orders: old_order
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    order_id: order.id,
                    status: status
                })
            };
            fetch(api.change_status_vacc_vace, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        icon: 'error',
                        title: result.message,
                        timer: 1000
                    });
                    old_order[index].status_vacc_vace = !status;
                    this.states = {
                        ...this.states,
                        orders: old_order
                    }
                }
            });
        },
        search() {
            const filters = {...this.states.filters};
            filters.page = 1;
            filters.total_page = 0;
            this.states = {
                ...this.states,
                filters: filters,
                orders: [],
                loading: true
            }
            this.callApi();
        },
        exportStatusPayment() {
            const filters = {...this.states.filters};
            filters.type_search = 'export';
            this.states = {
                ...this.states,
                filters: filters,
                loading_export: true
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.status_payment, requestOptions)
            .then(res => res.blob())
            .then(
                (result) => {
                    filters.type_search = 'search';
                    this.states = {
                        ...this.states,
                        loading_export: false,
                        filters: filters
                    }
                    const date = new Date();
                    const date_export = date.getDate() + '/' + parseInt(date.getMonth() + 1) + '/' + date.getFullYear();
                    const url = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `trang_thai_thanh_toan_${date_export}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            )
        },
        nextPage(page) {
            this.states = {
                ...this.states,
                page: page,
                orders: [],
                loading: true
            }
            this.callApi();
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.status_payment, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        ...this.states,
                        orders: result.datas,
                        filters: {
                            ...this.states.filters,
                            total_page: result.total_page
                        },
                        loading: false
                    }
                } else {
                    this.states = {
                        ...this.states,
                        message: result.message,
                        loading: false
                    }
                }
            });
        }
	},
	watch: {
		
	},
	mounted() {
		this.callApi()
	}
};
</script>

<style scoped>
table tr td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    white-space: nowrap;
}
</style>
