<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
		<h3>
			Sửa key - <b>{{state.data_edit.license}}</b>
		</h3>
		<br>
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <form @submit.prevent="onSubmitEditKey">
                    <v-row>
                        <v-col md="12">
                            <h3>Chỉnh sửa thông tin khách hàng đã kích hoạt (<b>{{state.data_edit.customer.name}}</b>)</h3>
                            <small>
                                <span style="color: red">Chú ý: </span> Nếu chuyển từ key thử nghiệm sang key thương mại thì sẽ phải quay lại phần đơn hàng để duyệt. Thông tin khách hàng đã kích hoạt vẫn được cập nhật bình thường.
                            </small>
                        </v-col>
                        <v-col md="4">
                            <v-text-field
                                required
                                label="Email"
                                outlined
                                v-model="state.data_edit.customer.email"
                                :error-messages="errors.email"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-text-field
                                required
                                label="Họ tên"
                                v-model="state.data_edit.customer.name"
                                outlined
                                :error-messages="errors.full_name"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-text-field
                                required
                                label="Số điện thoại"
                                v-model="state.data_edit.customer.phone"
                                outlined
                                :error-messages="errors.phone"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-select
                            required
                            :items="state.type_key"
                            v-model="state.data_edit.type_key.value"
                            :error-messages="errors.type_key"
                            item-text="name"
                            item-value="value"
                            label="Loại key"
                            outlined
                            dense
                            ></v-select>
                        </v-col>
                        <!-- <v-col md="4">
                            <v-select
                            required
                            :items="state.day_numbers"
                            v-model="state.data_edit.day_use"
                            item-text="name"
                            item-value="value"
                            label="Số ngày sử dụng"
                            outlined
                            dense
                            ></v-select>
                        </v-col> -->
                        <v-col md="4">
                            <v-text-field
                                type="date"
                                label="Ngày kích hoạt"
                                aria-disabled="true"
                                v-model="state.data_edit.date_active"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-text-field
                                type="date"
                                label="Ngày hết hạn"
                                v-model="state.data_edit.date_expire"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-text-field
                                required
                                label="Số lần có thể thay đổi máy (hardware_id)"
                                v-model="state.data_edit.number_change_key"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-text-field
                                required
                                label="Số lần đã đổi máy (hardware_id)"
                                v-model="state.data_edit.number_can_change_key"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <!-- <v-col md="4">
                            <v-text-field
                                required
                                label="Số bản được cài trên một máy"
                                v-model="state.data_edit.number_key_per_device"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col> -->
                        <v-col md="4">
                            <v-autocomplete
                                required
                                :items="state.products"
                                outlined
                                dense
                                item-text="name"
                                item-value="id"
                                label="Chọn sản phẩm *"
                                v-model="state.data_edit.product.id"
                            ></v-autocomplete>
                        </v-col>
                        <v-col md="4">
                            <v-autocomplete
                                :items="state.provinces"
                                outlined
                                required
                                dense
                                item-text="name"
                                item-value="id"
                                label="Tỉnh/TP *"
                                v-model="state.data_edit.customer.province_id"
                            ></v-autocomplete>
                        </v-col>
                        <v-col md="4">
                            <v-text-field
                                required
                                label="Địa chỉ"
                                v-model="state.data_edit.customer.address"
                                outlined
                                :error-messages="errors.phone"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col md="12">
                            <v-btn
                            depressed
                            :loading="loading"
                            x-large
                            color="primary"
                            type="submit"
                            style="width: 100%"
                            >
                                Xác nhận cập nhật
                            </v-btn>
                        </v-col>
                    </v-row>
                </form>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { api } from "../../../../helpers/const.js";
import Breadcrumb from "../../Shares/Breadcrumb.vue";
export default {
	name: "EditKey",
	// components: { Breadcrumb },
	data() {
		return {
			loading: false,
			breadcrumbs: [
				{
					text: "Dashboard",
					disabled: false,
					href: "/admin"
				},
				{
					text: "Tạo đơn",
					disabled: false,
					href: "/admin/order/create"
				}
			],
			errors: {},
            state: {
                type_key: [
					{
						'name': 'Key thương mại',
						'value': 1
					},
					{
						'name': 'Key thử nghiệm',
						'value': 0
					},
					{
						'name': 'Key lớp học',
						'value': 2
					}
				],
                data_edit: {},
                products: [],
                provinces: [],
                day_numbers: []
            }
		};
	},
	methods: {
		onSubmitEditKey() {
            this.loading = true;
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.state.data_edit)
            };
            fetch(api.update_key_actived, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {                    
                    this.$swal({
                        icon: 'success',
                        title: result.message,
                        timer: 2000,
                    });
                } else {
                    this.$swal({
                        icon: 'error',
                        title: result.message
                    });
                }
                this.loading = false;
            })
        }
	},
	watch: {

	},
	mounted() {
		const requestOptions = {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.$cookie.get("is_login")
			},
			body: JSON.stringify({
				id: this.$route.params.id
			})
		};
		fetch(api.info_key, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			if (result.success) {
                this.state = {
                    ...this.state,
                    data_edit: result.data,
                    products: result.products,
                    provinces: result.provinces,
                    day_numbers: result.day_numbers
                }
				// this.state = {
				// 	...this.state,
				// 	products: result.products,
				// 	data_edit: result.data_edit,
				// 	provinces: result.provinces,
				// 	product_types: result.product_types,
				// 	product_keys: result.product_keys,
				// 	method_learns: result.method_learns,
				// 	day_numbers: result.day_numbers,
				// 	product_bonus: result.product_bonus,
				// 	users: result.users
				// }
			} 			
		});
	}
};
</script>

<style scoped></style>
