<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
		<h3>
			Kết quả tìm kiếm
		</h3>       
        <br>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
			>
                <template>
                    <v-tabs v-model="tab">
                        <v-tab :key="'tab-1'">
                            Đơn hàng
                        </v-tab>
                        <v-tab :key="'tab-2'">
                            Đã kích hoạt
                        </v-tab>
                        <v-tab :key="'tab-3'">
                            Key
                        </v-tab>
                    </v-tabs>
                </template>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        :key="'tab-1'"
                    >
                        <v-card flat>
                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>Đơn hàng</th>
                                                <th>
                                                    Sản phẩm
                                                </th>
                                                <th>Nhân viên</th>
                                                <th>
                                                    Khách hàng
                                                </th>
                                                <th class="text-left">
                                                    Hành động
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="state.loading">
                                                <td class="text-center" colspan="12">
                                                    <v-progress-circular
                                                    :size="50"
                                                    color="primary"
                                                    indeterminate
                                                    ></v-progress-circular>
                                                </td>
                                            </tr>
                                            <template v-else>
                                                <tr v-if="state.message != ''">
                                                    <td colspan="6" align="center">
                                                        <p class="m-0">
                                                            {{state.message}}
                                                        </p>
                                                        <p>
                                                            <v-btn
                                                            elevation="2"
                                                            color="primary"
                                                            class="text-transform"
                                                            @click="refresh"
                                                            >
                                                                Tải lại
                                                            </v-btn>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr v-else-if="state.orders.length == 0">
                                                    <td colspan="6" align="center">
                                                        Dữ liệu trống
                                                    </td>
                                                </tr>
                                                <template v-else>
                                                    <tr v-for="(orderItem, index) in state.orders" :key="index">
                                                        <td>
                                                            <p>
                                                                {{orderItem.product.name}}
                                                            </p>
                                                            <p>
                                                                Tạo đơn: <b>{{orderItem.created_at}}</b>
                                                            </p>
                                                            <p>
                                                                Duyệt đơn: <b>{{orderItem.date_accept}}</b>
                                                            </p>
                                                            <p v-if="orderItem.key_sended != ''">
                                                                Key đã gửi: <b>{{orderItem.key_sended}}</b>
                                                            </p>
                                                            <p v-if="orderItem.bonus_product != ''">
                                                                Tặng kèm sản phẩm <b>{{orderItem.bonus_product}}</b>
                                                            </p>
                                                            <!-- <p>
                                                                Giảm giá: <b>{{Number(orderItem.discount).toLocaleString()}}</b>
                                                            </p>
                                                            <p>
                                                                Nợ: <b>{{Number(orderItem.owe).toLocaleString()}}</b> ({{orderItem.status_owe ? 'Chưa trả' : 'Đã trả'}})
                                                            </p> -->
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <b>{{orderItem.product.name}}</b>
                                                            </p>
                                                            <p>
                                                                Số tiền: <b>{{Number(orderItem.price * orderItem.amount).toLocaleString()}}</b>
                                                            </p>
                                                            <p>
                                                                Số lượng: <b>{{orderItem.amount}}</b>
                                                            </p>
                                                            <p>
                                                                <span v-bind:class="'label label-' + orderItem.product.color">{{orderItem.product.type}}</span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            {{orderItem.saler}}
                                                        </td>
                                                        <td style="width: 450px">
                                                            <p>
                                                                <b>{{orderItem.full_name}}</b>
                                                            </p>
                                                            <p>
                                                                Địa chỉ: {{orderItem.address}}, {{orderItem.province}}
                                                            </p>
                                                            <p>
                                                                Công ty: {{orderItem.company}}
                                                            </p>
                                                            <p>
                                                                MST: <b>{{orderItem.tax_code}}</b>
                                                            </p>
                                                            <v-row style="margin: -12px !important">
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="6"
                                                                >
                                                                    <v-text-field
                                                                        label="Số điện thoại"
                                                                        v-model="orderItem.phone"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="6"
                                                                >
                                                                    <v-text-field
                                                                        label="Email"
                                                                        hide-details
                                                                        v-model="orderItem.email"
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row style="margin: -12px !important">
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="12"
                                                                >
                                                                    <v-text-field
                                                                        hide-details
                                                                        label="Số tiền nợ"
                                                                        v-model="orderItem.owe"
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <!-- <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="6"
                                                                >
                                                                    <v-select
                                                                        dense
                                                                        v-model="orderItem.status_owe"
                                                                        :items="status_owe"
                                                                        item-text="name"
                                                                        item-value="id"
                                                                        label="Trạng thái nợ"
                                                                        hide-details
                                                                        outlined
                                                                    ></v-select>
                                                                </v-col> -->
                                                            </v-row>
                                                            <v-row style="margin: -12px !important">
                                                                <v-col
                                                                    v-if="orderItem.product.type_num == 3"
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="6"
                                                                >
                                                                    <v-text-field
                                                                        type="date"
                                                                        label="Ngày thanh toán"
                                                                        hide-details
                                                                        v-model="orderItem.date_payment"
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    v-if="orderItem.product.type_num == 3"
                                                                    sm="6"
                                                                >
                                                                    <v-text-field
                                                                        type="date"
                                                                        label="Ngày thi"
                                                                        hide-details
                                                                        v-model="orderItem.date_exam"
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="6"
                                                                >
                                                                    <v-select
                                                                        dense
                                                                        v-model="orderItem.user_id"
                                                                        :items="state.users"
                                                                        item-text="name"
                                                                        item-value="id"
                                                                        label="Nhân viên bán"
                                                                        hide-details
                                                                        outlined
                                                                    ></v-select>
                                                                </v-col>
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="6"
                                                                    sm="6"
                                                                >
                                                                    <v-text-field
                                                                        label="Ghi chú"
                                                                        v-model="orderItem.note"
                                                                        hide-details
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row v-if="orderItem.product.type_num == 3 && orderItem.product.vacc" style="margin: -12px !important">
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="12"
                                                                >
                                                                    <v-text-field
                                                                        type="date"
                                                                        label="Ngày thanh toán VACC"
                                                                        hide-details
                                                                        v-model="orderItem.date_payment_vacc"
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row v-if="orderItem.product.type_num == 3 && orderItem.product.vace" style="margin: -12px !important">
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="12"
                                                                >
                                                                    <v-text-field
                                                                        type="date"
                                                                        label="Ngày thanh toán VACE"
                                                                        hide-details
                                                                        v-model="orderItem.date_payment_vace"
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <br>
                                                            <v-btn
                                                                :loading="orderItem.loading"
                                                                class="text-transform"
                                                                color="primary"
                                                                @click="update(orderItem, index)"
                                                            >
                                                                Cập nhật
                                                            </v-btn>
                                                        </td>
                                                        <td align="center">
                                                            <p>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            dark
                                                                            depressed
                                                                            color="warning"
                                                                            style="margin-right: 7px"
                                                                            :to="{name: 'edit-order', params: {id: orderItem.id}}"
                                                                            >
                                                                            <v-icon small>mdi-pencil</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Sửa</span>
                                                                </v-tooltip>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            dark
                                                                            depressed
                                                                            color="error"
                                                                            style="margin-right: 7px"
                                                                            @click="deleteOrder(orderItem.id, index)"
                                                                            >
                                                                            <v-icon small>mdi-delete</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Xóa</span>
                                                                </v-tooltip>
                                                            </p>
                                                            <p>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            @click="redirectPrint(orderItem.id)"
                                                                            depressed
                                                                            color="secondary"
                                                                            style="margin-right: 7px"
                                                                            >
                                                                            <v-icon small>mdi-printer</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>In hóa đơn</span>
                                                                </v-tooltip>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            dark
                                                                            depressed
                                                                            color="success"
                                                                            style="margin-right: 7px"
                                                                            @click="resendEmail(orderItem)"
                                                                            >
                                                                            <v-icon small>mdi-email-outline</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Gửi lại mail</span>
                                                                </v-tooltip>
                                                            </p>  
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <div class="text-center" v-if="state.total_page_order > 1">
                                    <v-container>
                                        <v-row justify="center">
                                            <v-col cols="8">
                                                <v-container class="max-width">
                                                    <v-pagination
                                                    @input="nextPageOrder"
                                                    v-model="state.page_order"
                                                    class="my-4"
                                                    :length="state.total_page_order"
                                                    :total-visible="7"
                                                    ></v-pagination>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item
                        :key="'tab-2'"
                    >
                        <v-card flat>
                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>Đơn hàng</th>
                                                <th>
                                                    Sản phẩm
                                                </th>
                                                <th>Nhân viên</th>
                                                <th>
                                                    Khách hàng
                                                </th>
                                                <th>
                                                    Hành động
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="state.loading">
                                                <td class="text-center" colspan="12">
                                                    <v-progress-circular
                                                    :size="50"
                                                    color="primary"
                                                    indeterminate
                                                    ></v-progress-circular>
                                                </td>
                                            </tr>
                                            <template v-else>
                                                <tr v-if="state.message != ''">
                                                    <td colspan="6" align="center">
                                                        <p class="m-0">
                                                            {{state.message}}
                                                        </p>
                                                        <p>
                                                            <v-btn
                                                            elevation="2"
                                                            color="primary"
                                                            class="text-transform"
                                                            @click="refresh"
                                                            >
                                                                Tải lại
                                                            </v-btn>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr v-else-if="state.key_actives.length == 0">
                                                    <td colspan="6" align="center">
                                                        Dữ liệu trống
                                                    </td>
                                                </tr>
                                                <template v-else>
                                                    <tr v-for="(keyItem, index) in state.key_actives" :key="index">
                                                        <td>
                                                            <p>
                                                                Key: <b>{{keyItem.license}}</b>
                                                            </p>
                                                            <p>
                                                                Kích hoạt: <b>{{keyItem.date_active}}</b>
                                                            </p>
                                                            <p>
                                                                Hết hạn: <b>{{keyItem.date_expire}}</b>
                                                            </p>
                                                            <p>
                                                                Phần mềm: <b>{{keyItem.product.name}} - {{Number(keyItem.product.price).toLocaleString()}}</b>
                                                            </p>
                                                            <p>
                                                                Tạo đơn: <b>{{keyItem.updated_at}}</b>
                                                            </p>
                                                            <p>
                                                                Duyệt đơn: <b>{{keyItem.order.date_accept != undefined ? keyItem.order.date_accept : '...'}}</b>
                                                            </p>
                                                            <!-- <p>
                                                                Đơn giá: <b>{{keyItem.order.price != undefined ? Number(keyItem.order.price).toLocaleString() : ''}}</b>
                                                            </p> -->
                                                        </td>
                                                        <td>
                                                            <p>
                                                                Loại key: 
                                                                <b>
                                                                    {{keyItem.type_key.name}}
                                                                </b>
                                                            </p>
                                                            <p v-if="keyItem.order.price != undefined">
                                                                Số tiền: {{Number(keyItem.order.price * keyItem.order.amount).toLocaleString()}}
                                                            </p>
                                                            <!-- <p>
                                                                <span v-bind:class="'label label-' + keyItem.product.color">{{keyItem.product.type}}</span>
                                                            </p> -->
                                                            <p>
                                                                Đổi máy: <b>{{keyItem.number_change_key}} lần</b>
                                                            </p>
                                                            <p>
                                                                Đã dùng: <b>{{keyItem.number_can_change_key}} lần</b>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            {{keyItem.saler}}
                                                        </td>
                                                        <td style="width: 450px">
                                                            <p>
                                                                <b>{{keyItem.customer.name}}</b>
                                                            </p>
                                                            <p>
                                                                Địa chỉ: {{keyItem.order.address}}, {{keyItem.order.province}}
                                                            </p>
                                                            <p>
                                                                Công ty: {{keyItem.order.company}}
                                                            </p>
                                                            <p>
                                                                MST: <b>{{keyItem.order.tax_code}}</b>
                                                            </p>
                                                            <v-row style="margin: -12px !important">
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="6"
                                                                >
                                                                    <v-text-field
                                                                        label="Số điện thoại"
                                                                        v-model="keyItem.customer.phone"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="6"
                                                                >
                                                                    <v-text-field
                                                                        label="Email"
                                                                        hide-details
                                                                        v-model="keyItem.customer.email"
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row style="margin: -12px !important">
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="12"
                                                                    v-if="keyItem.order.owe != undefined"
                                                                >
                                                                    <v-text-field
                                                                        hide-details
                                                                        label="Số tiền nợ"
                                                                        v-model="keyItem.order.owe"
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="6"
                                                                    v-if="keyItem.order.status_owe != undefined"
                                                                >
                                                                    <v-select
                                                                        dense
                                                                        v-model="keyItem.order.status_owe"
                                                                        :items="status_owe"
                                                                        item-text="name"
                                                                        item-value="id"
                                                                        label="Trạng thái nợ"
                                                                        hide-details
                                                                        outlined
                                                                    ></v-select>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row style="margin: 10px -12px 0px -12px !important">
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="6"
                                                                >
                                                                    <v-text-field
                                                                        hide-details
                                                                        label="Ngày kích hoạt"
                                                                        type="date"
                                                                        v-model="keyItem.date_active"
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="6"
                                                                >
                                                                    <v-text-field
                                                                        hide-details
                                                                        label="Ngày hết hạn"
                                                                        v-model="keyItem.date_expire"
                                                                        type="date"
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row style="margin: -12px -12px !important">
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="6"
                                                                >
                                                                    <v-text-field
                                                                        hide-details
                                                                        type="number"
                                                                        label="Số lần đổi máy"
                                                                        v-model="keyItem.number_change_key"
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="6"
                                                                >
                                                                    <v-text-field
                                                                        hide-details
                                                                        v-model="keyItem.number_can_change_key"
                                                                        label="Số lần đã đổi"
                                                                        type="number"
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row style="margin: 0px -12px 0px -12px !important;">
                                                                <v-col
                                                                    class="d-flex"
                                                                    cols="12"
                                                                    sm="12"
                                                                >
                                                                    <v-text-field
                                                                        label="Ghi chú"
                                                                        v-model="keyItem.note"
                                                                        hide-details
                                                                        dense
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <br>
                                                            <v-btn
                                                                :loading="keyItem.loading"
                                                                class="text-transform"
                                                                color="primary"
                                                                @click="updateKeyActive(keyItem, index)"
                                                            >
                                                                Cập nhật
                                                            </v-btn>
                                                        </td>
                                                        <td align="center">
                                                            <!-- <p>
                                                                <v-btn
                                                                    :to="{name: 'edit-key', params: {id: keyItem.id}}"
                                                                    small
                                                                    depressed
                                                                    color="warning"
                                                                    style="margin-right: 7px"
                                                                    >
                                                                    <v-icon small>mdi-pencil</v-icon>
                                                                </v-btn>
                                                            </p> -->
                                                            <p>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            dark
                                                                            depressed
                                                                            color="warning"
                                                                            style="margin-right: 7px"
                                                                            :to="{name: 'edit-key', params: {id: keyItem.id}}"
                                                                            >
                                                                            <v-icon small>mdi-pencil</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Sửa/Gia hạn</span>
                                                                </v-tooltip>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            dark
                                                                            depressed
                                                                            color="error"
                                                                            style="margin-right: 7px"
                                                                            @click="deleteCustomer(keyItem.id, index)"
                                                                            >
                                                                            <v-icon small>mdi-delete</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Xóa</span>
                                                                </v-tooltip>
                                                            </p>
                                                            <p>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            dark
                                                                            depressed
                                                                            color="success"
                                                                            style="margin-right: 7px"
                                                                            @click="resendKey(keyItem)"
                                                                            >
                                                                            <v-icon small>mdi-email-outline</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Gửi lại mail</span>
                                                                </v-tooltip>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            dark
                                                                            depressed
                                                                            color="primary"
                                                                            style="margin-right: 7px"
                                                                            @click="blockKey(keyItem, index)"
                                                                            >
                                                                            <v-icon small>mdi-lock</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Block key</span>
                                                                </v-tooltip>
                                                            </p>
                                                            <p>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            depressed
                                                                            color="secondary"
                                                                            style="margin-right: 7px"
                                                                            @click="resetKey(keyItem)"
                                                                            >
                                                                            <v-icon small>mdi-restore</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Đặt lại key</span>
                                                                </v-tooltip>
                                                                <v-tooltip top v-if="keyItem.order.id != undefined">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            @click="printBill(keyItem.order)"
                                                                            depressed
                                                                            color="secondary"
                                                                            style="margin-right: 7px"
                                                                            >
                                                                            <v-icon small>mdi-printer</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>In hóa đơn</span>
                                                                </v-tooltip>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <div class="text-center" v-if="state.total_page_key_active > 1">
                                    <v-container>
                                        <v-row justify="center">
                                            <v-col cols="8">
                                                <v-container class="max-width">
                                                    <v-pagination
                                                    @input="nextPageKeyActive"
                                                    v-model="state.page_key_active"
                                                    class="my-4"
                                                    :length="state.total_page_key_active"
                                                    :total-visible="7"
                                                    ></v-pagination>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item
                        :key="'tab-3'"
                    >
                        <v-card flat>
                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>Mã License</th>
                                                <th>
                                                    Sản phẩm
                                                </th>
                                                <th>Loại key</th>
                                                <th>
                                                    Số ngày
                                                </th>
                                                <th>
                                                    Email
                                                </th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="state.loading">
                                                <td class="text-center" colspan="12">
                                                    <v-progress-circular
                                                    :size="50"
                                                    color="primary"
                                                    indeterminate
                                                    ></v-progress-circular>
                                                </td>
                                            </tr>
                                            <tr v-for="(keyItem, index) in state.key_by_searchs" :key="index">
                                                <td>
                                                    <span :style="keyItem.status.value == 2 ? 'color: #3699FF' : ''">
                                                        {{keyItem.license}}
                                                    </span>
                                                </td>
                                                <td>
                                                    {{keyItem.product.name}}
                                                </td>
                                                <td>
                                                    {{keyItem.type_key.name}}
                                                </td>
                                                <td>
                                                    {{keyItem.day_use}}
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        label="Email"
                                                        hide-details
                                                        v-model="keyItem.customer.email"
                                                        dense
                                                        outlined
                                                    ></v-text-field>
                                                    <br>
                                                    <v-btn
                                                        :loading="keyItem.loading"
                                                        class="text-transform"
                                                        color="primary"
                                                        @click="resendKey_v2(keyItem, index)"
                                                    >
                                                        Gửi lại
                                                    </v-btn>
                                                </td>
                                                <td>
                                                    <p>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    small
                                                                    dark
                                                                    depressed
                                                                    color="warning"
                                                                    style="margin-right: 7px"
                                                                    :to="{name: 'edit-key', params: {id: keyItem.id}}"
                                                                    >
                                                                    <v-icon small>mdi-pencil</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Sửa/Gia hạn</span>
                                                        </v-tooltip>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    small
                                                                    dark
                                                                    depressed
                                                                    color="error"
                                                                    style="margin-right: 7px"
                                                                    @click="deleteCustomer(keyItem.id, index)"
                                                                    >
                                                                    <v-icon small>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Xóa</span>
                                                        </v-tooltip>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <div class="text-center" v-if="state.total_page_key_by_search > 1">
                                    <v-container>
                                        <v-row justify="center">
                                            <v-col cols="8">
                                                <v-container class="max-width">
                                                    <v-pagination
                                                    @input="nextPageKeyBySearch"
                                                    v-model="state.page_key_by_search"
                                                    class="my-4"
                                                    :length="state.total_page_key_by_search"
                                                    :total-visible="7"
                                                    ></v-pagination>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
import {debounce} from './../../../../helpers/function.js'
import Breadcrumb from "./../../Shares/Breadcrumb.vue";
export default {
	name: "Search",
	// components: { Breadcrumb },
	data() {
		return {
            tab: null,
            status_owe: [
                {name: 'Chưa trả', id: 1},
                {name: 'Đã trả', id: 0}
            ],
            status_vacc: [
                {name: 'Chưa trả', id: 0},
                {name: 'Đã trả', id: 1}
            ],
            status_vace: [
                {name: 'Chưa trả', id: 0},
                {name: 'Đã trả', id: 1}
            ],
            breadcrumbs: [
				{
					text: "Dashboard",
					disabled: false,
					href: "/admin"
				},
				{
					text: "Tạo đơn",
					disabled: false,
					href: "/admin/order/create"
				}
			],
            state: {
                loading: true,
                orders: [],
                key_actives: [],
                key_by_searchs: [],
                key_in_actives: [],
                users: [],
                message: '',
                total_page_order: 1,
                total_page_key_active: 1,
                total_page_key_by_search: 1,
                page_order: 1,
                page_key_active: 1,
                page_key_by_search: 1,
            }
		};
	},
	methods: {
        blockKey(data, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn block key này?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: data.id,
                        })
                    };
                    fetch(api.block_key, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 2000
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    })
                }
                
            })
        },
        resendKey_v2(data, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn gửi lại key này?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const key_actives = [...this.state.key_actives];
                    key_actives[index].loading = true;
                    this.state = {
                        ...this.state,
                        key_actives: key_actives
                    }
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            email: data.customer.email,
                            product_id: data.product.id,
                            key: data.license
                        })
                    };
                    fetch(api.send_key_to_email, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 2000
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                        key_actives[index].loading = false;
                        this.state = {
                            ...this.state,
                            key_actives: key_actives
                        }
                    })
                } 
            })
        },
        resendEmail(data) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn gửi lại Email?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            order_id: data.id
                        })
                    };
                    fetch(api.resend_email, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 2000
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    })
                }
            })
        },
        resetKey(data) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn đặt lại key?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: data.id
                        })
                    };
                    fetch(api.reset_key, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                timer: 1000,
                                icon: 'success'
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                timer: 1000,
                                icon: 'error'
                            })
                        }
                    })
                }
            })
        },
        resendKey(data) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn gửi lại key này?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            email: data.customer.email,
                            product_id: data.product.id,
                            key: data.license
                        })
                    };
                    fetch(api.send_key_to_email, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 2000
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    })
                }
            })
        },
        deleteCustomer(id, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                            },
                            body: JSON.stringify({
                                id: id
                            })
                        };
                        fetch(api.remove_key, requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.success) {
                                this.$swal({
                                    title: result.message,
                                    timer: 1000,
                                    icon: 'success'
                                })
                                const key_actives = [...this.state.key_actives];
                                key_actives.splice(index, 1);
                                this.states = {
                                    ...this.states,
                                    key_actives: key_actives
                                }
                            } else {
                                this.$swal({
                                    title: result.message,
                                    timer: 1000,
                                    icon: 'error'
                                })
                            }
                        })
                }
            })
            
        },
        refresh() {

        },
        redirectPrint(id) {
            window.open('/bill-order/' + id);
        },
        nextPageOrder(page) {
            this.state = {
                ...this.state,
                loading: true,
                total_page_order: 1,
                page_order: page,
                orders: []
            }
            this.callApi(this.$route.query.query);
        },
        nextPageKeyBySearch(page) {
            this.state = {
                ...this.state,
                loading: true,
                total_page_key_by_search: 1,
                page_key_by_search: page,
                key_by_searchs: []
            }
            this.callApi(this.$route.query.query);
        },
        nextPageKeyActive(page) {
            this.state = {
                ...this.state,
                loading: true,
                total_page_key_active: 1,
                page_key_active: page,
                key_actives: []
            }
            this.callApi(this.$route.query.query);
        },
        updateKeyActive(keyItem, index) {
            const key_actives_copy = [...this.state.key_actives];
            key_actives_copy[index].loading = true;
            this.state = {
                ...this.state,
                key_actives: key_actives_copy
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    id: keyItem.id,
                    customer: {
                        customer_phone: keyItem.customer.phone,
                        customer_email: keyItem.customer.email,
                        note: keyItem.note,
                        date_active: keyItem.date_active,
                        expire_date: keyItem.date_expire,
                        number_change_key: keyItem.number_change_key,
                        number_can_change_key: keyItem.number_can_change_key
                    },
                    order: keyItem.order
                })
            };
            fetch(api.update_key_from_search, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.$swal({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    this.$swal({
                        title: result.message,
                        icon: 'error',
                        timer: 2000
                    })
                }
                key_actives_copy[index].loading = false;
                this.state = {
                    ...this.state,
                    key_actives: key_actives_copy
                }
            });
        },
        update(orderItem, index) {
            const order_copy = [...this.state.orders];
            order_copy[index].loading = true;
            this.state = {
                ...this.state,
                orders: order_copy
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    id: orderItem.id,
                    data_update: {
                        phone: orderItem.phone,
                        email: orderItem.email,
                        date_payment: orderItem.date_payment,
                        owe: orderItem.owe,
                        status_owe: orderItem.status_owe,
                        user_id: orderItem.user_id,
                        date_exam: orderItem.date_exam == '' ? null : orderItem.date_exam,
                        note: orderItem.note,
                        status_vacc: orderItem.status_vacc,
                        status_vace: orderItem.status_vace,
                        date_payment_vacc: orderItem.date_payment_vacc,
                        date_payment_vace: orderItem.date_payment_vace
                    }
                })
            };
            fetch(api.update_order_from_search, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.$swal({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    this.$swal({
                        title: result.message,
                        icon: 'error',
                        timer: 2000
                    })
                }
                order_copy[index].loading = false;
                this.state = {
                    ...this.state,
                    orders: order_copy
                }
            });
        },
        deleteOrder(id, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: id
                        })
                    };
                    fetch(api.delete_order, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            const copy = [...this.states.orders];
                            copy.splice(index, 1);
                            this.states = {
                                ...this.states,
                                orders: copy
                            }
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    });
                }
            })
        },
        callApi(query) {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login"),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: query,
                    page_order: this.state.page_order,
                    page_key_active: this.state.page_key_active,
                    page_key_by_search: this.state.page_key_by_search
                })
            };
            fetch(api.search_all, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.state = {
                        ...this.state,
                        orders: result.orders,
                        key_actives: result.key_actives,
                        key_by_searchs: result.key_by_searchs,
                        key_in_actives: result.key_in_actives,
                        users: result.users,
                        loading: false,
                        total_page_order: result.total_page_order,
                        total_page_key_active: result.total_page_key_active,
                        total_page_key_by_search: result.total_page_key_by_search
                    }
                } else {
                    this.state = {
                        ...this.state,
                        message: result.message,
                        loading: false
                    }
                }
            });
        }
	},
	watch: {
		'$route.query.query': function (query) {
            this.state = {
                ...this.state,
                loading: true,
                orders: []
            }
            this.callApi(query);
        }
	},
	mounted() {
        this.callApi(this.$route.query.query);
	}
};
</script>

<style scoped>
table tr td {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
</style>
