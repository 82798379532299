<template>
	<v-row class="body-login">
		<v-container>
			<v-row class="content-center">
				<v-col cols="12" sm="5">
						<v-form ref="form" v-model="valid" lazy-validation class="form-login">
							<h2>Quên mật khẩu</h2>
							<br>
							<v-text-field
							v-model="email"
							label="Nhập Email đăng nhập"
							:error-messages="errors.email"
							autocomplete="off"
							outlined
							name="email"
							required
							></v-text-field>
							<v-btn :loading="state.loading" color="success" class="mr-4" @click="submit">
								Gửi yêu cầu
							</v-btn>

							<v-btn color="error" class="mr-4" @click="reset"> Nhập lại</v-btn>
                            <br>
                            <br>
                            <p>
                                <router-link :to="'/'" class="a-none">
									Quay lại đăng nhập
								</router-link>
                            </p>
						</v-form>
				</v-col>
			</v-row>
		</v-container>
	</v-row>
</template>
<script>
import { api } from "./../../helpers/const.js";
export default {
	name: "ForgetPassword",
	data() {
		return {
			errors: { email: "", password: "" },
			state: {
				loading: false
			}
		};
	},
	methods: {
		reset() {
			this.$refs.form.reset();
		},
		submit() {
			this.state = {
				...this.state,
				loading: true
			}
			const email = this.email;
			const password = this.password;
			const requestOptions = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ email: email}),
			};
			fetch(api.forget_password, requestOptions)
				.then((response) => response.json())
				.then((result) => {
					if (result.success) {
						this.$swal({
                            icon: 'success',
                            title: result.message,
                            timer: 2000
                        });
					} else {
						if (result.type == "validate") {
							this.errors = result.warnings;
						} else if (result.type == "error") {
							this.$swal({
								icon: 'error',
								title: result.message,
								timer: 2000
							});
						}
                        this.state.loading = false;
					}
				});
		},
	}
};
</script>

<style scoped>
.body-login {
	margin: 0px !important;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)),
    url(https://gxd.edu.vn/frontend/images/bg_search1.jpg);
  background-size: cover;
  padding: 50px 0 40px;
  height: 100vh;
  display: flex;
  align-items: center;
}
.content-center {
  justify-content: center;
}

.form-login {
	background: #fff;
  width: 100%;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
  padding: 30px;
}
</style>
