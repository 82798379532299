<template>
<div>
<v-select
        dense
        v-model="user_id_selected"
        :items="users"
        item-text="name"
        @change="onChangeSaler(dataItem)"
        item-value="id"
        label="Nhân viên"
        hide-details
        outlined
    ></v-select>
</div>
    
</template>

<script>
import { api } from '../../helpers/const';
export default {
  props: ['users', 'user_id_selected', 'table', 'id', 'action'],
  methods: {
    onChangeSaler() {
      const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
        },
        body: JSON.stringify({
            id: this.id,
            table: this.table,
            data_update: {
                "user_id": this.user_id_selected
            },
            action: this.action
        })
      };
      fetch(api.update_all, requestOptions)
      .then((response) => response.json())
      .then((result) => {
          if (!result.success) {
              this.$swal({
                  icon: 'warning',
                  title: result.message,
                  timer: 1500,
              });
          }
      })
    }
  }
};
</script>
