<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
		<h3>
			Thống kê phải trả cho VACC
		</h3>
        
		<br/>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
                style="padding-top: 0px; padding-bottom: 0px"
			>
                <h3 >Lọc dữ liệu</h3>
            </v-col>
            <v-col
				cols="2"
				sm="2"
				md="2"
			>
                <v-text-field
                dense
                v-model="states.filters.key_search"
                label="Từ khóa tìm kiếm..."
                hide-details
                outlined
                ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field
                    label="Từ"
                    type="date"
                    dense
                    outlined
                    v-model="states.filters.date_start"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field
                    label="Đến"
                    type="date"
                    dense
                    outlined
                    v-model="states.filters.date_end"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="2"
				sm="2"
				md="2">
                <v-autocomplete
                    hide-details
                    :items="states.status"
                    item-value="id"
                    item-text="name"
                    outlined
                    v-model="states.filters.status"
                    dense
                    label="Trạng thái trả"
                ></v-autocomplete>
            </v-col>
            <v-col cols="2"
            sm="3"
            md="3">
                <v-radio-group
                style="margin-top: 0px"
                v-model="states.status_vacc_all"
                @change="updateStatusVaccVaceByRangeDate"
                row
                >
                    <v-radio
                        label="Xác nhận chưa trả"
                        value="0"
                    ></v-radio>
                    <v-radio
                        label="Xác nhận đã trả"
                        value="1"
                    ></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row class="bg-white" style="padding: 0px 10px 0px 10px;">
            <v-col cols="2"
				sm="12"
				md="12">
                <v-btn
                    class="text-transform"
                    color="primary"
                    @click="search"
                    style="margin-right: 10px"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="error"
                    @click="reload"
                    style="margin-right: 10px"
                >
                    <v-icon left>mdi-magnify</v-icon> Tải lại
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="success"
                    style="margin-right: 10px"
                    @click="exportVaccVace"
                    :loading="states.loading_export"
                >
                    <v-icon left>mdi-download</v-icon> Export
                </v-btn>
                <DialogExportDatePayment
                v-bind:type="'vacc'"
                ></DialogExportDatePayment>
            </v-col>
        </v-row>
        <hr>
		<DataOrderVaccVace
            v-bind:message="states.message"
            v-bind:loading="states.loading"
            v-bind:list_orders="states.orders"
            v-bind:type="type"
        >
        </DataOrderVaccVace>
        <div class="text-center" v-if="states.filters.total_page > 1">
            <v-container>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            @input="nextPage"
                            v-model="states.filters.page"
                            class="my-4"
                            :length="states.filters.total_page"
                            :total-visible="7"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
import {debounce} from './../../../../helpers/function.js'
import Breadcrumb from "./../../Shares/Breadcrumb.vue";
import {convertNumber} from "./../../../../helpers/function.js";
import DataOrderVaccVace from "./DataOrderVaccVace.vue";
import DialogExportDatePayment from "./DialogExportDatePayment.vue";

export default {
	name: "CreateOrder",
	components: { 
        DataOrderVaccVace,
        DialogExportDatePayment
    },
	data() {
		return {
            menu: false,
            menu2: false,
            type: "vacc",
			breadcrumbs: [
				{
					text: "Dashboard",
					disabled: false,
					href: "/admin"
				},
				{
					text: "Tạo đơn",
					disabled: false,
					href: "/admin/order/create"
				}
			],
            states: {
                status_vacc_all: null,
                loading_export: false,
                loading: true,
                status: [{name: 'Tất cả', id: -1}, {name: 'Chưa trả', id: 0}, {name: 'Đã trả', id: 1}],
                filters: {
                    key_search: '',
                    // date_start: new Date().getFullYear() + '-' + convertNumber(parseInt(1 + new Date().getMonth())) + '-01',
                    // date_end: new Date().getFullYear() + '-' + convertNumber(parseInt(1 + new Date().getMonth())) + '-' + convertNumber(new Date().getDate()),
                    date_start: "",
                    date_end: "",
                    status: -1,
                    page: 1,
                    total_page: 0,
                    type_search: 'search'
                },
                orders: [],
            }
		};
	},
	methods: {
        reload() {
            window.location.reload();
        },
        updateStatusVaccVaceByRangeDate() {
            this.$swal({
                title: 'Bạn có chắc muốn cập nhật trạng thái từ ' + this.states.filters.date_start + ' đến ' + this.states.filters.date_end,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            date_start: this.states.filters.date_start,
                            date_end: this.states.filters.date_end,
                            status: this.states.status_vacc_all,
                            type: 'vacc'
                        })
                    };
                    fetch(api.update_status_vacc_vace, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (!result.success) {
                            this.$swal({
                                icon: 'error',
                                title: result.message,
                                timer: 1000
                            });
                        } else {
                            this.$swal({
                                icon: 'success',
                                title: result.message,
                                timer: 1000
                            });
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                        }
                    })
                }
            })
            
        },
        changeStatusVaccVace(order, index, status) {
            const old_order = [...this.states.orders];
            old_order[index].status_vacc_vace = status;
            this.states = {
                ...this.states,
                orders: old_order
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    order_id: order.id,
                    status: status
                })
            };
            fetch(api.change_status_vacc_vace, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        icon: 'error',
                        title: result.message,
                        timer: 1000
                    });
                    old_order[index].status_vacc_vace = !status;
                    this.states = {
                        ...this.states,
                        orders: old_order
                    }
                }
            });
        },
        search() {
            const filters = {...this.states.filters};
            filters.page = 1;
            filters.total_page = 0;
            this.states = {
                ...this.states,
                filters: filters,
                orders: [],
                loading: true
            }
            this.callApi();
        },
        exportVaccVace() {
            const filters = {...this.states.filters};
            filters.type_search = 'export';
            this.states = {
                ...this.states,
                filters: filters,
                loading_export: true
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.vacc, requestOptions)
            .then(res => res.blob())
            .then(
                (result) => {
                    filters.type_search = 'search';
                    this.states = {
                        ...this.states,
                        loading_export: false,
                        filters: filters
                    }
                    const date = new Date();
                    const date_export = date.getDate() + '/' + parseInt(date.getMonth() + 1) + '/' + date.getFullYear();
                    const url = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `export_vacc_vace_${date_export}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            )
        },
        nextPage(page) {
            this.states = {
                ...this.states,
                page: page,
                orders: [],
                loading: true
            }
            this.callApi();
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.vacc, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        ...this.states,
                        orders: result.datas,
                        filters: {
                            ...this.states.filters,
                            total_page: result.total_page
                        },
                        loading: false
                    }
                } else {
                    this.states = {
                        ...this.states,
                        message: result.message,
                        loading: false
                    }
                }
            });
        }
	},
	watch: {
		
	},
	mounted() {
		this.callApi()
	}
};
</script>

<style scoped>
table tr td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    white-space: nowrap;
}
</style>
