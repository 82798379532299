<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
		<h3 class="m-b-10">
            Chi tiết đợt Import: {{state.name_upload}}
		</h3>
        <p class="m-b-0" style="margin-top: 10px">
            <router-link :to="{name: 'certificate-wait-approve'}">
                <v-btn
                    small
                    depressed
                    color="primary"
                    style="margin-right: 7px"
                    >
                    <v-icon left>mdi-arrow-left</v-icon>
                    Quay lại
                </v-btn>
            </router-link>
        </p>

		<br>
        <v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
                    <v-col
                        cols="2"
                        sm="3"
                        md="3"
                    >
                    <v-file-input
                        label="File input"
                        outlined
                        v-model="file_upload"
                        dense
                        :error-messages="errors.file"
                    ></v-file-input>
                    </v-col>
                    <v-col cols="2"
                        sm="1"
                        md="1">
                        <v-btn
                            class="text-transform"
                            color="primary"
                            @click="extraImport"
                        >
                            <v-icon left>mdi-send</v-icon> Xác nhận Import thêm
                        </v-btn>
                    </v-col>
                </v-row>
                <hr>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center">
                                    STT
                                </th>
                                <th class="text-left">
                                    Thông tin cá nhân
                                </th>
                                <th class="text-left">
                                    Lĩnh vực theo nghị định
                                </th>
                                <th>
                                    Lĩnh vực và hạng
                                </th>
                                <th>Trạng thái</th>
                                <th>Ngày thi</th>
                                <th class="text-left">
                                    Hành động
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="state.loading">
                                <td class="text-center" colspan="7">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-if="state.message != null">
                                <td class="text-center" colspan="6">
                                    {{state.message}} <br>
                                    <v-btn
                                        class="text-transform"
                                        depressed
                                        @click="reloadPage"
                                        color="primary"
                                    >
                                        Tải lại
                                    </v-btn>
                                </td>
                            </tr>
                            <tr v-for="(certificate, index) in state.certificates" :key="index">
                                <td align="center">
                                    {{index+1}}
                                </td>
                                <td>
                                    <p>
                                        <b>Tên</b>: {{certificate.customer.full_name}}
                                    </p>
                                    <p>
                                        <b>SĐT</b>: {{certificate.customer.phone}}
                                    </p>
                                    <p>
                                        <b>Địa chỉ: </b>{{certificate.customer.address}}
                                    </p>
                                    <p>
                                        <b>CMT: </b>{{certificate.customer.cmnd}}
                                    </p>
                                    <p class="m-b-0">
                                        <b>Trình độ: </b>{{certificate.customer.qualification}}
                                    </p>
                                </td>
                                <td style="width: 25%; padding-right: 20px !important">
                                    <v-autocomplete
                                        v-model="certificate.field_decree"
                                        :items="state.type_exam_list"
                                        outlined
                                        dense
                                        multiple
                                        label="Chọn lĩnh vực"
                                        chips
                                        small-chips
                                        @change="changeProduct"
                                    ></v-autocomplete>
                                    <v-btn
                                        small
                                        depressed
                                        color="primary"
                                        style="margin-right: 7px"
                                        @click="updateFieldDecree(certificate)"
                                        >
                                        Cập nhật
                                    </v-btn>
                                </td>
                                <td>
                                    <table class="table-type-exam">
                                        <tr v-for="(type_exam, index1) in certificate.type_exam_and_rank" :key="index1">
                                            <td>
                                                {{type_exam.type_exam}}
                                            </td>
                                            <td>
                                                {{type_exam.rank_exam}}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <span :class="certificate.status.class">
                                        {{certificate.status.name}}
                                    </span>
                                </td>
                                <td>
                                    <input @change="changeDateExam($event, certificate.id)" type="date" v-model="certificate.date_exam">
                                </td>
                                <td align="center"> 
                                    <router-link v-if="certificate.status.name == 'Chưa duyệt'" class="a-none" :to="{ name: 'certificate-import-edit', params: { id: certificate.id }}">
                                        <v-btn
                                            small
                                            depressed
                                            color="warning"
                                            style="margin-right: 7px"
                                            >
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                    </router-link>
                                    <v-btn
                                        small
                                        depressed
                                        color="success"
                                        style="margin-right: 7px"
                                        @click="acceptCertificate(index, certificate.id)"
                                        >
                                        <v-icon small>mdi-check</v-icon>
                                    </v-btn>
                                    <v-btn
                                        small
                                        depressed
                                        color="error"
                                        @click="deleteCertificate(index, certificate.id)"
                                        >
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>    
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { api } from "./../../../../../helpers/const.js";
import Breadcrumb from "./../../../Shares/Breadcrumb.vue";
export default {
	name: "DetailCertificate",
	// components: { Breadcrumb },
	data() {
		return {
            state: {
                name_upload: this.$route.params.name_upload,
                loading: true,
                message: null,
                certificates: [],
                type_exam_list: [],
                file_upload: []
            },
            errors: {
                name: '',
                file: '',
            }
		};
	},
	methods: {
        extraImport() {
            const formData = new FormData();
            formData.append('file', this.file_upload);
            formData.append('name', this.state.name_upload);
            const requestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: formData
            };
            fetch(api.import_extra_certificate, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.state = {
                        ...this.state,
                        certificates: result.datas
                    }
                    this.$swal({
                        title: result.message,
                        icon: 'success',
                        timer: 2000
                    })
                } else {
                    if (result.type == 'validate') {
                        this.errors = result.errors;
                    } else {
                        this.$swal({
                            title: result.message,
                            icon: 'error',
                            timer: 2000
                        })
                    }
                }
            });
        },
        changeDateExam(event, id) {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    date_exam: event.target.value,
                    id: id
                })
            };
            fetch(api.update_one_date_exam_certificate, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        title: result.message,
                        icon: 'error',
                        timer: 2000
                    })
                }
            });
        },
        updateFieldDecree(data) {
            if (data.field_decree.length == 0) {
                this.$swal({
                    title: 'Chưa chọn lĩnh vực theo nghị định',
                    icon: 'warning',
                    timer: 1500
                })
            } else {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                    },
                    body: JSON.stringify(data)
                };
                fetch(api.update_decree_in_certificate, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.success) {
                        this.$swal({
                            title: result.message,
                            icon: 'success',
                            timer: 1500
                        })
                    } else {
                        this.$swal({
                            title: result.message,
                            icon: 'error',
                            timer: 2000
                        })
                    }
                });
            }
        },
        deleteCertificate(index, id) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: id
                        })
                    };
                    fetch(api.delete_one_certificate_import, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            const new_array = [...this.state.certificates];
                            new_array.splice(index, 1);
                            this.state = {
                                ...this.state,
                                certificates: new_array
                            }
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    });
                }
            })
        },
        acceptCertificate(index, id) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn duyệt?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: id
                        })
                    };
                    fetch(api.accept_one_certificate_import, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            const new_array = [...this.state.certificates];
                            new_array.splice(index, 1);
                            this.state = {
                                ...this.state,
                                certificates: new_array
                            }
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    });
                }
            })
        },
        reloadPage() {
            this.states = {
                ...this.states,
                loading: true,
                message: null
            }
            this.callApi();
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    name_upload: this.state.name_upload
                })
            };
            fetch(api.certificate_import_detail, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.state = {
                        ...this.state,
                        loading: false,
                        certificates: result.datas,
                        type_exam_list: result.type_exam_list
                    }
                } else {
                    this.state = {
                        ...this.state,
                        message: result.message,
                        loading: false
                    }
                }
            });
        }
	},
	mounted() {
        this.callApi();
	}
};
</script>

<style scoped>
table tr td {
    padding: 20px 0px !important;
}
.table-type-exam {
    border-collapse: collapse;
}
.table-type-exam tr td {
    border: 1px solid #ccc;
    padding: 10px !important;
}
</style>
