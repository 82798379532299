<template>
  <button>
    <v-btn class="text-transform" color="warning" @click="state.is_open = true">
      <v-icon left>mdi-download</v-icon> Export thanh toán {{ type }}
    </v-btn>
    <v-dialog
      @click:outside="state.is_open = false"
      v-model="state.is_open"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Chọn ngày bắt đầu và kết thúc</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="1" sm="12" md="12">
                <v-text-field
                  label="Từ"
                  type="date"
                  v-model="state.date_start"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1" sm="12" md="12">
                <v-text-field
                  label="Đến"
                  type="date"
                  dense
                  outlined
                  v-model="state.date_end"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  class="text-transform"
                  color="primary"
                  @click="exportExcel"
                  :loading="state.is_loading"
                >
                  Xác nhận
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </button>
</template>

<script>
import { api } from "../../../../helpers/const";
export default {
  name: "DialogExportDatePayment",
  props: {
    type: null
  },
  data() {
    return {
      state: {
        orders: [],
        is_open: false,
        date_start: null,
        date_end: null,
        is_loading: false,
      },
    };
  },
  methods: {
    exportExcel() {
      this.state.is_loading = true;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$cookie.get("is_login"),
        },
        body: JSON.stringify({
          type: this.type,
          date_start: this.state.date_start,
          date_end: this.state.date_end
        }),
      };
      fetch(api.export_vacc_vace_by_date_payment, requestOptions)
        .then((res) => res.blob())
        .then((result) => {
          this.state.is_loading = false;
          const date = new Date();
          const date_export =
            date.getDate() +
            "/" +
            parseInt(date.getMonth() + 1) +
            "/" +
            date.getFullYear();
          const url = window.URL.createObjectURL(new Blob([result]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `export_vacc_vace_${date_export}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>
