<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
		<br>
		<h3>
			Cấu hình SMTP Email
		</h3>
		<br>
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
			<form @submit.prevent="onUpdateSmpt">
				<v-row>
					<v-col md="12">
						<h3>Thông cấu hình</h3>
					</v-col>
					<v-col md="4">
						<v-text-field
							label="Driver *"
							outlined
							required
                            v-model="state.data_api.driver"
							dense
						></v-text-field>
					</v-col>
                    <v-col md="4">
						<v-text-field
							label="Host *"
							outlined
							required
                            v-model="state.data_api.host"
							dense
						></v-text-field>
					</v-col>
                    <v-col md="4">
						<v-text-field
							label="Port *"
							outlined
							required
                            v-model="state.data_api.port"
							dense
						></v-text-field>
					</v-col>
                    <v-col md="4">
						<v-text-field
							label="From Email *"
							outlined
							required
                            v-model="state.data_api.from_email"
							dense
						></v-text-field>
					</v-col>
                    <v-col md="4">
						<v-text-field
							label="From Name *"
							outlined
							required
                            v-model="state.data_api.from_name"
							dense
						></v-text-field>
					</v-col>
                    <v-col md="4">
						<v-text-field
							label="Encryption *"
							outlined
							required
                            v-model="state.data_api.encryption"
							dense
						></v-text-field>
					</v-col>
                    <v-col md="4">
						<v-text-field
							label="Username *"
							outlined
							required
                            v-model="state.data_api.account"
							dense
						></v-text-field>
					</v-col>
                    <v-col md="4">
						<v-text-field
							label="Password *"
							outlined
							required
                            type="password"
                            v-model="state.data_api.password"
							dense
						></v-text-field>
					</v-col>
					<v-col md="12">
						<v-btn
						depressed
						x-large
						color="success"
						dark
						type="submit"
						style="width: 100%"
						:loading="state.loading_submit"
						>
							<v-icon left>mdi-send</v-icon> Cập nhật cấu hình
    					</v-btn>
					</v-col>
				</v-row>
			</form>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { api } from "./../../../../../helpers/const.js";
import Breadcrumb from "./../../../Shares/Breadcrumb.vue";
export default {
	name: "CreateOrder",
	data() {
		return {
			state: {
                loading_page: false,
                loading_submit: false,
                data_api: {}
            }
		};
	},
	methods: {
		onUpdateSmpt() {
            this.state = {
                ...this.state,
                loading_submit: true
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login"),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: this.state.data_api
                })
            };
            fetch(api.update_smpt_setting, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.$swal({
                        icon: 'success',
                        title: result.message,
                        timer: 1500,
                    });
                    this.state = {
                        ...this.state,
                        loading_submit: false,
                    }
                } else {
                    this.$swal({
                        icon: 'error',
                        title: result.message,
                        timer: 1500,
                    });
                    this.state = {
                        ...this.state,
                        loading_submit: false,
                    }
                }		
            });
        }
	},
	
	mounted() {
		const requestOptions = {
			method: "GET",
			headers: {
				'Authorization': 'Bearer ' + this.$cookie.get("is_login")
			}
		};
		fetch(api.get_smtp_setting, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			if (result.success) {
                this.state = {
                    ...this.state,
                    data_api: result.data,
                    loading_page: true
                }
			}			
		});
	}
};
</script>

<style scoped></style>
