<template>
	<div>
        <br>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
		<h3>
            Danh sách chứng chỉ đợi duyệt
		</h3>
		<br>
        <v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-btn
                v-if="selected.length > 1"
                @click="mergeCertificate"
                depressed
                color="primary"
                >
                Gộp danh sách
                </v-btn>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Chọn</th>
                                <th class="text-left">
                                    STT
                                </th>
                                <th class="text-left">
                                    Tên đợt Upload
                                </th>
                                <th class="text-left">
                                    Ngày upload
                                </th>
                                <th>
                                    Trạng thái
                                </th>
                                <th>
                                    Số lượng
                                </th>
                                <th>
                                    Ngày thi
                                </th>
                                <th class="text-left">
                                    Hành động
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="states.loading">
                                <td class="text-center" colspan="6">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-if="states.message != null">
                                <td class="text-center" colspan="6">
                                    {{states.message}} <br>
                                    <v-btn
                                        class="text-transform"
                                        depressed
                                        @click="reloadPage"
                                        color="primary"
                                    >
                                        Tải lại
                                    </v-btn>
                                </td>
                            </tr>
                            <tr v-for="(certificateItem, index) in states.certificates" :key="index">
                                <td>
                                    <v-checkbox
                                    v-model="selected"
                                    :value="certificateItem.name_upload"
                                    ></v-checkbox>
                                </td>
                                <td>
                                    {{index + 1}}
                                </td>
                                <td>
                                    {{certificateItem.name_upload}}
                                </td>
                                <td>
                                    {{certificateItem.date_upload}}
                                </td>
                                <td>
                                    <span :class="certificateItem.status.class">
                                        {{certificateItem.status.name}}
                                    </span>
                                </td>
                                <td>
                                    {{certificateItem.amount}}
                                </td>
                                <td>
                                    <input @change="changeDateExam($event, certificateItem.name_upload)" type="date" v-model="certificateItem.date_exam">
                                </td>
                                <td>
                                    <router-link class="a-none" :to="{ name: 'certificate-detail', params: { name_upload: certificateItem.name_upload }}">
                                        <v-btn
                                            small
                                            depressed
                                            color="primary"
                                            style="margin-right: 7px"
                                            >
                                            <v-icon small>mdi-eye</v-icon>
                                        </v-btn>
                                    </router-link>
                                    <v-btn
                                        small
                                        depressed
                                        color="success"
                                        style="margin-right: 7px"
                                        @click="acceptCertificate(index, certificateItem.name_upload)"
                                        >
                                        <v-icon small>mdi-check</v-icon>
                                    </v-btn>
                                    <v-btn
                                        small
                                        depressed
                                        @click="deleteCertificate(index, certificateItem.name_upload)"
                                        color="error"
                                        >
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
import Breadcrumb from "./../../Shares/Breadcrumb.vue";
export default {
	name: "ImportCertificate",
	// components: { Breadcrumb },
	data() {
		return {
            selected: [],
            status: -1,
            status_import: [{name: 'Tất cả', value: -1}, {name: 'Đã duyệt', value: 1}, {name: 'Chưa duyệt', value: 0}],
			loading: true,
            url_file_sample: api.url_file_sample,
			breadcrumbs: [
				{
					text: "Dashboard",
					disabled: false,
					href: "/admin"
				},
				{
					text: "Tạo đơn",
					disabled: false,
					href: "/admin/order/create"
				}
			],
            form: {
                name_upload: '',
                file_upload: []
            },
            key_search: '',
            states: {
                dialog: false,
                loading: true,
                message: null,
                certificates: [],
                provinces: []
            },
            errors: {
                name: '',
                file: '',
            }
		};
	},
	methods: {
        changeDateExam(event, name_upload) {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    name_upload: name_upload,
                    date_exam: event.target.value
                })
            };
            fetch(api.update_date_exam_certificate, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        title: result.message,
                        icon: 'error',
                        timer: 2000
                    })
                }
            });
        },
        deleteCertificate(index, name_upload) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            name_upload: name_upload
                        })
                    };
                    fetch(api.delete_certificate_import, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            const new_array = [...this.states.certificates];
                            new_array.splice(index, 1);
                            this.states = {
                                ...this.states,
                                certificates: new_array
                            }
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    });
                }
            })
        },
        acceptCertificate(index, name_upload) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn duyệt?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            name_upload: name_upload
                        })
                    };
                    fetch(api.accept_certificate_import, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                            const new_array = [...this.states.certificates];
                            new_array.splice(index, 1);
                            this.states = {
                                ...this.states,
                                certificates: new_array
                            }
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    });
                }
            })
        },
        mergeCertificate() {
            this.$swal({
                title: 'Bạn có chắc chắn muốn gộp?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            name_uploads: this.selected
                        })
                    };
                    fetch(api.merge_certificate, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                            location.reload();
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 1500
                            })
                        }
                    })
                }
            })
        },
        callApi() {
            const requestOptions = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                }
            };
            fetch(api.list_certificate_wait_accept, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        ...this.states,
                        loading: false,
                        certificates: result.datas,
                        provinces: result.provinces
                    }
                } else {
                    this.states = {
                        ...this.states,
                        message: result.message,
                        loading: false
                    }
                }
            });
        }
	},
	mounted() {
        this.callApi();
	}
};
</script>

<style scoped>

</style>
