<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
		<br>
		<h3>
			Sửa đơn hàng - {{state.data_edit.code}}<b></b>
		</h3>
		<br/>
		<v-row justify="left">
			<v-btn @click="$router.go(-1)" class="text-transform" color="primary" dark v-bind="attrs" v-on="on">
				<v-icon left>mdi-playlist-edit</v-icon> Danh sách đơn hàng
			</v-btn>
		</v-row>
		<br>
		<v-row v-if="state.loading_page">
			<v-progress-circular
			:size="50"
			color="primary"
			indeterminate
			></v-progress-circular>
		</v-row>
		<v-row v-if="!state.loading_page" class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
			<form @submit.prevent="onSubmitOrder">
				<v-row>
					<v-col md="12">
						<h3>Thông tin khách hàng</h3>
					</v-col>
					<v-col md="4">
						<v-text-field
							label="Email *"
							outlined
							v-model="state.data_edit.email"
							:error-messages="errors.email"
							dense
						></v-text-field>
						<!-- <v-autocomplete
							autocomplete="new-email"
							required
							v-model="state.data_edit"
							:items="list_emails"
							:search-input.sync="search"
							item-text="email"
							item-value="email"
							outlined
							dense
							return-object
							@change="changeEmail"
							label="Địa chỉ Email *"
						></v-autocomplete> -->
					</v-col>
					<v-col md="4">
						<v-text-field
							label="Họ tên *"
							outlined
							v-model="state.data_edit.full_name"
							:error-messages="errors.full_name"
							dense
						></v-text-field>
					</v-col>
					<v-col md="4">
						<v-text-field
							label="Số điện thoại *"
							outlined
							v-model="state.data_edit.phone"
							:error-messages="errors.phone"
							dense
						></v-text-field>
					</v-col>
					<v-col md="4">
						<v-autocomplete
							v-model="state.data_edit.province"
							:error-messages="errors.province"
							:items="state.provinces"
							item-value="id"
							item-text="name"
							required
							outlined
							return-object
							dense
							label="Tỉnh/TP *"
						></v-autocomplete>
					</v-col>
					<v-col md="4">
						<v-text-field
							v-model="state.data_edit.address"
							:error-messages="errors.address"
							label="Địa chỉ"
							outlined
							dense
						></v-text-field>
					</v-col>
					<v-col md="4">
						<v-text-field
							label="Ghi chú"
							outlined
							v-model="state.data_edit.note"
							dense
						></v-text-field>
					</v-col>
					<v-col md="4">
						<v-autocomplete
							v-model="state.data_edit.product"
							:error-messages="errors.product"
							:items="state.products"
							outlined
							dense
							item-text="name"
							item-value="product_id"
							return-object
							label="Chọn sản phẩm *"
							chips
            				small-chips
						></v-autocomplete>
					</v-col>
					<v-col md="4">
						<v-text-field
							label="Công ty"
							outlined
							v-model="state.data_edit.company"
							dense
						></v-text-field>
					</v-col>
					<v-col md="4">
						<v-text-field
							label="MST"
							v-model="state.data_edit.tax_code"
							outlined
							dense
						></v-text-field>
					</v-col>
					<v-col md="4">
						<v-autocomplete
							v-model="state.data_edit.user"
							:items="state.users"
							outlined
							dense
							item-text="name"
							item-value="id"
							return-object
							label="Chọn nhân viên bán *"
							chips
            				small-chips
						></v-autocomplete>
					</v-col>
				</v-row>
				<v-row v-if="state.data_edit.product.type == 3">
					<v-col md="4">
						<v-textarea
						outlined
						v-model="state.data_edit.decree"
						name="input-7-4"
						label="Lĩnh vực theo nghị định"
						hint="Mỗi lĩnh vực cách nhau bởi dấu ; và không được để dấu ; ở cuối"
						></v-textarea>
					</v-col>
					<v-col md="4">
						<v-textarea
						v-model="state.data_edit.type_exam"
						outlined
						name="input-7-4"
						label="Lĩnh vực sát hạch"
						hint="Mỗi lĩnh vực cách nhau bởi dấu ; và không được để dấu ; ở cuối"
						></v-textarea>
					</v-col>
					<v-col md="4">
						<v-textarea
						outlined
						v-model="state.data_edit.rank_exam"
						name="input-7-4"
						label="Hạng sát hạch"
						hint="Hạng sát hạch cách nhau bởi dấu ; và không được để dấu ; ở cuối"
						></v-textarea>
					</v-col>
					<p style="color: red">
						* Chú ý: (Mỗi nghị định, lĩnh vực, hạng cách nhau bằng dấu ; và không được đặt dấu ; ở cuối)
					</p>
				</v-row>
				<v-row>
					<v-col md="12" v-if="state.data_edit.status == 'in_active'">
						<v-simple-table>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">
											Phần mềm
										</th>
										<!-- <th class="text-left">
											Loại
										</th> -->
										<th class="text-left">
											Giá
										</th>
										<th class="text-left">
											Lựa chọn
										</th>
										<th class="text-left">Số lượng</th>
										<th class="text-left">Giảm</th>
										<th class="text-left">Nợ</th>
										<th class="text-center">Nhận</th>
									</tr>
								</thead>
								<tbody>
									<!-- <tr>
										<td class="text-right" style="font-size: 20px; font-weight: 500; background: #fb8c00;color: #fff;" colspan="8">
											{{Number(state.data_edit.product.price).toLocaleString()}}
										</td>
									</tr> -->
									<tr>
										<td>{{ state.data_edit.product.name }}</td>
										<!-- <td>{{ state.data_edit.product.type }}</td> -->
										<td>{{ Number(state.data_edit.product.price_root).toLocaleString() }}</td>
										<td>
											<v-select
												:items="state.day_numbers"
												item-text="name"
												item-value="value"
												label="Số ngày"
												@change="onChangeUseDayNumber(state.data_edit.product.use_day_number)"
												name="day_number"
												v-model="state.data_edit.product.use_day_number"
												v-if="state.data_edit.product.type == state.product_types.software"
												required
											>
											</v-select>
											<v-select
												:items="state.product_keys"
												item-text="name"
												item-value="value"
												label="Loại key"
												@change="onChangeTypeKey(state.data_edit.product.key_type)"
												v-model="state.data_edit.product.key_type"
												name="product_key"
												v-if="state.data_edit.product.type == state.product_types.software"
												required
											>
											</v-select>
											<v-select
												:items="state.method_learns"
												item-text="name"
												item-value="value"
												v-model="state.data_edit.product.method_learn"
												label="Phương thức học"
												name="method_learn"
												v-if="state.data_edit.product.type == state.product_types.course"
												required
											>
											</v-select>
											<v-select
												:items="state.product_bonus"
												item-text="name"
												item-value="product_id"
												label="Tặng key"
												v-model="state.data_edit.product.bonus_product_id"
												v-if="state.data_edit.product.type == state.product_types.course"
												required
											>
											</v-select>
										</td>
										<td>
											<v-text-field
												type="number"
												v-model="state.data_edit.product.amount"
											></v-text-field>
										</td>
										<td>
											<v-text-field
												type="number"
												value="0"
												v-model="state.data_edit.product.discount"
											></v-text-field>
										</td>
										<td>
											<v-text-field
												type="number"
												v-model="state.data_edit.product.owe"
												value="0"
											></v-text-field>
										</td>
										<td align="right">
											{{Number(state.data_edit.product.price * state.data_edit.product.amount - state.data_edit.product.owe - state.data_edit.product.discount).toLocaleString()}}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
				</v-row>
				<v-row>
					<v-col md="12">
						<v-btn
						depressed
						x-large
						color="success"
						dark
						type="submit"
						style="width: 100%"
						>
							<v-progress-circular v-if="loading == true" indeterminate small></v-progress-circular>
							<v-icon left v-if="loading == false">mdi-send</v-icon> <span v-if="loading == false">Cập nhật đơn hàng</span>
    					</v-btn>
					</v-col>
				</v-row>
			</form>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { api } from "../../../../../helpers/const.js";
import Breadcrumb from "../../../Shares/Breadcrumb.vue";
export default {
	name: "EditOrder",
	// components: { Breadcrumb },
	data() {
		return {
			loading: false,
			breadcrumbs: [
				{
					text: "Dashboard",
					disabled: false,
					href: "/admin"
				},
				{
					text: "Tạo đơn",
					disabled: false,
					href: "/admin/order/create"
				}
			],
			product_selected: [],
			emails: '',
			search: null,
			errors: {},
			state: {
				data_edit: {
					user: {},
					email: '',
					full_name: '',
					phone: '',
					address: '',
					company: '',
					tax_code: '',
					province: {},
					note: '',
					product: {}
				},
				products: [],
				provinces: [],
				product_types: {},
				product_keys: [],
				method_learns: [],
				day_numbers: [],
				product_bonus: [],
				users: [],
				loading_page: true
			}
		};
	},
	methods: {
		total(data) {
			return data.map(item => item.price * item.amount - item.owe - item.discount).reduce((prev, next) => prev + next);
		},
		onSubmitOrder() {
			this.state.data_edit['order_id'] = this.$route.params.id;
			this.errors = {};
			this.loading = true;
			const requestOptions = {
				method: "POST",
				headers: {
					'Authorization': 'Bearer ' + this.$cookie.get("is_login"),
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(this.state.data_edit)
			};
			fetch(api.update_order, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.success) {
					this.$swal({
						icon: 'success',
						title: result.message,
						timer: 2000,
					});
				} else {
					if (result.type == 'validate') {
						this.errors = result.errors
					} else if (result.type == 'error') {
						this.$swal({
							icon: 'error',
							title: result.message,
							timer: 2000,
						});
					}
				}
				this.loading = false
			});
		},

		onChangeUseDayNumber(day_number) {
			const data_edit = {...this.state.data_edit};

			if (day_number >= 365) {
				data_edit.product.key_type = 1;
				data_edit.product.price = data_edit.product.price_root;
			} else {
				data_edit.product.key_type = 0;
				data_edit.product.price = 0;
			}
			this.state = {
				...this.state,
				data_edit: data_edit
			}
		},
		
		onChangeTypeKey(type_key) {
			const data_edit = {...this.state.data_edit};

			if (type_key == 0) {
				data_edit.product.use_day_number = 7;
				data_edit.product.price = 0;
			} else {
				data_edit.product.use_day_number = 365;
				data_edit.product.price = data_edit.product.price_root;
			}
		}
	},
	watch: {

	},
	mounted() {
		const requestOptions = {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.$cookie.get("is_login")
			},
			body: JSON.stringify({
				id: this.$route.params.id
			})
		};
		fetch(api.get_data_order, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			if (result.success) {
				this.state = {
					...this.state,
					products: result.products,
					data_edit: result.data_edit,
					provinces: result.provinces,
					product_types: result.product_types,
					product_keys: result.product_keys,
					method_learns: result.method_learns,
					day_numbers: result.day_numbers,
					product_bonus: result.product_bonus,
					users: result.users,
					loading_page: false
				}
			} 			
		});
	}
};
</script>

<style scoped></style>
