<template>
    <div>
        <!-- <Breadcrumb :data="breadcrumbs" /> -->
        <br>
        <h3>
            Danh sách quyền
        </h3>
        <br/>
        <v-row justify="left">
			<v-dialog @click:outside="state = {
                                    ...state,
                                    dialog: false,
                                    role_id: '',
                                    role_name: '',
                                    role_display_name: '',
                                    role_description: '',
                                    index_edit: '',
                                    permission_selected: []
                                }" v-model="state.dialog" persistent max-width="800px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn class="text-transform" color="primary" dark v-bind="attrs" v-on="on">
						<v-icon left>mdi-plus</v-icon>Thêm mới
					</v-btn>
				</template>
				<v-card>
					<v-card-title>
						<span class="text-h5">Thông tin quyền</span>
					</v-card-title>
					<form @submit.prevent="onSubmitRole">
						<v-card-text>
							<v-container>
								<v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                            label="Tên quyền *"
                                            v-model="state.role_name"
                                            outlined
                                            :error-messages="state.errors.role_name"
                                            name="name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                            label="Tên quyền *"
                                            v-model="state.role_display_name"
                                            outlined
                                            :error-messages="state.errors.role_display_name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-autocomplete
                                            v-model="state.permission_selected"
                                            :disabled="isUpdating"
                                            :items="state.permissions"
                                            filled
                                            outlined
                                            label="Chọn quyền truy cập"
                                            item-text="name"
                                            item-value="id"
                                            multiple
                                        >
                                            <template v-slot:selection="data">
                                                <v-chip
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                                close
                                                @click="data.select"
                                                @click:close="remove(data.item)"
                                                >
                                                {{ data.item.name }}
                                                </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                                <template>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea
                                        v-model="state.role_description"
                                        outlined
                                        name="input-7-4"
                                        :error-messages="state.errors.role_description"
                                        label="Mô tả quyền"
                                        ></v-textarea>
                                    </v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="state = {
                                    ...state,
                                    dialog: false,
                                    role_id: '',
                                    role_name: '',
                                    role_display_name: '',
                                    role_description: '',
                                    index_edit: '',
                                    permission_selected: []
                                }"
							>
								Đóng
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								type="submit"
							>
								Lưu
							</v-btn>
						</v-card-actions>
					</form>
				</v-card>
			</v-dialog>
		</v-row>
        <br>
        <v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    STT
                                </th>
                                <th>Quyền</th>
                                <th class="text-left">
                                    Tên quyền
                                </th>
                                <th class="text-left">
                                    Mô tả
                                </th>
                                <th>
                                    Hành động
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="state.loading">
                                <td class="text-center" colspan="5">
                                    <v-progress-circular
                                    :size="40"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-for="(roleItem, index) in state.roles" :key="index">
                                <td>
                                    {{index + 1}}
                                </td>
                                <td>
                                    {{ roleItem.name }}
                                </td>
                                <td>
                                    {{ roleItem.display_name }}
                                </td>
                                <td>
                                    {{ roleItem.description }}
                                </td>
                                <td>
                                    <v-btn
                                        small
                                        depressed
                                        color="warning"
                                        style="margin-right: 7px"
                                        @click="state = {
                                            ...state,
                                            dialog: true,
                                            role_id: roleItem.id,
                                            role_name: roleItem.name,
                                            role_display_name: roleItem.display_name,
                                            role_description: roleItem.description,
                                            index_edit: index,
                                            permission_selected: roleItem.permissions,
                                            errors: {
                                                'role_name': '',
                                                'role_display_name': '',
                                                'role_description': ''
                                            }
                                        }"
                                        >
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                    
                                    <v-btn
                                        small
                                        depressed
                                        color="error"
                                        @click="deleteRole(roleItem.id, index)"
                                        >
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Breadcrumb from "./../../Shares/Breadcrumb.vue";
import { api } from "./../../../../helpers/const.js";

export default {
    name: "ListUser",
    // components: { Breadcrumb },
    data() {
        return {
            state: {
                'dialog': false,
                'role_name': '',
                'role_display_name': '',
                'role_description': '',
                'errors': {
                    'role_name': '',
                    'role_display_name': '',
                    'role_description': ''
                },
                'role_id': '',
                'index_edit': '',
                'roles': [],
                'loading': true,
                'permissions': [],
                'permission_selected': []
            },
            
        }
    },
    methods: {
        remove (item) {
            const index = this.state.permission_selected.indexOf(item.id)
            if (index >= 0) this.state.permission_selected.splice(index, 1)
        },
        deleteRole(role_id, index) {
            if (confirm('Bạn có chắc chắn muốn xóa?')) {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                    },
                    body: JSON.stringify({
                        'role_id': role_id
                    })
                };
                fetch(api.delete_role, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.success) {
                        const copy_state = [...this.state.roles];
                        copy_state.splice(index, 1);
                        this.state = {
                            ...this.state,
                            roles: copy_state
                        }
                        this.$swal({
                            icon: 'success',
                            title: result.message,
                            timer: 1000
                        });
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: result.message,
                            timer: 1000
                        });
                    }
                })
            }
            return false;
        },
        onSubmitRole() {
            //console.log(this.state.permission_selected);
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    'role_name': this.state.role_name,
                    'role_display_name': this.state.role_display_name,
                    'role_description': this.state.role_description,
                    'role_id': this.state.role_id,
                    'permissions': this.state.permission_selected
                })
            };
            fetch(api.create_role, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    if (this.state.role_id == '') {
                        this.state = {...this.state,
                                      dialog: false,
                                      roles: [...this.state.roles, result.role_latest]
                                    }
                    } else {
                        const copy_state = {...this.state};
                        copy_state.dialog = false;
                        copy_state.roles[this.state.index_edit] = {
                            'id': this.state.role_id,
                            'name': this.state.role_name,
                            'display_name': this.state.role_display_name,
                            'description': this.state.role_description,
                            'permissions': result.data
                        }
                        copy_state.role_name = '';
                        copy_state.role_display_name = '';
                        copy_state.role_description = '';
                        copy_state.role_id = '';
                        this.state = copy_state;
                    }
                    this.$swal({
                        icon: 'success',
                        title: result.message,
                        timer: 1000
                    });
                } else {
                    if (result.type == 'validate') {
                        this.state = {...this.state, errors: result.errors}
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: result.message,
                            timer: 1000
                        });
                    }
                    
                }
            })
        }
    },
    mounted() {
		const requestOptions = {
			method: "GET",
			headers: {
				'Authorization': 'Bearer ' + this.$cookie.get("is_login")
			}
		};
		fetch(api.list_role, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			if (result.success) {
				this.state = {
                    ...this.state,
                    roles: result.datas,
                    permissions: result.permissions,
                    loading: false
                }
            }
		});
	}
}
</script>

<style scoped>

</style>