<template>
	<div>
		<!-- <Breadcrumb :data="breadcrumbs" /> -->
		<br>
        <h3>
			Tổng hợp nhân viên
		</h3>
		<br/>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
                style="padding-top: 0px; padding-bottom: 0px"
			>
                <h3 >Lọc dữ liệu</h3>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field
                    label="Từ"
                    type="date"
                    dense
                    outlined
                    v-model="states.filters.date_start"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
                <v-text-field
                    label="Đến"
                    type="date"
                    dense
                    outlined
                    v-model="states.filters.date_end"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="2"
				sm="3"
				md="3">
                <v-autocomplete
                    chips
                    small-chips
                    :items="states.employees"
                    item-value="id"
                    item-text="name"
                    outlined
                    v-model="states.filters.employees"
                    dense
                    label="Nhân viên"
                ></v-autocomplete>
            </v-col>
            <v-col cols="2"
            sm="3"
            md="3">
                <v-radio-group
                style="margin-top: 0px"
                v-model="states.status_salary_all"
                @change="updateSalaryAll"
                row
                >
                    <v-radio
                        label="Chưa trả"
                        value="0"
                    ></v-radio>
                    <v-radio
                        label="Đã trả"
                        value="1"
                    ></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row class="bg-white" style="padding: 0px 10px 0px 10px;">
            <v-col cols="2"
				sm="3"
				md="3">
                <v-btn
                    class="text-transform"
                    color="primary"
                    @click="search"
                    style="margin-right: 10px"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="success"
                    @click="exportStatisticalAllEmployee"
                    :loading="states.loading_export"
                >
                    <v-icon left>mdi-download</v-icon> Export
                </v-btn>
            </v-col>
        </v-row>
        <hr>
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table class="list-order">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    STT
                                </th>
                                <th>Đơn hàng</th>
                                <th class="text-left">
                                    Khách hàng
                                </th>
                                <th class="text-left">
                                    Đơn giá
                                </th>
                                <th class="text-left">
                                    Giảm
                                </th>
                                <th class="text-left">
                                    Nhận
                                </th>
                                <th class="text-left">
                                    Tiền ra
                                </th>
                                <th>
                                    Hoa hồng
                                </th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="states.loading">
                                <td class="text-center" colspan="12">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-if="states.message != null">
                                <td class="text-center" colspan="13">
                                    <p>
                                        {{states.message}}
                                    </p>
                                    <v-btn
                                        :to="{name: 'list-order'}"
                                        large
                                        depressed
                                        color="error"
                                        style="margin-right: 7px"
                                        >
                                        <v-icon small left>mdi-reload</v-icon> Tải lại
                                    </v-btn>
                                </td>
                            </tr>
                            <tr v-if="states.orders.length > 0" style="background: rgb(251, 140, 0);color: #fff;">
                                <td colspan="3"></td>
                                <td>
                                    <b>{{Number(states.total_price).toLocaleString()}}</b>
                                </td>
                                <td>
                                    <b>{{Number(states.total_discount).toLocaleString()}}</b>
                                </td>
                                <td>
                                    <b>{{Number(states.total_receive).toLocaleString()}}</b>
                                </td>
                                <td>
                                    <b>{{Number(states.total_payment).toLocaleString()}}</b>
                                </td>
                                <td>
                                    <b>{{Number(states.total_commission).toLocaleString()}}</b>
                                </td>
                                <td></td>
                            </tr>
                            <tr v-for="(item, index) in states.orders"
                                :key="index"
                                >
                                <td>{{ index +  1 }}</td>
                                <td>
                                    <p>
                                        Sản phẩm: <b>{{item.product.name}}</b>
                                    </p>
                                    <p>
                                        Tạo đơn: <b>{{item.created_at}}</b>
                                    </p>
                                    <p>
                                        Duyệt đơn: <b>{{item.date_accept}}</b>
                                    </p>
                                    <p>
                                        Người tạo: <b>{{item.saler}}</b>
                                    </p>
                                    <p>
                                        Người duyệt: <b>{{item.user_accept}}</b>
                                    </p>
                                    <p>
                                        Mã đơn: <b>{{ item.code }}</b>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        <v-icon small left>mdi-email</v-icon> {{item.email}}
                                    </p>
                                    <p>
                                        <v-icon small left>mdi-account</v-icon>{{item.full_name}}
                                    </p>
                                    <p>
                                        <v-icon small left>mdi-phone</v-icon> {{item.phone}}
                                    </p>
                                </td>
                                <td>
                                    {{item.amount}}  x <b>{{Number(item.price).toLocaleString()}}</b>
                                </td>
                                <td>
                                    {{Number(item.discount).toLocaleString()}}
                                </td>
                                <td>
                                    <b>
                                        {{Number(item.price * item.amount - item.discount).toLocaleString()}}
                                    </b>
                                </td>
                                <td>
                                    <b>
                                        {{Number(item.payment * item.amount).toLocaleString()}}
                                    </b>
                                </td>
                                <td>
                                    <b>
                                        {{Number(commission(item)).toLocaleString()}}
                                    </b>
                                </td>
                                <td align="center">
                                    <p>
                                        <v-btn
                                            :to="{name: 'edit-order', params: {id: item.id}}"
                                            small
                                            depressed
                                            color="warning"
                                            style="margin-right: 7px"
                                            >
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn
                                            small
                                            depressed
                                            color="error"
                                            @click="deleteOrder(item.id, index)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </p>
                                    <p>
                                        <v-chip
                                            class="ma-2 cursor"
                                            :color="item.status_certificate ? 'primary' : 'error'"
                                            v-if="item.product.type_num == 3"
                                            @click="updateStatusSalaryAndCertificate(!item.status_certificate, 'certificate', item.id, index)"
                                        >
                                        Trả CC
                                        </v-chip>
                                        <v-chip
                                        class="ma-2 cursor"
                                        @click="updateStatusSalaryAndCertificate(!item.status_salary, 'salary', item.id, index)"
                                        :color="item.status_salary ? 'primary' : 'error'"
                                        >
                                        Lương
                                        </v-chip>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <div class="text-center" v-if="states.filters.total_page > 1">
                    <v-container>
                        <v-row justify="center">
                            <v-col cols="8">
                                <v-container class="max-width">
                                    <v-pagination
                                    @input="nextPage"
                                    v-model="states.filters.page"
                                    class="my-4"
                                    :length="states.filters.total_page"
                                    :total-visible="7"
                                    ></v-pagination>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
import {convertNumber} from "./../../../../helpers/function.js";
import {debounce} from './../../../../helpers/function.js'
import Breadcrumb from "./../../Shares/Breadcrumb.vue";
export default {
	name: "DetailEmployee",
	// components: { Breadcrumb },
	data() {
		return {
            states: {
                status_salary_all: null,
                total_discount: 0,
                total_price: 0,
                total_commission: 0,
                total_receive: 0,
                total_payment: 0,
                products: [],
                employees: [],
                loading_export: false,
                loading: true,
                status: [{name: 'Tất cả', id: -1}, {name: 'Chưa trả', id: 0}, {name: 'Đã trả', id: 1}],
                types: ['All', 'VACC', 'VACE'],
                filters: {
                    date_start: "",
                    date_end: "",
                    status: '',
                    type: '',
                    page: 1,
                    total_page: 0,
                    type_search: 'search',
                    employees: -1,
                    product_type: -1
                },
                orders: [],
                data_products: []
            }
		};
	},
	methods: {
        updateSalaryAll() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    data_update: {
                        status_salary: this.states.status_salary_all,
                        date_start: this.states.filters.date_start,
                        date_end: this.states.filters.date_end,
                        user_id: this.states.filters.employees
                    }
                })
            };
            fetch(api.update_order_global, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (!result.success) {
                    this.$swal({
                        icon: 'error',
                        title: result.message,
                        timer: 2000,
                    });
                } else {
                    this.$swal({
                        icon: 'success',
                        title: result.message,
                        timer: 2000,
                    });
                }
            });
        },
        updateStatusSalaryAndCertificate(status, name, order_id, index) {
            var data_update = {};
            if (name == 'salary') {
                data_update = {
                    status_salary: status,
                };
            } else if (name == 'certificate') {
                data_update = {
                    status_certificate: status
                };
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    order_id: order_id,
                    data_update: data_update
                })
            };
            fetch(api.update_order_global, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    const old_orders = [...this.states.orders];
                    old_orders[index]['status_' + name] = status;
                    this.states = {
                        ...this.states,
                        orders: old_orders
                    }
                }
            });
        },
        commission(item) {
            return item.commission/100 * (item.price * item.amount - item.discount - item.payment * item.amount);
        },
        total(data) {
			return {
                total_price: data.map(item => item.total_price).reduce((prev, next) => prev + next),
                total_payment: data.map(item => item.total_payment).reduce((prev, next) => prev + next),
                total_discount: data.map(item => item.total_discount).reduce((prev, next) => prev + next),
                total_commission: data.map(item => item.total_commission).reduce((prev, next) => prev + next),
            };
		},
        search() {
            const filters = {...this.states.filters};
            filters.page = 1;
            filters.total_page = 0;
            this.states = {
                ...this.states,
                filters: filters,
                orders: [],
                status_salary_all: null,
                loading: true
            }
            this.callApi();
        },
        exportStatisticalAllEmployee() {
            const filters = {...this.states.filters};
            filters.type_search = 'export';
            this.states = {
                ...this.states,
                filters: filters,
                loading_export: true,
                status_salary_all: null
            }
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.statistical_employee_detail, requestOptions)
            .then(res => res.blob())
            .then(
                (result) => {
                    filters.type_search = 'search';
                    this.states = {
                        ...this.states,
                        loading_export: false,
                        filters: filters
                    }
                    const date = new Date();
                    const date_export = date.getDate() + '/' + parseInt(date.getMonth() + 1) + '/' + date.getFullYear();
                    const url = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `employee_detail_${date_export}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            )
        },
        toggle () {
            this.$nextTick(() => {
                if (this.likesAllEmployee) {
                    this.states.filters.employees = []
                } else {
                    const data = [];
                    this.states.employees.map((value) => {
                        data.push(value.id);
                    })
                    this.states.filters.employees = data; //this.states.employees.slice()
                }
            })
        },
        toggleProduct() {
            this.$nextTick(() => {
                if (this.likesAllProduct) {
                    this.states.filters.products = []
                } else {
                    const data = [];
                    this.states.products.map((value) => {
                        data.push(value.id);
                    })
                    this.states.filters.products = data; //this.states.products.slice()
                }
            })
        },
        nextPage(page) {
            this.states = {
                ...this.states,
                page: page,
                orders: [],
                loading: true
            }
            this.callApi();
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.statistical_employee_detail, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        ...this.states,
                        products: result.products,
                        employees: result.employees,
                        filters: {
                            ...this.states.filters,
                            total_page: result.total_page
                        },
                        orders: result.orders,
                        loading: false,
                        total_price: result.total_price,
                        total_receive: result.total_receive,
                        total_discount: result.total_discount,
                        total_commission: result.total_commission,
                        total_payment: result.total_payment,
                        data_products: result.data_products
                    }
                } else {
                    this.states = {
                        ...this.states,
                        message: result.message,
                        loading: false
                    }
                }
            });
        }
	},
    computed: {
      likesAllEmployee () {
        return this.states.filters.employees.length === this.states.employees.length;
      },
      likesSomeEmployee () {
        return this.states.filters.employees.length > 0 && !this.likesAllEmployee;
      },
      likesAllProduct () {
        return this.states.filters.products.length === this.states.products.length;
      },
        likesSomeProduct () {
        return this.states.filters.products.length > 0 && !this.likesAllProduct;
      },
      icon () {
        if (this.likesAllEmployee) return 'mdi-close-box'
        if (this.likesSomeEmployee) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      iconProduct() {
          if (this.likesAllProduct) return 'mdi-close-box'
            if (this.likesSomeProduct) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
      }
    },
	watch: {
		
	},
	mounted() {
		this.callApi()
	}
};
</script>

<style scoped>
table tr td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    white-space: nowrap;
}
</style>
