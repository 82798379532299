<template>
	<div>
        <br>
		<h3>
            Danh sách key đã gửi qua Email
		</h3>
		<br/>
        <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
                style="padding-top: 0px; padding-bottom: 0px"
			>
            <h3 >Tìm kiếm key</h3>
            </v-col>
            <v-col
				cols="6"
				sm="3"
				md="3"
			>
                <v-text-field
                dense
                v-model="filter.key_search"
                label="Từ khóa tìm kiếm..."
                outlined
                ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2" md="2">
                <v-select
                    outlined
                    dense
                    v-model="filter.type_key"
                    :items="states.type_key_search"
                    item-text="name"
                    item-value="value"
                    label="Loại key"
                ></v-select>
            </v-col>
            <v-col cols="6"
				sm="2"
				md="2">
                <!-- <v-select
                    outlined
                    dense
                    v-model="filter.product_id"
                    :items="states.products"
                    item-text="name"
                    item-value="id"
                    label="Phần mềm"
                ></v-select> -->
                <v-autocomplete
                    v-model="filter.product"
                    :items="states.products"
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    label="Sản phẩm"
                    chips
                    small-chips
                ></v-autocomplete>
            </v-col>
            <v-col cols="12"
				sm="3"
				md="3">
                <v-btn
                    style="margin-right: 10px"
                    class="text-transform"
                    color="primary"
                    @click="submitForm"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="error"
                    @click="resetForm"
                >
                    <v-icon left>mdi-reload</v-icon> Reset
                </v-btn>
            </v-col>
            <v-col cols="1"
				sm="1"
				md="1">
            </v-col>
         </v-row>
         <hr>
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    STT
                                </th>
                                <th class="text-left">
                                    License
                                </th>
                                <th>Số ngày</th>
                                <th>SĐT</th>
                                <th>Trạng thái</th>
                                <th>Tình trạng</th>
                                <th>Gửi key</th>
                                <th class="text-left">
                                    Hành động
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="states.loading">
                                <td class="text-center" colspan="12">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-for="(license, index) in states.licenses" :key="index">
                                <td>
                                    {{index + 1}}
                                </td>
                                <td>
                                    <p>
                                        Key: <b>{{license.license}}</b>
                                    </p>
                                    <p>
                                        Hardware Id: <b>{{license.hardware_id}}</b>
                                    </p>
                                    <p>
                                        Phần mềm: <b>{{license.product.name}}</b>
                                    </p>
                                    <p>
                                        <v-chip
                                        class="cursor"
                                        style="margin-right: 10px" 
                                        :color="license.type_key.value ? 'primary' : 'error'"

                                    >
                                        {{license.type_key.name}}
                                    </v-chip>
                                    </p>
                                </td>
                                <td>
                                    {{license.day_use}}
                                </td>
                                <td>
                                    {{license.customer.phone}}
                                </td>
                                <td style="white-space: nowrap">
                                    <v-chip
                                        class="cursor"
                                        style="margin-right: 10px" 
                                        :color="(license.status.value === 1 || license.status === 2) ? 'primary' : 'error'"

                                    >
                                        {{license.status.name}}
                                    </v-chip>
                                </td>
                                <td style="white-space: nowrap">
                                    <v-chip
                                        class="cursor"
                                        style="margin-right: 10px" 
                                        :color="license.status_active.value === 2 ? 'primary' : 'error'"
                                    >
                                        {{license.status_active.name}}
                                    </v-chip>
                                </td>
                                <td style="width: 250px">
                                    <v-text-field
                                    dense
                                    v-model="license.email_resend"
                                    label="Email"
                                    outlined
                                    ></v-text-field>
                                    <v-btn
                                        style="margin-right: 10px"
                                        class="text-transform"
                                        color="primary"
                                        @click="sendKeyToMail(license, index)"
                                        :loading="license.loading"
                                    >
                                        Gửi lại
                                    </v-btn>
                                </td>
                                <td style="white-space: nowrap">
                                    <v-btn
                                        small
                                        depressed
                                        color="error"
                                        @click="deleteKey(license, index)"
                                        >
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
			</v-col>
		</v-row>
        <div class="text-center" v-if="states.total_page > 1">
            <v-container>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            @input="nextPage"
                            v-model="page"
                            class="my-4"
                            :length="states.total_page"
                            :total-visible="7"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
export default {
	name: "ListKeySendedToday",
	data() {
		return {
            page: 1,
            states: {
                dialog_edit: false,
                dialog: false,
                products: [],
                type_keys: [],
                type_key_search: [],
                day_numbers: [],
                loading: true,
                message: null,
                licenses: [],
                total_page: 0,
            },
            filter: {
                key_search: '',
                type_key: '-1',
                product_id: -1,
                page_name: 'sended_email',
                page: 1,
                status_key: -1
            },
		};
	},
	methods: {
        deleteKey(license, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: license.id
                        })
                    };
                    fetch(api.delete_key, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            const licenses = [...this.states.licenses];
                            licenses.splice(index, 1);
                            this.states = {
                                ...this.states,
                                licenses: licenses
                            }
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 2000
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    })
                }
            })
        },
        nextPage(page) {
            this.filter['page'] = page;
            this.states = {
                ...this.states,
                licenses: [],
                loading: true
            }
            this.callApi();
        },
        resetForm() {
            this.filter = {
                ...this.filter,
                key_search: '',
                type_key: '-1',
                product: [],
                status_key: -1
            }
            this.states = {
                ...this.states,
                licenses: [],
                loading: true
            }
            this.callApi();
        },
        submitForm() {
            this.states = {
                ...this.states,
                loading: true,
                licenses: []
            }
            this.filter = {
                ...this.filter,
                page: 1
            }
            this.callApi();
        },
        sendKeyToMail(key, index) {
            const license_copy = [...this.states.licenses];
            license_copy[index].loading = true;
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    email: key.email_resend,
                    product_id: key.product.id,
                    key: key.license
                })
            };
            fetch(api.send_key_to_email, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.$swal({
                        title: result.message,
                        icon: 'success',
                        timer: 2000
                    })
                    this.states = {
                        ...this.states,
                    }
                    // this.status_queue = !this.status_queue
                    setTimeout(() => {
                        fetch(api.run_queue);
                    }, 1500);
                } else {
                    this.$swal({
                        title: result.message,
                        icon: 'error',
                        timer: 2000
                    })
                }
                license_copy[index].loading = false;
                this.states = {
                    ...this.states,
                    licenses: license_copy,
                }
            })
        },
		onSubmitKey() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.form_search)
            };
            fetch(api.create_key, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        ...this.states,
                        dialog: false,
                        total_key: parseInt(this.states.total_key) + parseInt(this.form_search.amount)
                    }
                    this.$swal({
                        title: result.message,
                        icon: 'success',
                        timer: 2000
                    })
                } else {
                    if (result.type == 'validate') {
                        this.errors = result.errors;
                    } else {
                        this.$swal({
                            title: result.message,
                            icon: 'error',
                            timer: 2000
                        })
                    }
                }
            });
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.filter)
            };
            fetch(api.filter_key_global, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    const obj = {name: 'Tất cả', value: -1};
                    const type_key_search = [obj, ...result.type_keys];
                    this.states = {
                        ...this.states,
                        products: result.products,
                        loading: false,
                        type_keys: result.type_keys,
                        licenses: result.licenses,
                        type_key_search: type_key_search,
                        total_page: result.total_page
                    }
                } else {
                    this.states = {
                        ...this.states,
                        message: result.message,
                        loading: false,
                    }
                }
            });
        }
	},
	mounted() {
        this.callApi();
	},
};
</script>

<style scoped>
table tr td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}
</style>
