<template>
	<div>
        <br>
		<h3>
            Danh sách key
		</h3>
		<br/>
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>
                                    STT
                                </th>
                                <th>
                                    Phần mềm
                                </th>
                                <th>
                                    Key thử nghiệm
                                </th>
                                <th>
                                    Key thương mại
                                </th>
                                <th>
                                    Key lớp học
                                </th>
                                <th>
                                    Chưa gửi
                                </th>
                                <th>
                                    Đã gửi chưa kích hoạt
                                </th>
                                <th>Đã kích hoạt</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="state.loading">
                                <td class="text-center" colspan="5">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-for="(productItem, index) in state.products" :key="index">
                                <td>
                                    {{index + 1}}
                                </td>
                                <td>
                                    {{productItem.name}}
                                </td>
                                <td>
                                    {{Number(productItem.total_key_free).toLocaleString()}}
                                </td>
                                <td>
                                    {{Number(productItem.total_key_sale).toLocaleString()}}
                                </td>
                                <td>
                                    {{Number(productItem.total_key_class).toLocaleString()}}
                                </td>
                                <td>
                                    {{Number(productItem.total_key_null).toLocaleString()}}
                                </td>
                                <td>
                                    {{Number(productItem.total_key_sended).toLocaleString()}}
                                </td>
                                <td>
                                    {{Number(productItem.total_key_active).toLocaleString()}}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
export default {
	name: "DetailKey",
	data() {
		return {
            state: {
                products: [],
                loading: true
            }
		};
	},
	methods: {
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.filter)
            };
            fetch(api.get_key_by_product, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.state = {
                        ...this.state,
                        loading: false,
                        products: result.products
                    }
                }
            });
        }
	},
	mounted() {
        this.callApi();
	}
};
</script>

<style scoped>
table tr td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}
</style>
