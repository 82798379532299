<template>
    <div>
		<br>
        <h3>
			Danh sách khách hàng chưa kích hoạt
		</h3>
		<br/>
        <!-- <v-row class="bg-white" style="padding: 20px 10px 0px 10px;">
            <v-col
				cols="12"
				sm="12"
				md="12"
                style="padding-top: 0px; padding-bottom: 0px"
			>
                <h3 >Lọc dữ liệu</h3>
            </v-col>
        </v-row> -->
        <!-- <v-row class="bg-white" style="padding: 0px 10px 0px 10px;">
            <v-col cols="2"
				sm="3"
				md="3">
                <v-btn
                    class="text-transform"
                    color="primary"
                    @click="search"
                    style="margin-right: 10px"
                >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
                <v-btn
                    class="text-transform"
                    color="success"
                    @click="exportStatisticalAllEmployee"
                    :loading="states.loading_export"
                >
                    <v-icon left>mdi-download</v-icon> Export
                </v-btn>
            </v-col>
        </v-row>
        <hr> -->
		<v-row class="bg-white">
			<v-col
				cols="12"
				sm="12"
				md="12"
			>
                <v-simple-table class="list-order">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    STT
                                </th>
                                <th>Sản phẩm</th>
                                <th class="text-left">
                                    Loại key
                                </th>
                                <th class="text-left">
                                    Quản lý
                                </th>
                                <th class="text-left">
                                    Ngày tạo
                                </th>
                                <th class="text-left">
                                    Khách hàng
                                </th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="states.loading">
                                <td class="text-center" colspan="6">
                                    <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    ></v-progress-circular>
                                </td>
                            </tr>
                            <tr v-if="states.message != null">
                                <td class="text-center" colspan="6">
                                    <p>
                                        {{states.message}}
                                    </p>
                                    <v-btn
                                        :to="{name: 'list-order'}"
                                        large
                                        depressed
                                        color="error"
                                        style="margin-right: 7px"
                                        >
                                        <v-icon small left>mdi-reload</v-icon> Tải lại
                                    </v-btn>
                                </td>
                            </tr>
                            <tr v-for="(customerItem, index) in states.customers"
                                :key="index"
                                >
                                <td>{{ index +  1 }}</td>
                                <td>
                                    {{customerItem.product.name}} <br>
                                    {{customerItem.license}}
                                </td>
                                <td>
                                    <v-chip
                                    class="ma-2"
                                    :color="customerItem.type_key.value == 0 ? 'error' : 'primary'"
                                    >
                                    {{customerItem.type_key.name}}
                                    </v-chip>
                                     <br>
                                    Thời hạn: <b>{{customerItem.day_use}} ngày</b>
                                </td>
                                <td>
                                    {{customerItem.order.saler}}
                                </td>
                                <td>
                                    {{customerItem.order.created_at}}
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="customerItem.customer.name"
                                        label="Họ tên"
                                        outlined
                                        @change="updateKey(index, customerItem)"
                                        autocomplete="off"
                                        dense
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="customerItem.customer.email"
                                        label="Email"
                                        autocomplete="off"
                                        @change="updateKey(index, customerItem)"
                                        outlined
                                        dense
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="customerItem.customer.phone"
                                        @change="updateKey(index, customerItem)"
                                        label="SĐT"
                                        autocomplete="off"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </td>
                                <td align="center">
                                    <p>
                                        <v-btn
                                            small
                                            depressed
                                            color="warning"
                                            style="margin-right: 7px"
                                            @click="resendKey(customerItem)"
                                            >
                                            <v-icon small>mdi-restore</v-icon>
                                        </v-btn>
                                        <v-btn
                                            small
                                            depressed
                                            color="error"
                                            @click="deleteCustomer(customerItem.id, index)"
                                            >
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </p>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <div class="text-center" v-if="states.filters.total_page > 1">
                    <v-container>
                        <v-row justify="center">
                            <v-col cols="8">
                                <v-container class="max-width">
                                    <v-pagination
                                    @input="nextPage"
                                    v-model="states.filters.page"
                                    class="my-4"
                                    :length="states.filters.total_page"
                                    :total-visible="7"
                                    ></v-pagination>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import { api } from "./../../../../helpers/const.js";
export default {
	name: "NotActive",
	data() {
		return {
            states: {
                loading: true,
                message: null,
                customers: [],
                filters: {
                    page: 1,
                    total_page: 0
                }
            }
        }
    },
    methods: {
        nextPage(page) {
            this.states = {
                ...this.states,
                page: page,
                customers: [],
                loading: true
            }
            this.callApi();
        },
        resendKey(data) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn gửi lại key này?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                    },
                    body: JSON.stringify({
                        email: data.customer.email,
                        product_id: data.product.id,
                        key: data.license
                    })
                };
                fetch(api.send_key_to_email, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.success) {
                        this.$swal({
                            title: result.message,
                            icon: 'success',
                            timer: 2000
                        })
                    } else {
                        this.$swal({
                            title: result.message,
                            icon: 'error',
                            timer: 2000
                        })
                    }
                })
            })
        },
        updateKey(index, data) {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    id: data.id,
                    data_update: {
                        customer_name: data.customer.name,
                        customer_email: data.customer.email,
                        customer_phone: data.customer.phone
                    }
                })
            };
            fetch(api.update_key_global, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.$swal({
                        title: result.message,
                        timer: 500,
                        icon: 'success'
                    })
                } else {
                    this.$swal({
                        title: result.message,
                        timer: 1000,
                        icon: 'error'
                    })
                }
            })
        },
        deleteCustomer(id, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                            },
                            body: JSON.stringify({
                                id: id
                            })
                        };
                        fetch(api.remove_key, requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.success) {
                                this.$swal({
                                    title: result.message,
                                    timer: 1000,
                                    icon: 'success'
                                })
                                const customer_copy = [...this.states.customers];
                                customer_copy.splice(index, 1);
                                this.states = {
                                    ...this.states,
                                    customers: customer_copy
                                }
                            } else {
                                this.$swal({
                                    title: result.message,
                                    timer: 1000,
                                    icon: 'error'
                                })
                            }
                        })
                }
            })
            
        },
        callApi() {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify(this.states.filters)
            };
            fetch(api.customer_not_active, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.states = {
                        ...this.states,
                        loading: false,
                        customers: result.customers,
                        total_page: result.total_page
                    }
                } else {
                    this.states = {
                        ...this.states,
                        loading: false,
                        message: result.message
                    }
                }
            })
        }
    },
    mounted() {
		this.callApi()
	}
}
</script>
