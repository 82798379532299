<template>
    <v-row class="bg-white">
        <v-col
            cols="12"
            sm="12"
            md="12"
        >
            <v-simple-table class="list-order">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>
                                STT
                            </th>
                            <th>Sản phẩm</th>
                            <th>
                                Khách hàng
                            </th>
                            <th>Quản lý</th>
                            <th>Ghi chú</th>
                            <th>Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="loading">
                            <td class="text-center" colspan="6">
                                <v-progress-circular
                                :size="50"
                                color="primary"
                                indeterminate
                                ></v-progress-circular>
                            </td>
                        </tr>
                        <tr v-if="message != null">
                            <td class="text-center" colspan="6">
                                <p>
                                    {{states.message}}
                                </p>
                                <v-btn
                                    :to="{name: 'list-order'}"
                                    large
                                    depressed
                                    color="error"
                                    style="margin-right: 7px"
                                    >
                                    <v-icon small left>mdi-reload</v-icon> Tải lại
                                </v-btn>
                            </td>
                        </tr>
                        <tr v-for="(customerItem, index) in states.customers"
                            :key="index"
                            >
                            <td>{{ index +  1 }}</td>
                            <td>
                                <b>{{customerItem.product.name}}</b> : {{Number(customerItem.product.price).toLocaleString()}}
                                <br>
                                <b>Key:</b> {{customerItem.license}}
                                <br>
                                <b>Loại key:</b> <v-chip
                                                    class="ma-2"
                                                    :color="customerItem.type_key.value == 0 ? 'error' : 'primary'"
                                                    >
                                                    {{customerItem.type_key.name}}
                                                    </v-chip>
                                <br>
                                <b>Ngày hết hạn:</b> {{customerItem.date_expire}}
                            </td>
                            <td>
                                {{customerItem.customer.name}}
                                <br>
                                {{customerItem.customer.phone}}/{{customerItem.customer.email}}
                                <br>
                                Ngày HĐ cuối: {{customerItem.last_runing_date}}
                            </td>
                            <td>
                                {{customerItem.order.saler}}
                            </td>
                            <td style="padding: 10px 0px">    
                                <v-textarea
                                outlined
                                @change="updateKey(customerItem)"
                                name="input-7-4"
                                v-model="customerItem.note"
                                label="Ghi chú"
                                :value="customerItem.note"
                                ></v-textarea>
                            </td>
                            <td align="center">
                                <p>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                depressed
                                                color="secondary"
                                                style="margin-right: 7px"
                                                @click="resetKey(customerItem)"
                                                >
                                                <v-icon small>mdi-restore</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Đặt lại key</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                dark
                                                depressed
                                                color="error"
                                                style="margin-right: 7px"
                                                @click="deleteCustomer(customerItem.id, index)"
                                                >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Xóa</span>
                                    </v-tooltip>
                                </p>
                                <p>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                dark
                                                depressed
                                                color="success"
                                                style="margin-right: 7px"
                                                @click="resendKey(customerItem)"
                                                >
                                                <v-icon small>mdi-email-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Gửi lại mail</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                dark
                                                depressed
                                                color="primary"
                                                style="margin-right: 7px"
                                                @click="blockKey(customerItem, index)"
                                                >
                                                <v-icon small>mdi-lock</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Block key</span>
                                    </v-tooltip>
                                </p>
                                <p>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                                dark
                                                depressed
                                                color="warning"
                                                style="margin-right: 7px"
                                                :to="{name: 'edit-key', params: {id: customerItem.id}}"
                                                >
                                                <v-icon small>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Sửa/Gia hạn</span>
                                    </v-tooltip>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
</template>

<script>
import { api } from "./../../../../helpers/const.js";
export default {
    name: "DataCustomer",
    props: {
        customers: [],
        loading: {
            type: Boolean,
            default: true
        },
        message: null
    },
    data() {
		return {
            states: {
                customers: []
            }
        };
    },
    methods: {
        resetKey(data) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn đặt lại key?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: data.id
                        })
                    };
                    fetch(api.reset_key, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                timer: 1000,
                                icon: 'success'
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                timer: 1000,
                                icon: 'error'
                            })
                        }
                    })
                }
            })
        },
        blockKey(data, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn block key này?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            id: data.id,
                            day_number: this.states.filters.time,
                        })
                    };
                    fetch(api.block_key, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 2000
                            })
                            const customer_copy = [...this.states.customers];
                            customer_copy.splice(index, 1);
                            this.states = {
                                ...this.states,
                                customers: customer_copy
                            }
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    })
                }
                
            })
        },
        resendKey(data) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn gửi lại key này?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                        },
                        body: JSON.stringify({
                            email: data.customer.email,
                            product_id: data.product.id,
                            key: data.license
                        })
                    };
                    fetch(api.send_key_to_email, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.success) {
                            this.$swal({
                                title: result.message,
                                icon: 'success',
                                timer: 2000
                            })
                        } else {
                            this.$swal({
                                title: result.message,
                                icon: 'error',
                                timer: 2000
                            })
                        }
                    })
                }
            })
        },
        updateKey(data) {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                },
                body: JSON.stringify({
                    id: data.id,
                    data_update: {
                        note: data.note,
                    }
                })
            };
            fetch(api.update_key_global, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    this.$swal({
                        title: result.message,
                        timer: 500,
                        icon: 'success'
                    })
                } else {
                    this.$swal({
                        title: result.message,
                        timer: 1000,
                        icon: 'error'
                    })
                }
            })
        },
        deleteCustomer(id, index) {
            this.$swal({
                title: 'Bạn có chắc chắn muốn xóa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy'
            }).then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.$cookie.get("is_login")
                            },
                            body: JSON.stringify({
                                id: id
                            })
                        };
                        fetch(api.remove_key, requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.success) {
                                this.$swal({
                                    title: result.message,
                                    timer: 1000,
                                    icon: 'success'
                                })
                                const customer_copy = [...this.states.customers];
                                customer_copy.splice(index, 1);
                                this.states = {
                                    ...this.states,
                                    customers: customer_copy
                                }
                            } else {
                                this.$swal({
                                    title: result.message,
                                    timer: 1000,
                                    icon: 'error'
                                })
                            }
                        })
                }
            })
            
        },
    },
    watch: {
        customers: function(newVal) {
            this.states = {
                ...this.states,
                customers: [...newVal]
            }
        }
    }
}
</script>
